import { Stack, Typography, ButtonBase, Backdrop, CircularProgress } from "@mui/material"
import React, { useState, useEffect, createRef } from 'react';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/index.js';
import { ConfirmAlert } from "../../../customControls/confirmAlert.js";
import { documentColumns, documentRow } from "./data/arrayData";
import api from "../../../lib/axios.js";
import { enqueueSnackbar } from "notistack";

export const Documents = ({ nodeData }) => {
    const fileRef = createRef()
    const [isLoading, setIsLoading] = useState(false);
    const [documentArrayData, setDocumentArrayData] = useState([]);
    const [documentDeleteConfirmationShouldOpen, setDocumentDeleteConfirmationShouldOpen] = useState(false);
    const [selectedDocumentID, setSelectedDocumentID] = useState("");

    useEffect(() => {
        getImageData()
    }, []);

    async function uploadFiles(file) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", file);

        formData.append("userName", localStorage.getItem("fullName"));
        api({
            method: "POST",
            url: '/apis/NodeImages/uploadimage/' + nodeData._id,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((res) => {
                if (res.data.success) {
                    enqueueSnackbar("File uploaded", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                    getImageData()
                } else {
                    enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                }
                setIsLoading(false)
            }).catch(function (error) {
                console.log(error);
            });
    }

    async function getImageData() {
        setIsLoading(true)
        return await api.get('/apis/NodeImages/byNode/' + nodeData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setDocumentArrayData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onDocumentDeleteClick = (row) => {
        setSelectedDocumentID(row._id)
        setDocumentDeleteConfirmationShouldOpen(true)
    };

    function deleteDocumnet() {
        setIsLoading(true)
        api.delete('/apis/NodeImages/' + selectedDocumentID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("File deleted", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                setSelectedDocumentID("")
                getImageData()
            } else {
                enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateDocumentConfirmAlert = (value) => {
        if (value) {
            deleteDocumnet()
        } else {
            setSelectedDocumentID("")
        }
    }

    return (
        <Stack>
            <Stack mb={2}>
                <input
                    type="file"
                    name="files"
                    hidden
                    ref={fileRef}
                    onChange={(e) => {
                        const selectedFiles = e.target.files;
                        console.log("selectedFiles", selectedFiles)
                        if (selectedFiles.length > 0) {
                            uploadFiles(selectedFiles[0]);
                        }

                    }}

                />
                <ButtonBase
                    sx={{
                        border: '1px solid #E2E2EA',
                        bgcolor: '#FCFCFE',
                        borderRadius: '5px',
                        width: 100,
                        height: 30
                    }}

                    onClick={(e) => {
                        e.preventDefault()
                        if (fileRef.current) {
                            fileRef.current.value = null;
                            fileRef.current.click()
                        }
                    }}
                >
                    <Typography color={'#44444F'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={14}>
                        Upload
                    </Typography>
                </ButtonBase>
            </Stack>
            <DefaultDataGrid rows={documentArrayData} columns={documentColumns(onDocumentDeleteClick)} disablePagination onRowClick={(e) => {
                if (e.row.path) {
                    window.open(e.row.path, '_blank');
                }
            }}></DefaultDataGrid>
            {
                documentDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDocumentDeleteConfirmationShouldOpen(false)
                        updateDocumentConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}
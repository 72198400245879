import { Box, Stack, Typography, Grid, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment/moment.js';
import { arrayManufacturer, arrayProduct } from '../../NodeNew/data/arrayData.jsx';
import { Documents } from './Documents.jsx';
import { Geofence } from './GeoFence.jsx'

export const Details = ({ nodeData, arrayData, sensorData, setShowMap, showMap, setAddGeoFence, addGeoFence, setDrawData, drawData, setSelectedPoligon, selectedPoligon, setActiveTab, activeTab, getPoligon }) => {
    const [showDocument, setShowDocument] = useState(false);
    const [showGeo, setShowGeo] = useState(false);
    const [showDeviceDetail, setShowDeviceDetail] = useState(false);
    useEffect(() => {
        console.log("addGeoFence", addGeoFence);
    }, [nodeData, sensorData]);
    useEffect(() => {
        console.log("addGeoFence", addGeoFence);
        if (!showMap && showGeo) {
            setShowGeo(false)
        }
    }, [showMap]);


    return (
        <Stack
            ml={1}
            mr={3}
            gap={3}
            p="17px"
            sx={{
                // backgroundColor: '#F6F8FA',
                border: '1px solid #E6E7E8',
                borderRadius: '3.5px',
                width: 332,
            }}
        >
            <Grid container spacing="15px">
                <Grid item xs={6}>
                    <Typography fontWeight={500} fontSize={15}>Details</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography fontSize={12} fontFamily={'Roboto'} color={'#262B40'}>
                        {sensorData != null && sensorData.latitude != null && sensorData.latitude != "" && sensorData.timestamp != null && sensorData.timestamp !== "" ? moment(sensorData.timestamp).format("MMM DD,YYYY hh:mm a") : "-"}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>
                            {nodeData.nodeName || "-"}                                    </Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'}>
                            Node Name
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                            <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}> {sensorData != null && sensorData.state == "1" ? "Online" : "Offline"}</Typography>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: sensorData != null && sensorData.state == "1" ? "green.main" : "red.main",
                                }}
                            />
                        </Stack>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} >Status</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>{sensorData != null && arrayManufacturer.find(manufacturer => manufacturer.id == sensorData.manufacturer)?.value || "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Manufacturer</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>{sensorData != null && arrayProduct.find(product => product.id == sensorData.product)?.value || "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Product</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}> {nodeData.nodeCategory || "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Category</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>{nodeData.nodeType || "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Type</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap="15px">
                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#262B40'}>{sensorData != null && sensorData.sensorNumber || "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>Node ID</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Device Info</Typography>
                <IconButton
                    sx={{
                        padding: "3",
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#F6F8FA',
                        color: 'black',
                    }}
                    onClick={(e) => {
                        setShowDeviceDetail(!showDeviceDetail)
                        setShowDocument(false)
                        setShowGeo(false)
                        setShowMap(false)

                    }}
                >
                    {showDeviceDetail ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                </IconButton >
            </Stack>
            {showDeviceDetail && <Stack width={'100%'}>
                <Grid container spacing="15px">
                    <Grid item xs={6}>
                        <Stack gap="15px">
                            <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>
                                {nodeData.sensorNumber || "-"}                                    </Typography>
                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'}>
                                Serial No.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack gap="15px">
                            <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>
                                {nodeData.sensor.ident || "-"}                                    </Typography>
                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'}>
                                IMEI
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack gap="15px">
                            <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>
                                {sensorData.productId || "-"}                                    </Typography>
                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'}>
                                Product ID
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack gap="15px">
                            <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>
                                {sensorData.firmware || "-"}                                    </Typography>
                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'}>
                                Firmware
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack gap="15px">
                            <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>
                                {sensorData.iccid || "-"}                                    </Typography>
                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'}>
                                ICCID
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>}
            {nodeData != null && sensorData != null && sensorData.product != null && sensorData.product != "4" && <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Geofences</Typography>
                <IconButton
                    sx={{
                        padding: "3",
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#F6F8FA',
                        color: 'black',
                    }}
                    onClick={(e) => {
                        if (showGeo) {
                            setShowMap(false)
                        } else {
                            setShowMap(true)
                        }
                        setShowGeo(!showGeo)
                        setShowDocument(false)
                        setShowDeviceDetail(false)

                    }}
                >
                    {showGeo ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                </IconButton >
            </Stack>}
            {showGeo &&
                <Geofence
                    nodeData={nodeData}
                    arrayData={arrayData}
                    setAddGeoFence={setAddGeoFence}
                    addGeoFence={addGeoFence}
                    setDrawData={setDrawData}
                    drawData={drawData}
                    setSelectedPoligon={setSelectedPoligon}
                    selectedPoligon={selectedPoligon}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    getPoligon={getPoligon}
                />}

            <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Documents</Typography>
                <IconButton
                    sx={{
                        padding: "3",
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#F6F8FA',
                        color: 'black',
                    }}
                    onClick={(e) => {
                        setShowDocument(!showDocument)
                        setShowDeviceDetail(false)
                        setShowGeo(false)
                        setShowMap(false)

                    }}
                >
                    {showDocument ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                </IconButton >
            </Stack>
            {showDocument && <Documents nodeData={nodeData} />}
        </Stack>
    );
};

import { Backdrop, Box, Button, ButtonBase, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { DefaultInput } from '../../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../../ui/DefaultSelect/index.js';
import React, { useState } from 'react';
import { Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import useIsMountedRef from '../../../../hooks/useIsMountedRef.js';
import { arrayTimeZone } from '../../data/company.jsx';

export const SettingCompanyManage = ({ children, companyObj, setCompanyDetailsOpen, onClose, getData }) => {
    const isMountedRef = useIsMountedRef();
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            submit: null,
            companyName: companyObj.companyName,
            contact: companyObj.contact,
            email: companyObj.email,
            address1: companyObj.address1,
            address2: companyObj.address2,
            city: companyObj.city,
            state: companyObj.state,
            zipCode: companyObj.zipCode,
            telephone: companyObj.telephone,
            accountTelephone: companyObj.accountTelephone,
            taxId: companyObj.taxId,
            netTerms: companyObj.netTerms,
            dealer: companyObj.dealer,
            billingContact: companyObj.billingContact,
            accountEmail: companyObj.accountEmail,
            selectedTimeZone: arrayTimeZone.find(tz => tz.value == companyObj.timeZone),
        },
        validationSchema: Yup.object().shape({
            companyName: Yup.string().max(255).required('Company name is required'),
            contact: Yup.string().max(255).required('Contact name is required'),
            email: Yup.string().email('Must be a valid email').required('Email is required'),
            address1: Yup.string().required('Address 1 is required'),
            // address2: Yup.string().required('Address 2 is required'),
            city: Yup.string().max(255).required('City is required'),
            state: Yup.string().max(255).required('State is required'),
            zipCode: Yup.string().max(255).required('zip code is required'),
            telephone: Yup.string().max(255).required('Mobile number is required'),
            // accountTelephone: Yup.string().max(255).required('Phone number is required'),
            // taxId: Yup.string().max(255).required('Tax id is required'),
            // netTerms: Yup.string().max(255).required('Net terms is required'),
            // dealer: Yup.string().max(255).required('Dealer is required'),
            // billingContact: Yup.string().max(255).required('Billing contact is required'),
            // accountEmail: Yup.string().email('Must be a valid email').required('Email is required'),
            selectedTimeZone: Yup.object().required('Time Zone is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setIsLoading(true)
                setSubmitting(true);
                var data = await apiCalling(values.companyName, values.contact, values.email, values.address1, values.address2, values.city, values.state, values.zipCode, values.telephone, values.accountTelephone, values.taxId, values.netTerms, values.dealer, values.billingContact, values.accountEmail, values.selectedTimeZone);
                console.log("Data", data.data.success)
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    console.log("IF")
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Company updated', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    onClose()
                    getData()
                } else {
                    console.log("ELSE")
                    setStatus({ success: false });
                    setErrors({ submit: data.data.message });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })
    async function apiCalling(companyName, contactName, contactEmail, address1, address2, city, state, zipCode, mobileNumber, phoneNumber, taxId, netTerms, dealer, billingContact, billingEmail, selectedTimeZone) {
        var data = {
            companyName: companyName,
            contact: contactName,
            email: contactEmail,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zipCode,
            telephone: mobileNumber,
            accountTelephone: phoneNumber,
            taxId: taxId,
            netTerms: netTerms,
            dealer: dealer,
            billingContact: billingContact,
            accountEmail: billingEmail,
            timeZone: selectedTimeZone.value,
        }
        return await api.put('/apis/Companies/', data)
    }
    return (
        <>
            <Stack>
                <FormikProvider value={formik}>
                    <Stack
                        px={4}
                        pt={3}
                        pb={2}
                        gap={4}
                        overflowY={'auto'}
                        minWidth={'700px'}
                        height={'750px'}
                        maxHeight={'calc(100vh - 100px)'}
                        maxWidth={'700px'}
                        position={'fixed'}
                        zIndex={10}
                        sx={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            overflowY: 'auto',
                            borderRadius: '10px',
                            bgcolor: 'common.white',
                            border: '1px solid #D5D5D5',
                        }}
                        component={'form'}
                        onSubmit={formik.handleSubmit}
                    >

                        <Typography fontSize={18}>Company Details</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Company Name'}
                                    placeholder={'E.g. ABc'}
                                    value={formik.values.companyName}
                                    name={'companyName'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.companyName && formik.errors.companyName)}
                                    helperText={formik.touched.companyName && formik.errors.companyName}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <DefaultInput
                                    label={'Email'}
                                    placeholder={'E.g. example@oilservices.com'}
                                    value={formik.values.email}
                                    name={"email"}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Contact Name'}
                                    placeholder={'E.g. Abc'}
                                    value={formik.values.contact}
                                    name={'contact'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.contact && formik.errors.contact)}
                                    helperText={formik.touched.contact && formik.errors.contact}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Address 1'}
                                    placeholder={'E.g. 1401 Main'}
                                    value={formik.values.address1}
                                    name={'address1'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.address1 && formik.errors.address1)}
                                    helperText={formik.touched.address1 && formik.errors.address1}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Address 2'}
                                    placeholder={'E.g. 1401 Main'}
                                    value={formik.values.address2}
                                    name={'address2'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.address2 && formik.errors.address2)}
                                    helperText={formik.touched.address2 && formik.errors.address2}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <DefaultInput
                                    label={'City'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.city}
                                    name={'city'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.city && formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'State'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.state}
                                    name={'state'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.state && formik.errors.state)}
                                    helperText={formik.touched.state && formik.errors.state}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Zip Code'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.zipCode}
                                    name={'zipCode'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
                                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Mobile Number'}
                                    placeholder={'E.g. 12345'}
                                    value={formik.values.telephone}
                                    name={'telephone'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.telephone && formik.errors.telephone)}
                                    helperText={formik.touched.telephone && formik.errors.telephone}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <DefaultSelect
                                    sx={{
                                        '.MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Time Zone'}
                                    value={formik.values.selectedTimeZone}
                                    onItemSelected={(e) => {
                                        formik.setFieldValue('selectedTimeZone', e.target.value)
                                    }}
                                    helperText={formik.touched.selectedTimeZone && formik.errors.selectedTimeZone}
                                    error={Boolean(formik.touched.selectedTimeZone && formik.errors.selectedTimeZone)}
                                    placeholder="Select Time Zone"
                                >
                                    {arrayTimeZone.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                    ))}
                                </DefaultSelect>
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Phone Number'}
                                    placeholder={'E.g. 12345'}
                                    value={formik.values.accountTelephone}
                                    name={'accountTelephone'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.accountTelephone && formik.errors.accountTelephone)}
                                    helperText={formik.touched.accountTelephone && formik.errors.accountTelephone}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Tax ID'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.taxId}
                                    name={'taxId'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.taxId && formik.errors.taxId)}
                                    helperText={formik.touched.taxId && formik.errors.taxId}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Net Terms'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.netTerms}
                                    name={'netTerms'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.netTerms && formik.errors.netTerms)}
                                    helperText={formik.touched.netTerms && formik.errors.netTerms}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Dealer'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.dealer}
                                    name={'dealer'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.dealer && formik.errors.dealer)}
                                    helperText={formik.touched.dealer && formik.errors.dealer}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Billing Contact'}
                                    placeholder={'E.g. 12345'}
                                    value={formik.values.billingContact}
                                    name={'billingContact'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.billingContact && formik.errors.billingContact)}
                                    helperText={formik.touched.billingContact && formik.errors.billingContact}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Billing Email'}
                                    placeholder={'E.g. example@oilservices.com'}
                                    value={formik.values.accountEmail}
                                    name={'accountEmail'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.accountEmail && formik.errors.accountEmail)}
                                    helperText={formik.touched.accountEmail && formik.errors.accountEmail}
                                />
                            </Grid>

                        </Grid>
                        <Box flex={1}>{children}</Box>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <ButtonBase />
                            <Stack direction={'row'} gap={2}>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: 'primary.main',
                                        borderRadius: '5px',
                                        height: '30px'

                                    }}
                                    onClick={onClose}
                                >
                                    <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                        Cancel
                                    </Typography>
                                </ButtonBase>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: '#EFEFEF',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                >
                                    <Typography fontSize={14} fontWeight={700}>
                                        Update
                                    </Typography>
                                </ButtonBase>
                            </Stack>
                        </Stack>
                    </Stack >

                </FormikProvider >
                <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>
        </>
    )

}
export const companyTabs = [
    {
        title: 'Details',
        value: 'details',
    },
    {
        title: 'Compliance',
        value: 'compliance',
    },
];

import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, ButtonBase, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import * as Yup from 'yup';
import { Formik, FormikProvider, useFormik } from 'formik';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { useNavigate } from 'react-router';
export const ChangePassword = (props) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const isMountedRef = useIsMountedRef();
    useEffect(() => {
    }, []);
    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: '',
            submit: null
        },
        validationSchema: Yup.object().shape({
            // oldPassword: Yup.string().required('Old password is required'),
            password: Yup.string().required('Password is required'),
            confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Password does not same')
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setIsLoading(true)
                var data = await apiCalling(values.password, values.oldPassword);
                console.log(data)
                setIsLoading(false)
                if (data !== undefined && data.success) {
                    localStorage.clear()
                    navigate("/authentication/login")
                    console.log("IF")
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    setStatus({ success: true });
                    setSubmitting(false);
                } else {
                    console.log("Else")
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                    setStatus({ success: false });
                    setErrors({ submit: data.message });
                    setSubmitting(false);
                }

            } catch (err) {
                console.log("inCATCH", err);
                console.error(err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })


    const apiCalling = async (password, oldPassword) => {
        var data = {
            "authID": localStorage.getItem("authID"),
            "oldPassword": oldPassword,
            "newPassword": password
        }
        var obj = await axios.post('/apis/CompanyLogin/resetPassword', data)
        return obj.data
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Stack
                        height={'100vh'}
                        alignItems={'center'}
                        justifyContent={'center'}

                    >
                        <Stack
                            py={0}
                            width={'100%'}
                            height={'100%'}
                            maxWidth={'300px'}
                            sx={{
                                justifyContent: 'center'
                            }}
                        >
                            <Box spacing={3}>
                                <FormikProvider value={formik}>
                                    <Stack spacing={3} component={'form'} onSubmit={formik.handleSubmit} >
                                        {/* <DefaultInput
                                            label={'Old Password'}
                                            placeholder={'************'}
                                            type={'password'}
                                            name="oldPassword"
                                            value={formik.values.oldPassword}
                                            error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                                            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        /> */}
                                        <DefaultInput
                                            label={'Password'}
                                            placeholder={'************'}
                                            type={'password'}
                                            name="password"
                                            value={formik.values.password}
                                            error={Boolean(formik.touched.password && formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />

                                        <DefaultInput
                                            label={'Confirm Password'}
                                            placeholder={'************'}
                                            type={'password'}
                                            name="confirmPassword"
                                            value={formik.values.confirmPassword}
                                            error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        <Stack direction={'row'} gap={2} sx={{ width: '100%' }}>
                                            <ButtonBase
                                                sx={{
                                                    py: 1,
                                                    px: 3,
                                                    bgcolor: 'primary.main',
                                                    borderRadius: '5px',
                                                    flexGrow: 1,
                                                }}
                                                onClick={() => {
                                                    navigate(-1)
                                                }}
                                            >
                                                <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                                    Cancel
                                                </Typography>
                                            </ButtonBase>
                                            <ButtonBase
                                                sx={{
                                                    py: 1,
                                                    px: 3,
                                                    bgcolor: '#EFEFEF',
                                                    borderRadius: '5px',
                                                    flexGrow: 1,
                                                }}
                                                disabled={formik.isSubmitting}
                                                type={'submit'}
                                            >
                                                <Typography fontSize={14} fontWeight={700}>
                                                    Submit
                                                </Typography>
                                            </ButtonBase>
                                        </Stack>
                                    </Stack>
                                </FormikProvider>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Backdrop style={{ zIndex: 99 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
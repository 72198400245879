export const BoxIcon = (props) => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M15 1H1V15H15V1Z' fill='#1E75FF' fillOpacity='0.27' />
            <path d='M14.5 1.5H1.5V14.5H14.5V1.5Z' stroke='#707070' />
            <path
                d='M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z'
                fill='#1E75FF'
            />
            <path
                d='M14 4C15.1046 4 16 3.10457 16 2C16 0.89543 15.1046 0 14 0C12.8954 0 12 0.89543 12 2C12 3.10457 12.8954 4 14 4Z'
                fill='#1E75FF'
            />
            <path
                d='M14 16C15.1046 16 16 15.1046 16 14C16 12.8954 15.1046 12 14 12C12.8954 12 12 12.8954 12 14C12 15.1046 12.8954 16 14 16Z'
                fill='#1E75FF'
            />
            <path
                d='M2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.89543 12 0 12.8954 0 14C0 15.1046 0.89543 16 2 16Z'
                fill='#1E75FF'
            />
        </svg>
    );
};

export const internalSensorsTabs = [
    {
        title: 'Temp',
        value: 'temperature',
    },
    {
        title: 'Speed',
        value: 'positionSpeed',
    },
    {
        title: 'Altitude',
        value: 'positionAltitude',
    },
    {
        title: 'Battery',
        value: 'voltage',
    },
    {
        title: 'CellSignal',
        value: 'cellSignal',
    },
];


export const internalEventTabs = [
    {
        title: 'All',
        value: '0',
    },
    {
        title: 'Temp',
        value: '2',
    },
    {
        title: 'Speed',
        value: '1',
    },
    {
        title: 'Altitude',
        value: '3',
    },
    {
        title: 'Geofence',
        value: '4',
    },
];

import { Box, Grid, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { Map } from './Map.jsx';
import moment from 'moment'
import image from '../../../assets/images/image_oyster_top@2x.png'
import { Tag } from '../../../ui/Tag/Tag.jsx';

export const Details = ({ assetName, oemServer, trackerData }) => {
    const [isLoading, setIsLoading] = useState(false);

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "0 mins";
        }
        return durationString.trim();
    }

    return (
        <>
            <Stack
                flex={1}
                direction={'row'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 'none',
                }}
                mb={1}
                width={'calc(100% + 24px)'}
            >
                <Stack gap={3} p={3} borderRight={'1px solid #E2E2EA'} width={'600px'} flexShrink={0}>
                    <Typography fontFamily={'Roboto'} fontWeight={'Medium'} fontSize={15} color={'#1F243B'}>Tracker Details</Typography>
                    <Box mt={-3} ml={-2} mr={-5}>
                        <Stack direction={'row'} spacing={1} >
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.IMEI ? oemServer.IMEI : "-"}</Typography>
                                        <Typography
                                            fontSize={12}
                                            color={'#A0A3A6'}
                                            fontFamily={'Roboto'}
                                            fontWeight={'Regular'}
                                        >
                                            IMEI
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.ICCID ? oemServer.ICCID : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            ICCID
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.ProductId ? oemServer.ProductId : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Product
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.Vendor ? oemServer.Vendor : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Vendor
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.BatchString ? oemServer.FirmwareVersion : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Firmware
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={7}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.BatchString ? oemServer.BatchString : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Batch String
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{oemServer && oemServer.productType ? oemServer.productType : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Product Type
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#262B40'}>{assetName}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Assigned Asset
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mr={-3}>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Tag text={trackerData.timestamp ? moment().diff(moment(trackerData.timestamp), 'hour') > 12 ? "Offline" : "Online" : "-"} borderColor={"#24D5AA"} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={5}>
                                    <Stack gap={0.5} >
                                        <Tag text={trackerData.status ? trackerData.status == "1" ? "Enabled" : "Disabled" : "-"} borderColor={"#24D5AA"} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={5}>
                                    <Stack gap={0.5} mt={1.5}>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Status
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Grid item xs={5}>
                                    <Stack gap={0.5} mt={1.5}>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Billing
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5} />
                                <Grid item xs={5} />
                                <Grid item xs={5} mt={1}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontWeight={500}>{trackerData && trackerData.channelID ? trackerData.channelID : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            Channel ID
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5} mt={1}>
                                        <Typography fontSize={12} fontWeight={500}>{trackerData && trackerData.hardwareVersion ? trackerData.hardwareVersion : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            HW Ver.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontWeight={500}>{trackerData && trackerData.powersourceStatus ? trackerData.powersourceStatus : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            External Power
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack gap={0.5}>
                                        <Typography fontSize={12} fontWeight={500}>{trackerData && trackerData.signalDbm ? trackerData.signalDbm : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={'Regular'}>
                                            GSM Signal
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Stack gap={1}>
                        <Stack direction={'row'} spacing={2}>
                            <Box px={2} py={1} ml={-1} width={'250px'} bgcolor={'#F6F8FA'} borderRadius={'10px'}>
                                <Grid container spacing={2} ml={0} mt={0}>
                                    {/* <Grid item xs={12}>
                                        <Typography fontWeight={500} fontSize={15}>Data</Typography>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1, display: 'inline' }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>
                                                {trackerData.positionLatitude != null && trackerData.positionLatitude != "" && trackerData.positionLatitude != "-" && trackerData.timestamp != null && trackerData.timestamp !== "" ? moment(trackerData.timestamp).format("MM-DD-YY hh:mma") : "-"}
                                                {/* {trackerData.lastOnlineDate ? moment(trackerData.lastOnlineDate).format("MM-DD-YYYY hh:mm:ss") : "-"} */}
                                            </Typography>
                                            <Typography fontSize={12} color={'#AAB0BC'} style={{ display: 'inline' }} fontFamily={'Roboto'} fontWeight={'Regular'}>Last Connected</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>{`${trackerData.temperature} F` || "-"}</Typography>
                                            <Typography fontSize={12} color={'#AAB0BC'} fontFamily={'Roboto'} fontWeight={'Regular'}> Device Temp.</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>{trackerData && trackerData.priorityAltitude ? trackerData.priorityAltitude : "-"}</Typography>
                                            <Typography fontSize={12} color={'#AAB0BC'} fontFamily={'Roboto'} fontWeight={'Regular'}>Altitude(m)</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>{oemServer && oemServer.LastBatteryLevel ? oemServer.LastBatteryLevel : "-"}</Typography>
                                            <Typography fontSize={12} color={'#AAB0BC'} fontFamily={'Roboto'} fontWeight={'Regular'}>Battery(v)</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>{trackerData && trackerData.positionSpeed ? trackerData.positionSpeed : "-"}</Typography>
                                            <Typography fontSize={12} color={'#AAB0BC'} fontFamily={'Roboto'} fontWeight={'Regular'}>Speed(m/s)</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>{oemServer && oemServer.LastLongitude ? oemServer.LastLongitude : "-"}</Typography>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} color={'#AAB0BC'} fontFamily={'Roboto'} fontWeight={'Regular'}>Longitude</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Stack gap={0.5}>
                                            <Typography fontSize={12} style={{ flexGrow: 1 }} fontFamily={'Roboto'} fontWeight={'Regular'} color={'#1F243B'}>{oemServer && oemServer.LastLatitude ? oemServer.LastLatitude : "-"}</Typography>
                                            <Typography fontSize={12} color={'#AAB0BC'} fontFamily={'Roboto'} fontWeight={'Regular'}>Latitude</Typography>
                                        </Stack>
                                    </Grid>

                                </Grid>
                            </Box>
                            <Stack>
                                <Box
                                    src={image}
                                    component={'img'}
                                    sx={{
                                        width: '250px',
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>

                </Stack>
                <Map trackerData={trackerData} />
            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

import { Backdrop, Box, ButtonBase, CircularProgress, MenuItem, Stack, Typography, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { DefaultSelect } from '../../../ui/DefaultSelect';
import { DefaultInput } from '../../../ui/DefaultInput/DefaultInput.jsx';
import { arrayGraphData, arrayGraphColor } from './data/arrayData.jsx';
import api from '../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx'

export const SelectGraphAlert = ({ nodeData, selectedDataId, sensorData, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGraphColor, setSelectedGraphColor] = useState("");
    const [showPercantage, setShowPercentage] = useState(false);
    const [arraySensorDataType, setArraySensorDataType] = useState([]);
    const formik = useFormik({
        initialValues: {
            groupName: nodeData?.graphGroupName || '',
            selecteGraph: null,
            selectedInput: null,
            graphTitle: '',
            graphMaxValue: '',
        },
        validationSchema: Yup.object().shape({
            groupName: Yup.string().required('Group name is required'),
            selecteGraph: Yup.object().required('Graph is required'),
            selectedInput: Yup.object().required('Data input is required'),
            graphTitle: Yup.string().required('Group title is required'),
            graphMaxValue: Yup.string().required('Max value is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                console.log("code", selectedGraphColor);

                if (selectedGraphColor == "") {
                    enqueueSnackbar('Graph color is required', { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                } else {
                    var dataValues = {
                        groupName: values.groupName,
                        selecteGraph: values.selecteGraph,
                        selectedInput: values.selectedInput,
                        graphTitle: values.graphTitle,
                        graphMaxValue: values.graphMaxValue,
                    }
                    formik.setValues(dataValues)
                    setIsLoading(true)
                    var data = await apicalling(values);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        enqueueSnackbar('Graph updated', {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                        onClose(true)
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                    setSubmitting(false);
                }
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        let arrayMeasurmentNew = arrayMeasurment.filter(item => item.array.includes(sensorData.product + ""))
        sensorData.arraySecondaryData.map((item, index) => (
            arrayMeasurmentNew.push({ id: item.pin, value: item.name, isExternal: 1 })
        ))
        setArraySensorDataType(arrayMeasurmentNew)
    }, []);

    useEffect(() => {
        getGraphData()
    }, [formik.values.selecteGraph]);

    const handleCheckboxChange = (graphColor) => {
        setSelectedGraphColor(selectedGraphColor === graphColor ? "" : graphColor);
    };

    function getGraphData() {
        if (nodeData != null && nodeData.arrayGraph != null && sensorData != null && sensorData.arraySecondaryData != null) {
            if (formik.values.selecteGraph != null && formik.values.selecteGraph.id != null) {
                var objGraph = nodeData.arrayGraph.find(obj => obj.graph == formik.values.selecteGraph.id)
                if (objGraph) {
                    var objPin = null
                    if (objGraph.isExternal != null && objGraph.isExternal == 1) {
                        objPin = arraySensorDataType.find(obj => obj.id == objGraph.pin && obj.isExternal == 1)
                    } else {
                        objPin = arraySensorDataType.find(obj => obj.id == objGraph.pin)
                    }
                    if (objPin) {
                        var isPercentage = objGraph.showPercentage == "1" ? true : false
                        formik.setFieldValue("selectedInput", objPin ? objPin : "")
                        formik.setFieldValue("graphTitle", objGraph.graphName ? objGraph.graphName : "")
                        formik.setFieldValue("graphMaxValue", objGraph.maxValue ? objGraph.maxValue : "")
                        setShowPercentage(isPercentage ? isPercentage : false)
                        setSelectedGraphColor(objGraph.color ? objGraph.color : "")
                    }
                }
            }
        }
    }

    async function apicalling(values) {
        var data = {
            graphGroupName: values.groupName,
            graph: values.selecteGraph.id,
            pin: values.selectedInput.id,
            graphName: values.graphTitle,
            maxValue: values.graphMaxValue,
            showPercentage: showPercantage == true ? "1" : "0",
            color: selectedGraphColor,
            isExternal: values.selectedInput.isExternal ? values.selectedInput.isExternal : 0
        }
        console.log("dataValues", data);
        return await api.put('/apis/Nodes/updateGraph/' + selectedDataId, data)
    }


    return (
        <>
            <FormikProvider value={formik}>
                <Stack
                    ml={1}
                    mr={3}
                    gap={3}
                    p="17px"
                    sx={{
                        border: '1px solid #E6E7E8',
                        borderRadius: '3.5px',
                        width: 332,
                    }}
                    component={'form'}
                    onSubmit={formik.handleSubmit}>
                    <Stack gap={2}>
                        <Typography fontFamily={'Roboto'} fontWeight={'Bold'} color={'#1F243B'} fontSize={15}>Graph Settings</Typography>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Stack gap="15px" width={'100%'}>
                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                            },
                                        }}
                                        label={'Data Group Name'}
                                        value={formik.values.groupName}
                                        name={'groupName'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.groupName && formik.errors.groupName)}
                                        helperText={formik.touched.groupName && formik.errors.groupName}
                                    />
                                </Stack>
                                <Stack maxWidth={200}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Select Graph'}
                                        value={formik.values.selecteGraph}
                                        onItemSelected={(e) => {
                                            formik.setFieldValue('selecteGraph', e.target.value)
                                        }}
                                        helperText={formik.touched.selecteGraph && formik.errors.selecteGraph}
                                        error={Boolean(formik.touched.selecteGraph && formik.errors.selecteGraph)}
                                        displayKey="name"
                                    >
                                        {arrayGraphData.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                        ))}
                                    </DefaultSelect>
                                </Stack>
                                <Stack maxWidth={200}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Select Data Input'}
                                        value={formik.values.selectedInput}
                                        onItemSelected={(e) => {
                                            console.log("Value", e.target.value);

                                            formik.setFieldValue('selectedInput', e.target.value)
                                        }}
                                        helperText={formik.touched.selectedInput && formik.errors.selectedInput}
                                        error={Boolean(formik.touched.selectedInput && formik.errors.selectedInput)}
                                    >
                                        {arraySensorDataType ? arraySensorDataType.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                        )) : <MenuItem></MenuItem>}
                                    </DefaultSelect>
                                </Stack>

                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                                width: 200
                                            },
                                        }}
                                        label={'Graph Title'}
                                        value={formik.values.graphTitle}
                                        name={'graphTitle'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.graphTitle && formik.errors.graphTitle)}
                                        helperText={formik.touched.graphTitle && formik.errors.graphTitle}
                                    />
                                </Stack>
                                <Stack direction={'row'} gap={2}>
                                    <Stack >
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 2,
                                                    width: 200
                                                },
                                            }}
                                            label={'Enter Max Value (i.e. 100%)'}
                                            value={formik.values.graphMaxValue}
                                            name={'graphMaxValue'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.graphMaxValue && formik.errors.graphMaxValue)}
                                            helperText={formik.touched.graphMaxValue && formik.errors.graphMaxValue}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={12} color={'#A0A3A6'}>% Value</Typography>
                                        <Checkbox
                                            padding={0}
                                            checked={showPercantage}
                                            onChange={(e) => {
                                                if (showPercantage) {
                                                    setShowPercentage(false)
                                                } else {
                                                    setShowPercentage(true)
                                                }
                                            }}
                                        ></Checkbox>
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Grid container xs={12}>
                                        {arrayGraphColor.map((graph, index) => (
                                            <Grid item xs={2.4} key={index} >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                    mb={'15px'}
                                                >
                                                    <Checkbox
                                                        sx={{
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            color: '#B5B5BE',
                                                        }}

                                                        checked={selectedGraphColor === graph.code}
                                                        onChange={() => handleCheckboxChange(graph.code)}
                                                    />
                                                    <Box
                                                        width="25px"
                                                        height="25px"
                                                        bgcolor={graph.code}
                                                        borderRadius="50%"
                                                    />
                                                </Stack>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Stack>
                                <Stack direction={'row'} gap={2} mt={'-15px'} >
                                    <Box flex={1}></Box>
                                    <ButtonBase
                                        sx={{
                                            border: '1px solid #E2E2EA',
                                            bgcolor: '#F6F8FA',
                                            borderRadius: '5px',
                                            height: '30px',
                                            width: '100px'
                                        }}
                                        onClick={() => { onClose(false) }}
                                    >
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                            Cancel
                                        </Typography>
                                    </ButtonBase>
                                    <ButtonBase
                                        sx={{
                                            height: '30px',
                                            width: '100px',
                                            bgcolor: '#1E75FF',
                                            borderRadius: '5px',
                                        }}
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={500} color={'#FAFAFB'}>
                                            Save
                                        </Typography>
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </FormikProvider>
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, MenuItem, Switch, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DefaultCircularProgress } from '../../ui/DefaultCircularProgress/index.js';
import { ArrowIcon } from '../../assets/icons/ArrowIcon.jsx';
import { SearchInput } from '../../components/SearchInput/index.js';
import { PlusIcon } from '../../assets/icons/PlusIcon.jsx';
import { AssetItem } from '../../modules/AssetMapView/AssetItem.jsx';
import { ExpandMore, Fullscreen, FullscreenExit } from '@mui/icons-material';
// import { useSetRecoilState } from 'recoil';
// import { sidebarOpened } from '../../core/store/index.js';
import { MapView } from '../../components/Map/MapView.jsx';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { Filter } from '../../ui/Filter/index.js';
import Papa from 'papaparse';
import moment from 'moment';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DefaultToggle } from "../NodeDetail/NodeData/DefaultToggle.jsx"
import { NodeItem } from './NodeItem.jsx';
import { MapViewNode } from '../../components/Map/MapViewNode.jsx';


export const AssetMapView = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isFullMap, setIsFullMap] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [arrayNodeData, setArrayNodeData] = useState([]);
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedGroup, setSelectedGroup] = useState(-1)
    const [selectedCategory, setSelectedCategory] = useState(-1)
    const [selectedType, setSelectedType] = useState(-1)
    const [selectedTracker, setSelectedTracker] = useState(-1)
    const [arrayGroups, setArrayGroups] = useState([]);
    const [arrayTrackers, setArrayTrackers] = useState([]);
    const [arrayCategory, setArrayCategory] = useState([]);
    const [arrayType, setArrayType] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const [arraySummeryGroupsNode, setArraySummeryGroupsNode] = useState([]);
    const [percentageInSummery, setPercentageInSummery] = useState(0);
    const [isAssetOrNode, setIsAssetOrNode] = useState(false);
    const [isAssetOrNodeTotals, setIsAssetOrNodeTotals] = useState(false);
    const arrayTopAssetStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Moving", totals: 0, color: "#32D9A2" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Idle", totals: 0, color: "#979797" },
    ]

    const arrayTopNodeStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#32D9A2" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Offline", totals: 0, color: "#979797" },
    ]

    const assetStatus = ['Idle', 'Moving'];
    useEffect(() => {
        getAllDetail()
        getSummery()
        getNodeSummery()
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!isAssetOrNode) {
                getData();
            } else {
                getNodeData();
            }
        }, 300); // Adjust the debounce delay as needed

        return () => clearTimeout(timeoutId);
    }, [isAssetOrNode]);


    useEffect(() => {
        // setIsOpenedSidebar(!isFullMap);
    }, [isFullMap]);



    const getSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Assets/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroups(resData.arrayGroup)
                setPercentageInSummery(resData.inServicePercentage)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }


    function getGorups() {
        // setIsLoading(true)
        api.get('/apis/Groups/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroups(resData)
                //setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Assets/trackers/').then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setArrayData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getNodeData = () => {
        setIsLoading(true)
        api.get('/apis/Nodes/sensors/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                var filterArray = applyFilters(resData, query)
                console.log(filterArray)
                setArrayNodeData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getAllDetail = () => {
        // setIsLoading(true)
        api.get('/apis/Assets/getFilterDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroups(resData.arrayGroup)
                setArrayCategory(resData.arrayCategory)
                setArrayType(resData.arrayType)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const getNodeSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroupsNode(resData.arrayGroup)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const applyFilters = (arrayDataAll, query, selectedStatus, selectedGroup, selectedTracker, selectedCategory, selectedType) => {
        if (!isAssetOrNode) {
            var finalArray = []
            var filteredArraySearch = arrayDataAll
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['assetName', 'serialNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

            // if (selectedStatus !== null && selectedStatus != -1) {
            //     var filteredArrayStatus = finalArray
            //         .filter((dataObj) => {
            //             let matches = true;
            //             let containsQuery = false;
            //             var speedStatus = dataObj.tracker != null && dataObj.tracker.positionSpeed != null && dataObj.tracker.positionSpeed > 0 ? "Moving" : "Idle"
            //             if (selectedStatus == speedStatus) {
            //                 containsQuery = true;
            //             }

            //             if (!containsQuery) {
            //                 matches = false;
            //             }
            //             return matches;
            //         });
            //     finalArray = [...filteredArrayStatus]
            // }

            // if (selectedTracker !== null && selectedTracker != -1) {
            //     var filteredArrayTracker = finalArray
            //         .filter((dataObj) => {
            //             let matches = true;
            //             let containsQuery = false;

            //             if (dataObj.serialNumberID === selectedTracker) {
            //                 containsQuery = true;
            //             }

            //             if (!containsQuery) {
            //                 matches = false;
            //             }
            //             return matches;
            //         });
            //     finalArray = [...filteredArrayTracker]
            // }

            // if (selectedGroup !== null && selectedGroup != -1) {
            //     var filteredArrayGroup = finalArray
            //         .filter((dataObj) => {
            //             let matches = true;
            //             let containsQuery = false;
            //             if (dataObj.groups != null && dataObj.groups.length > 0) {
            //                 if (dataObj.groups.includes(selectedGroup)) {
            //                     containsQuery = true;
            //                 }
            //             }
            //             if (!containsQuery) {
            //                 matches = false;
            //             }
            //             return matches;
            //         });
            //     finalArray = [...filteredArrayGroup]
            // }
            // if (selectedCategory !== null && selectedCategory != -1) {
            //     var fliterArrayCategory = finalArray
            //         .filter((dataObj) => {
            //             let matches = true;
            //             let containsQuery = false;

            //             if (dataObj.categoryID === selectedCategory) {
            //                 containsQuery = true;
            //             }

            //             if (!containsQuery) {
            //                 matches = false;
            //             }
            //             return matches;
            //         });
            //     finalArray = [...fliterArrayCategory]
            // }
            // if (selectedType !== null && selectedType != -1) {
            //     var fliterArrayType = finalArray
            //         .filter((dataObj) => {
            //             let matches = true;
            //             let containsQuery = false;

            //             if (dataObj.typeID === selectedType) {
            //                 containsQuery = true;
            //             }

            //             if (!containsQuery) {
            //                 matches = false;
            //             }
            //             return matches;
            //         });
            //     finalArray = [...fliterArrayType]
            // }
            // if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedTracker === -1 && selectedCategory === -1 && selectedType === -1) {
            //     return arrayDataAll
            // }
            if (query === "") {
                return arrayDataAll
            }
            return finalArray
        } else {
            var finalArrayNode = []
            var filteredArraySearchNode = arrayDataAll
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['nodeName', 'sensorNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArrayNode = filteredArraySearchNode.length > 0 ? [...filteredArraySearchNode] : []
            if (query === " ") {
                return arrayDataAll
            }
            return finalArrayNode
        }

    }

    const handleQueryChange = (event) => {
        if (!isAssetOrNode) {
            console.log("IF");
            setQuery(event.target.value)
            var filterArray = applyFilters(arrayData, event.target.value)
            setFilteredArrayData(filterArray)
        } else {
            console.log("ELSE");
            setQuery(event.target.value)
            var filterArrayNode = applyFilters(arrayNodeData, event.target.value)
            setFilteredArrayData(filterArrayNode)
        }

    };

    function calculateDuration(startTime, endTime) {
        console.log("startTime", startTime)
        console.log("endTime", endTime)
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);

        console.log("days", days)
        console.log("hours", hours)
        console.log("minutes", minutes)
        console.log("seconds", seconds);


        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "0 mins";
        }
        return durationString.trim();
    }

    function pieChart(chartID, percentageInSummery, color, name) {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            // console.log("root.dom.id", root.dom.id);
            // console.log("chartID", chartID);

            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        let chart = null
        if (chartID == 'chartdiv') {
            chart = root.container.children.push(am5percent.PieChart.new(root,
                {
                    innerRadius: am5.percent(90),
                    layout: root.verticalLayout
                }));
        } else {
            chart = root.container.children.push(am5percent.PieChart.new(root,
                {
                    innerRadius: am5.percent(91),
                    layout: root.verticalLayout
                }));
        }


        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        if (chartID == 'chartdiv') {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            series.data.setAll([
                { sector: "", size: 0 },
                { sector: "", size: 100 },
            ]);
            label.set("text", "0%");

            if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
                const remainingValue = 100 - percentageInSummery;
                console.log("remainingValue", remainingValue)
                series.data.setAll([
                    { sector: "", size: percentageInSummery },
                    { sector: "", size: remainingValue },
                ]);
                label.set("text", `${percentageInSummery}%`);
            } else {
                series.get("colors").set("colors", [
                    am5.color(0x1E75FF),
                    am5.color(0xE6E7E8),
                ]);
                series.data.setAll([
                    { sector: "", size: 0 },
                    { sector: "", size: 100 },
                ]);
                label.set("text", "0%");
            }
        } else {
            if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
                series.get("colors").set("colors", [
                    am5.color(color),
                    am5.color(0xE6E7E8),
                ]);

                const remainingValue = 100 - percentageInSummery;
                console.log("remainingValue", remainingValue)
                series.data.setAll([
                    { sector: name, size: percentageInSummery },
                    { sector: "", size: remainingValue },
                ]);
                label.set("text", `${percentageInSummery}%`);
            } else {
                series.get("colors").set("colors", [
                    am5.color(0x1E75FF),
                    am5.color(0xE6E7E8),
                ]);

                series.data.setAll([
                    { sector: "", size: 0 },
                    { sector: "", size: 100 },
                ]);
                label.set("text", "0%");
            }
        }

        series.appear(1000, 100);
    }

    return (
        <>
            <Stack width={'100%'} height='100%'>
                <Stack overflow={'hidden'} height={'calc(100vh)'} >
                    <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'} height={"100%"}>
                        {/* <Box
                            width={isFullMap || filteredArrayData.length == 0 ? 0 : 300}
                            flexShrink={0}
                            borderRight={'1px solid #E1E3E8'}
                            // overflow={'hidden'}
                            display={"auto"}
                            height={"100%"}
                            sx={{
                                transition: 'all 0.15s ease',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    width: '4px',
                                    bgcolor: 'grey.400',
                                },
                            }}
                        > */}
                        <Box
                            width={isFullMap || 300}
                            flexShrink={0}
                            borderRight={'1px solid #E1E3E8'}
                            // overflow={'hidden'}
                            display={"auto"}
                            height={"100%"}
                            sx={{
                                transition: 'all 0.15s ease',
                                overflowY: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    width: '4px',
                                    bgcolor: 'grey.400',
                                },
                                '@media not all and (min-resolution: .001dpcm)': {
                                    '@supports (-webkit-appearance:none)': {
                                        overflowY: 'scroll',
                                    },
                                },
                            }}
                        >
                            <Stack ml={'10px'} mr={'25px'} mt={'16px'}>
                                <SearchInput
                                    name={'search'}
                                    handleQueryChange={handleQueryChange}
                                    sx={{ minWidth: '200px' }} />
                            </Stack>
                            <Stack direction={'row'} justifyContent={'space-between'} ml={'16px'} mt={'12px'} mr={'25px'}>
                                <Stack direction={'row'}>
                                    <Typography fontWeight={400} fontSize={13} color={"#AAB0BC"} whiteSpace={'nowrap'} fontFamily={'Roboto'} mr={'5px'}> Sort </Typography>
                                    <KeyboardArrowDownIcon sx={{ color: '#979797', mt: -0.2 }} />
                                </Stack>
                                <Stack direction={'row'} onClick={(e) => {
                                    if (isAssetOrNodeTotals) {
                                        setIsAssetOrNodeTotals(false)
                                    } else {
                                        setIsAssetOrNodeTotals(true)
                                    }
                                }}>
                                    <Typography fontWeight={400} fontSize={13} color={"#AAB0BC"} whiteSpace={'nowrap'} fontFamily={'Roboto'} mr={'5px'}> Totals </Typography>
                                    <ExpandMore
                                        sx={{
                                            transform: isAssetOrNodeTotals == true ? 'rotate(90deg) translateY(5px)' : 'rotate(-90deg)',
                                            color: '#979797',
                                            mt: -0.2
                                        }}
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} ml={'16px'} mt={'15px'} alignItems={'center'} gap={1}>
                                <Typography fontWeight={400} fontSize={13} color={"#AAB0BC"} whiteSpace={'nowrap'} fontFamily={'Roboto'}> Assets </Typography>
                                <Switch
                                    sx={{
                                        alignItems: 'center',
                                        '.MuiSwitch-thumb': {
                                            bgcolor: '#1E75FF',
                                            width: 20,
                                            height: 20,
                                        },
                                        '.Mui-checked .MuiSwitch-thumb': {
                                            backgroundColor: '#151D26',
                                        },
                                        '& .Mui-checked+.MuiSwitch-track, .MuiSwitch-track': {
                                            backgroundColor: '#EAEBEA !important',
                                            boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 16%)',
                                            height: 20,
                                            width: 40,
                                            borderRadius: '10px',
                                        },
                                        '.MuiSwitch-switchBase': {
                                            top: '0px',
                                        },
                                    }}
                                    checked={isAssetOrNode}
                                    onChange={(e) => { setIsAssetOrNode(e.target.checked) }}
                                    name={"isAssetOrNode"}
                                />
                                <Typography fontWeight={400} fontSize={13} color={"#AAB0BC"} whiteSpace={'nowrap'} fontFamily={'Roboto'}> Nodes </Typography>
                            </Stack>
                            {isAssetOrNode == false ? filteredArrayData.length > 0 ? filteredArrayData.map((item, index) => {
                                var speedStatus = item.tracker != null && item.tracker.positionSpeed != null && item.tracker.positionSpeed > 0 ? "Moving" : "Idle"
                                return <AssetItem
                                    key={`asset-item-${index}`}
                                    id={item._id}
                                    title={item.assetName}
                                    temp={item.tracker != null && item.tracker.temperature != null ? item.tracker.temperature : ""}
                                    speed={item.tracker != null && item.tracker.positionSpeed != null ? item.tracker.positionSpeed : ""}
                                    tags={[
                                        {
                                            text: speedStatus,
                                            textSize: 12,
                                            borderColor: speedStatus == "Moving" ? 'green.main' : "#E1E3E8",
                                        },
                                    ]}
                                    deviceID={item.tracker != null && item.tracker.deviceSerialNumber != null ? item.tracker.deviceSerialNumber : ""}
                                />
                            }) : <Stack
                                justifyContent="center"
                                alignItems="center"
                                height="calc(100vh - 150px)"
                            >
                                <Typography>No Available Data</Typography>
                            </Stack> : filteredArrayData.length > 0 ? filteredArrayData.map((item, index) => {
                                var speedStatus = item.tracker != null && item.tracker.positionSpeed != null && item.tracker.positionSpeed > 0 ? "Moving" : "Idle"
                                return <NodeItem
                                    key={`node-item-${index}`}
                                    id={item._id}
                                    title={item.nodeName}
                                    temp={item.sensor != null && item.sensor.temperature != null ? item.sensor.temperature : ""}
                                    speed={item.sensor != null && item.sensor.positionSpeed != null ? item.sensor.positionSpeed : ""}
                                    deviceID={item.sensor != null && item.sensor.sensorNumber != null ? item.sensor.sensorNumber : ""}
                                    battery={item.sensor != null && item.sensor.battery != null ? item.sensor.battery : ""}
                                    isEvent={item.isEvent}
                                />
                            }) : <Stack
                                justifyContent="center"
                                alignItems="center"
                                height="calc(100vh - 150px)"
                            >
                                <Typography>No Available Data</Typography>
                            </Stack>}
                        </Box>
                        <Box flex={1} overflow={'hidden'} position={'relative'}>
                            {isAssetOrNodeTotals == true && (isAssetOrNode == false ?
                                <Stack>
                                    <Stack direction={'row'} alignItems={'center'} gap={5} ml={'25px'} mt={'19px'}>
                                        <Stack>
                                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={'15px'} >
                                                ASSETS
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'} gap={5}>
                                                {arrayTopAssetStatusData.map((status) => (
                                                    <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                                        <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                                            {status.name}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                width: 8,
                                                                height: 8,
                                                                borderRadius: '50%',
                                                                backgroundColor: status.color,
                                                                mt: '16px'
                                                            }}
                                                        />
                                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                                            {/* {status.totals} */}
                                                            {status.id == "0"
                                                                ? arrayData.length
                                                                : status.id == "1"
                                                                    ? arrayData.filter((data) => data && data.tracker && data.tracker.positionSpeed > 0).length
                                                                    : status.id == "2"
                                                                        ? arrayData.filter((data) => data && data.isEvent == "1").length
                                                                        : status.id == "4"
                                                                            ? arrayData.filter((data) => data && data.tracker && (data.tracker.positionSpeed === "0.0" || data.tracker.positionSpeed === "-")).length
                                                                            : status.totals
                                                            }
                                                        </Typography>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Stack>
                                        <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                                        <Stack width={"100%"}>
                                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                                {arraySummeryGroups.length > 0 ? "TOP METRICS" : ""}
                                            </Typography>
                                            {arraySummeryGroups.length > 0 ?
                                                <Grid container xs={12} columnSpacing={"26px"}>
                                                    {arraySummeryGroups.map((obj) => {
                                                        return (
                                                            <Grid item xs={2.4} key={obj._id}>
                                                                <Stack>
                                                                    <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                                        {obj.name + " " + `${obj.percentage}%`}
                                                                    </Typography>
                                                                    <LinearProgress
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: "6px",
                                                                            flexShrink: 0,
                                                                            borderRadius: '2px',
                                                                            bgcolor: '#EFEFEF',
                                                                            mt: "17px",
                                                                            '.MuiLinearProgress-bar': {
                                                                                borderRadius: '3px',
                                                                                bgcolor: obj.color ? obj.color : "",
                                                                            },
                                                                        }}
                                                                        variant={'determinate'}
                                                                        value={obj.percentage ? obj.percentage : 0}
                                                                    />
                                                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                                        {obj.assignedAsset}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                                : <Stack height="70px" />}
                                        </Stack>
                                    </Stack> < Box sx={{ border: '1px solid #E1E3E8' }}></Box></Stack> :
                                <Stack>
                                    <Stack direction={'row'} alignItems={'center'} gap={5} ml={'25px'} mt={'19px'}>
                                        <Stack>
                                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                                NODES
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'} gap={5} >
                                                {arrayTopNodeStatusData.map((status) => (
                                                    <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                                        <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                                            {status.name}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                width: 8,
                                                                height: 8,
                                                                borderRadius: '50%',
                                                                backgroundColor: status.color,
                                                                mt: '16px'
                                                            }}
                                                        />
                                                        <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                                            {status.id == "0" ? arrayNodeData.length : status.id == "1" ? arrayNodeData.filter((data) => data.sensor.state == "1").length : status.id == "2" ? arrayNodeData.filter((data) => data.isEvent == "1").length : status.id == "4" ? arrayNodeData.filter((data) => data.sensor.state == "0").length : status.totals}
                                                        </Typography>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Stack>
                                        <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                                        <Stack width={"100%"}>
                                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                                {arraySummeryGroupsNode.length > 0 ? "TOP METRICS" : ""}
                                            </Typography>
                                            {arraySummeryGroupsNode.length > 0 ?
                                                <Grid container xs={12} columnSpacing={"26px"}>
                                                    {arraySummeryGroupsNode.map((obj) => {
                                                        return (
                                                            <Grid item xs={2.4} key={obj._id}>
                                                                <Stack>
                                                                    <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                                        {obj.name + " " + `${obj.percentage}%`}
                                                                    </Typography>
                                                                    <LinearProgress
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: "6px",
                                                                            flexShrink: 0,
                                                                            borderRadius: '2px',
                                                                            bgcolor: '#EFEFEF',
                                                                            mt: "17px",
                                                                            '.MuiLinearProgress-bar': {
                                                                                borderRadius: '3px',
                                                                                bgcolor: obj.color ? obj.color : "",
                                                                            },
                                                                        }}
                                                                        variant={'determinate'}
                                                                        value={obj.percentage ? obj.percentage : 0}
                                                                    />
                                                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                                        {obj.assignedNode}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                                : <Stack height="70px" />}
                                        </Stack>
                                    </Stack>
                                    < Box sx={{ border: '1px solid #E1E3E8' }}></Box></Stack>)
                            }
                            {/* <ButtonBase
                                onClick={() => setIsFullMap(!isFullMap)}
                                sx={{
                                    position: 'absolute',
                                    top: 170,
                                    right: 50,
                                    zIndex: 1,
                                    width: 40,
                                    height: 40,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'primary.main',
                                    borderRadius: '50%',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                }}
                            >
                                {isFullMap && (
                                    <FullscreenExit
                                        sx={{
                                            color: 'common.white',
                                            transform: 'translateX(1px)',
                                        }}
                                    />
                                )}
                                {!isFullMap && (
                                    <Fullscreen
                                        sx={{
                                            color: 'common.white',
                                            transform: 'translateX(1px)',
                                        }}
                                    />
                                )}
                            </ButtonBase> */}
                            {isAssetOrNode == false ?
                                <MapView assetData={filteredArrayData} isFullMap={isFullMap} /> :
                                <MapViewNode nodeData={filteredArrayData} isFullMap={isFullMap} />
                            }
                        </Box>
                    </Stack>
                </Stack>
            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

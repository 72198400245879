import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';

export const DefaultSelect = memo(({ sx, label, displayKey = "value", helperText, helperTextColor, placeholder, value, onItemSelected, children, ...otherProps }) => {

    // useEffect(() => {
    //     if (defaultValue) {
    //         setValue(defaultValue);
    //     }
    // }, [defaultValue]);

    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', alignItems: 'center', ...sx }}>
            <>
                {label && <Typography
                    color='#A8A8A7'
                    fontSize={12}
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {label}
                </Typography>}

                <Select
                    label={label}
                    value={value}
                    onChange={onItemSelected}
                    IconComponent={ExpandMore}
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected) {
                            return <Typography sx={{ color: '#AAAAAA', fontSize: 14, fontWeight: 400, fontFamily: 'Roboto' }}>{placeholder}</Typography>;
                        }
                        return selected[displayKey];
                    }}
                    sx={{
                        width: '100%',
                        border: '1px solid #E2E2EA',
                        borderRadius: '6px',
                        height: 30,
                        overflow: 'hidden',
                        bgcolor: 'common.white',
                        '&::before': {
                            border: 'none',
                        },
                        '.MuiSelect-select': {
                            py: '5px',
                            fontSize: 14,
                            pl: 2,
                            color: value ? 'inherit' : '#A8A8A7', // Placeholder color
                        },
                        '& .MuiSelect-icon': {
                            right: '12px',
                            zIndex: 1,
                            color: 'primary.main',
                        },
                        '&.Mui-disabled': {
                            outline: 'none',
                            '&::before': {
                                borderBottom: 'none',
                            },
                            '&::after': {
                                borderBottom: 'none',
                            },
                        },
                    }}
                    {...otherProps}
                >
                    {children}
                </Select>
                {helperText && <Typography
                    color={helperTextColor || '#CF6460'}
                    fontSize={12}
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {helperText}
                </Typography>}
            </>
        </FormControl>
    );
});
export default DefaultSelect;


// import { FormControl, Select, Typography } from '@mui/material';
// import React, { memo } from 'react';
// import { ExpandMore } from '@mui/icons-material';

// export const DefaultSelect = memo(({ sx, label, helperText, helperTextColor, placeholder, value, onItemSelected, children, ...otherProps }) => {
//     return (
//         <FormControl variant='standard' sx={{ minWidth: '100%', alignItems: 'center', ...sx }}>
//             <>
//                 {label && (
//                     <Typography
//                         color='#A8A8A7'
//                         fontSize={12}
//                         sx={{
//                             alignSelf: 'flex-start',
//                         }}
//                     >
//                         {label}
//                     </Typography>
//                 )}

//                 <Select
//                     label={label}
//                     value={value}
//                     onChange={onItemSelected}
//                     IconComponent={ExpandMore}
//                     displayEmpty
//                     renderValue={(selected) => {
//                         if (!selected) {
//                             return <Typography sx={{ color: '#A8A8A7' }}>{placeholder}</Typography>;
//                         }
//                         return selected.value;
//                     }}
//                     sx={{
//                         width: '100%',
//                         border: '1px solid #E2E2EA',
//                         borderRadius: '6px',
//                         height: 30,
//                         overflow: 'hidden',
//                         bgcolor: 'common.white',
//                         '&::before': {
//                             border: 'none',
//                         },
//                         '.MuiSelect-select': {
//                             py: '5px',
//                             fontSize: 14,
//                             pl: 2,
//                             color: value ? 'inherit' : '#A8A8A7', // Placeholder color
//                         },
//                         '& .MuiSelect-icon': {
//                             right: '12px',
//                             zIndex: 1,
//                             color: 'primary.main',
//                         },
//                         '&.Mui-disabled': {
//                             outline: 'none',
//                             '&::before': {
//                                 borderBottom: 'none',
//                             },
//                             '&::after': {
//                                 borderBottom: 'none',
//                             },
//                         },
//                     }}
//                     {...otherProps}
//                 >
//                     {children}
//                 </Select>

//                 {helperText && (
//                     <Typography
//                         color={helperTextColor || '#CF6460'}
//                         fontSize={12}
//                         sx={{
//                             alignSelf: 'flex-start',
//                         }}
//                     >
//                         {helperText}
//                     </Typography>
//                 )}
//             </>
//         </FormControl>
//     );
// });

// export default DefaultSelect;

export const tabs = [
    {
        title: 'Company',
        value: 'company',
        roles: ['SuperAdmin', '1', '2']
    },
    {
        title: 'Users',
        value: 'users',
        roles: ['SuperAdmin', '1']
    },
    {
        title: 'Groups',
        value: 'groups',
        roles: ['SuperAdmin', '1', '2']
    },
    {
        title: 'Asset Configuration',
        value: 'asset-configuration',
        roles: ['SuperAdmin', '1', '2']
    },
    {
        title: 'Node Configuration',
        value: 'node-configuration',
        roles: ['SuperAdmin', '1', '2']
    },
];


// export const getTabsForRole = (role) => {
//     console.log("role", role)
//     return arrayTab.filter(obj => obj.roles.includes(role));
// }


// const role = localStorage.getItem("role");
// export var tabs = getTabsForRole(role);
// console.log(tabs); 
export const arraySensor = [
    { id: "1", value: "Temperature", field: "temperature", apiField: 'temperature', unit: " °F" },
    { id: "2", value: "Altitude", field: "altitude", apiField: 'positionAltitude', unit: " m" },
    { id: "3", value: "Speed", field: "speed", apiField: 'positionSpeed', unit: " mph" },
    { id: "4", value: "Battery", field: "battery", apiField: 'voltage', unit: "" },
    { id: "5", value: "Cell Signal", field: "cellSignal", apiField: 'cellSignal', unit: " %" }
]

export const arrayChartType = [
    { id: "1", value: "Line Chart" },
    { id: "2", value: "Column Chart" }
]
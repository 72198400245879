export const arraySensor = [
    { id: "1", value: "Temperature", field: "temperature" },
    { id: "2", value: "Altitude", field: "altitude" },
    { id: "3", value: "Speed", field: "speed" },
    { id: "4", value: "Battery", field: "battery" },
    { id: "5", value: "Cell Signal", field: "cellSignal" }
]

export const arrayChartType = [
    { id: "1", value: "Line Chart" },
    { id: "2", value: "Column Chart" }
]
import { FormControl, MenuItem, Select } from '@mui/material';
import React, { memo } from 'react';
import { ExpandMore } from '@mui/icons-material';

export const Filter = memo(({ sx, defaultValue = 'Active', ...props }) => {

    const handleSelectChange = (event) => {
        props.onChange && props.onChange(event);
    };
    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', alignItems: 'center', ...sx }}>
            <>
                <Select
                    IconComponent={ExpandMore}
                    sx={{
                        width: '100%',
                        border: '1px solid #E2E2EA',
                        borderRadius: '6px',
                        height: 30,
                        overflow: 'hidden',
                        bgcolor: 'common.white',
                        '&::before': {
                            border: 'none',
                        },
                        '.MuiSelect-select': {
                            py: '5px',
                            fontSize: 12,
                            pl: 2,
                        },
                        '& .MuiSelect-icon': {
                            right: '12px',
                            zIndex: 1,
                            color: 'primary.main',
                        },
                        ...sx
                    }}
                    defaultValue={defaultValue}
                    onChange={handleSelectChange}
                >
                    <MenuItem key={-1} value={-1}> {props.placeholder ? `${props.placeholder}` : "All"}</MenuItem>
                    {props.children}
                </Select>
            </>
        </FormControl>
    );
});

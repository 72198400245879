import { useNavigate } from 'react-router-dom';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const isMountedRef = useIsMountedRef();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            submit: null
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var data = await apiCalling(values.email);
                if (data !== undefined && data.success) {
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    setStatus({ success: true });
                    setSubmitting(false);
                    navigate("/authentication/login")
                } else {
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                    setStatus({ success: false });
                    setErrors({ submit: data.message });
                    setSubmitting(false);
                }

            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    });

    const apiCalling = async (email) => {
        var data = {
            email: email,
        }
        var obj = await axios.post('/apis/CompanyLogin/forgotPassword/', data)
        return obj.data
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <Stack
                alignItems={'flex-start'}
                justifyContent={'center'}
                bgcolor={'#000000'}
                py={10}
                px={20}
                width={'100%'}
                position={'relative'}
                overflow={'hidden'}
            >
                <Box
                    src={'/assets/login-bg.png'}
                    component={'img'}
                    sx={{
                        width: '1000px',
                        height: '600px',
                        position: 'absolute',
                        left: '500px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                />
                <FormikProvider value={formik}>
                    <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={350} onSubmit={formik.handleSubmit}>
                        <Box width={285} height={54} src={'/icons/logo.svg'} component={'img'} mb={4} />
                        <Box component={'form'} onSubmit={formik.handleSubmit} width={'100%'} paddingRight="10px">
                            <Stack spacing={1.5} width={'100%'}>
                                <Stack spacing={3} >
                                    <Typography color={'common.white'} fontWeight={700} fontSize={20} mb={2} textAlign="center">
                                        Forgot Password?
                                    </Typography>
                                    <Typography color={'common.white'} mb={3} textAlign="center">
                                        Please enter the email address you used
                                        to create your Signal Tracks account.
                                        You will receive a password reset link
                                        that will expire in 30 minutes.
                                    </Typography>
                                    <Stack direction={'column'} width={'100%'}>
                                        <Typography mb={1.5} color={'common.white'}>
                                            Email
                                        </Typography>
                                        <TextField
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '17px 25px',
                                                    borderRadius: '10px',
                                                    background: 'white',
                                                    border: '1px solid #E1E3E8',
                                                },
                                            }}
                                            type={'email'}
                                            placeholder='example@oilservices.com'
                                            name={'email'}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            error={Boolean(formik.touched.email && formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Stack>
                                </Stack>
                                <Box />
                                <Stack spacing={3} direction={'row'} width={'100%'} >
                                    <Button
                                        sx={{
                                            fontSize: 20,
                                            py: 1.2,
                                            borderRadius: '30px',
                                            maxWidth: 300,
                                            width: '100%',
                                            alignSelf: 'center',
                                            bgcolor: 'common.white',
                                            color: 'common.black',
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                                bgcolor: 'common.white',
                                            },
                                        }}
                                        onClick={() =>
                                            navigate(-1)
                                        }
                                    >
                                        Cancle
                                    </Button>
                                    <Button
                                        sx={{
                                            fontSize: 20,
                                            py: 1.2,
                                            borderRadius: '30px',
                                            maxWidth: 300,
                                            width: '100%',
                                            alignSelf: 'center',
                                            bgcolor: 'common.white',
                                            color: 'common.black',
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                                bgcolor: 'common.white',
                                            },
                                        }}
                                        type={'submit'}
                                    >
                                        Send
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                        <Typography color={'common.white'} mt={4}>
                            SIGNALTRACKS, LLC
                        </Typography>
                    </Stack>
                </FormikProvider>
            </Stack>
        </>
    )

}
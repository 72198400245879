import { Box, Grid, Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Success = (props) => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/authentication/login');
    };

    return (
        <Stack
            alignItems={'flex-start'}
            justifyContent={'center'}
            bgcolor={'#000000'}
            py={10}
            px={20}
            width={'100%'}
            position={'relative'}
            overflow={'hidden'}
        >
            <Box
                src={'/assets/login-bg.png'}
                component={'img'}
                sx={{
                    width: '1000px',
                    height: '600px',
                    position: 'absolute',
                    left: '500px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            />

            <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={350}>
                <Typography color={'common.white'} fontWeight={700} fontSize={25} mb={2}>
                    Success!
                </Typography>
                <Stack
                    spacing={3}
                    onSubmit={handleSubmit}
                    component={'form'}
                >
                    <Typography fontWeight={700} color={'common.white'}>
                        You have successfully created your
                        account.
                    </Typography>
                    <Typography color={'common.white'}>
                        You will receive an email verification
                        link. Please check your spam inbox in
                        the event you do not see your
                        verification email.
                    </Typography>
                    <Typography color={'common.white'}>
                        Once you verify your email your account
                        will be activated.
                    </Typography>
                    <Button
                        sx={{
                            fontSize: 20,
                            py: 1.2,
                            borderRadius: '30px',
                            maxWidth: 300,
                            width: '100%',
                            alignSelf: 'center',
                            bgcolor: 'common.white',
                            color: 'common.black',
                            textTransform: 'capitalize',
                            '&:hover': {
                                bgcolor: 'common.white',
                            },
                        }}
                        type={'submit'}
                    >
                        Sign In
                    </Button>
                </Stack>
                <Typography color={'common.white'} mt={4}>
                    SIGNALTRACKS, LLC
                </Typography>
            </Stack>

        </Stack>
    )
}
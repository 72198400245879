import { BoxIcon } from '../../../assets/icons/BoxIcon.jsx';
import { CircleIcon } from '../../../assets/icons/CircleIcon.jsx';
import { DrawIcon } from '../../../assets/icons/DrawIcon.jsx';

export const toolsTabs = [
    {
        title: 'Box',
        value: 'draw_rectangle',
        icon: <BoxIcon />,
    },
    {
        title: 'Circle',
        value: 'draw_circle',
        icon: <CircleIcon />,
    },
    {
        title: 'Draw',
        value: 'draw_polygon',
        icon: <DrawIcon />,
    },
];

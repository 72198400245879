import { Typography, IconButton, Stack } from '@mui/material';
import { Close, Edit } from '@mui/icons-material';

export const geoFenceColumns = (serialNumber, onDeleteClick, onEditClick) => [
    // {
    //     field: 'session',
    //     headerName: 'Device SN',
    //     width: 100,
    //     renderCell: ({ value }) => (
    //         <Typography color={'secondary'} fontSize={13}>
    //             {serialNumber}
    //         </Typography>
    //     ),
    // },
    { field: 'name', headerName: 'Zone Name', width: 120 },
    {
        field: 'notification',
        headerName: 'Alert',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>
                {value == "1" ? "Yes" : "No"}
            </Typography>
        ),
    },
    {
        field: 'edit',
        headerName: '',
        width: 30,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton
                sx={{
                    padding: "3",
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#262B40',
                    color: 'white',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    onEditClick(params.row)
                }}
            >
                <Edit fontSize="small" />
            </IconButton >
        ),
    },
    {
        field: 'delete',
        headerName: '',
        width: 30,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton
                sx={{
                    padding: "3",
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#262B40',
                    color: 'white',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    onDeleteClick(params.row)
                }}
            >
                <Close fontSize="small" />
            </IconButton >
        ),
    },
];

export const sharingColumns = (onDeleteClick, onEditClick) => [

    { field: 'email', headerName: 'Email', width: 120 },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>
                {value == "1" ? "Yes" : "No"}
            </Typography>
        ),
    },
    {
        field: 'edit',
        headerName: '',
        width: 30,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton
                sx={{
                    padding: "3",
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#262B40',
                    color: 'white',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    onEditClick(params.row)
                }}
            >
                <Edit fontSize="small" />
            </IconButton >
        ),
    },
    {
        field: 'delete',
        headerName: '',
        width: 30,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton
                sx={{
                    padding: "3",
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#262B40',
                    color: 'white',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    onDeleteClick(params.row)
                }}
            >
                <Close fontSize="small" />
            </IconButton >
        ),
    },
];

export const documentColumns = (onDeleteClick) => [

    { field: 'name', headerName: 'File Name', width: 300 },
    {
        field: 'delete',
        headerName: '',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <Stack alignItems={'flex-end'} width={'100%'}>
                <IconButton
                    sx={{
                        padding: "3",
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#262B40',
                        color: 'white',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        onDeleteClick(params.row)
                    }}
                >
                    <Close fontSize="small" />
                </IconButton >
            </Stack>
        ),
    },
];
import React from 'react';
import { Box, Typography } from '@mui/material';

const TimelineTimePanel = () => {
  // Define the number of hours and minute markers
  const totalHours = 24;
  const displayHours = 8; // Number of hours to display
  const hourStep = totalHours / displayHours; // Step between displayed hours

  // Generate hours for display
  const hours = Array.from({ length: displayHours }, (_, i) => i * hourStep).filter((hour) => hour !== 0);
  const minuteMarkers = Array.from({ length: 144 }, (_, i) => i * 10); // Markers every 10 minutes

  return (
    <Box sx={{ position: 'relative', height: '60px', width: '100%',overflow:'hidden' }}>
      {hours.map((hour) => (
        <Box
        key={hour}
        sx={{
          position: 'absolute',
          left: `${(hour / totalHours) * 100}%`,
          transform: 'translateX(-50%)',
          textAlign: 'center',
          height:'100%'
        }}
      >
        {/* Container for hour label and line */}
        <Box sx={{ position: 'relative', height:'100%' }}>
          <Typography fontFamily={'Roboto'} fontWeight={400} fontSize={14} color={'#051219'} sx={{ pt:1.5  }}>
            {hour === 0 || hour === 12 ? '12:00' : `${hour % 12}:00`} {hour < 12 ? 'AM' : 'PM'}
          </Typography>
          <Box sx={{ 
            height: '16px', 
            width: '2px', 
            backgroundColor: '#051219', 
            position: 'absolute', 
            left: '50%', 
            transform: 'translateX(-50%)', 
            bottom: 0, // Align to bottom
          }} />
        </Box>
      </Box>
      ))}

      {/* Minute Markers */}
      {minuteMarkers.map((minute) => {
        // Calculate the hour index and position within that hour
        const hourIndex = Math.floor(minute / 60);
        const positionInHour = minute % 60;
        const hourWidth = 100 / 8; // 100% divided by the number of hours
        const positionPercent = (hourIndex * hourWidth) + ((positionInHour / 60) * hourWidth);

        return (
          <Box
            key={minute}
            sx={{
              position: 'absolute',
              left: `${positionPercent}%`,
              transform: 'translateX(-50%)',
              textAlign: 'center',
              bottom: 0, // Align to bottom
            }}
          >
            {/* Draw a smaller line every 10 minutes except for full hours */}
            {minute % 10 === 0 && minute % 60 !== 0 && (
              <Box sx={{ height: '8px', width: '1px', backgroundColor: '#E6E7E8', marginTop: '4px' }} />
            )}
          </Box>
        );
      })}

     
    </Box>
  );
};

export default TimelineTimePanel;

// const TimelineTimePanel = () => {
//     const hours = Array.from({ length: 8 }, (_, i) => i * 3);
//     const minuteMarkers = Array.from({ length: 144 }, (_, i) => i * 10);
//   return (
//       <Box sx={{ position: 'relative', height: '60px' }}>
//          {hours.map((hour) => (
//           <Box
//             key={hour}
//             sx={{
//               position: 'absolute',
//               left: `${(hour / 24) * 100}%`,
//               transform: 'translateX(-50%)',
//               textAlign: 'center',
//               bottom: 0, // Align to bottom
//             }}
//           >
//             <Typography fontFamily={'Roboto'} fontWeight={400} fontSize={14} color={'#051219'} sx={{ marginBottom: '4px' }}>
                
//               {hour === 0 || hour === 12 ? '12:00' : `${hour % 12}:00`} {hour < 12 ? 'AM' : 'PM'}
//             </Typography>
//             <Box sx={{ height: '16px', width: '2px', backgroundColor: '#051219', marginTop: '4px' }} />
//           </Box>
//         ))}
//         {minuteMarkers.map((minute) => {
//         // Calculate the position within the hour block
//         const hourIndex = Math.floor(minute / 60);
//         const positionInHour = minute % 60;
//         const hourWidth = 100 / 8; // 100% divided by the number of hours
//         const positionPercent = (hourIndex * hourWidth) + (positionInHour / 60) * hourWidth;

//         return (
//           <Box
//             key={minute}
//             sx={{
//               position: 'absolute',
//               left: `${positionPercent}%`,
//               transform: 'translateX(-50%)',
//               textAlign: 'center',
//               bottom: 0, // Align to bottom
//             }}
//           >
//             {/* Draw a smaller line every 10 minutes except for full hours */}
//             {minute}
//             {minute % 10 === 0 && minute % 60 !== 0 && (
//               <Box sx={{ height: '8px', width: '1px', backgroundColor: '#E6E7E8', marginTop: '4px' }} />
//             )}
//           </Box>
//         );
//       })}
//         {/* {minuteMarkers.map((minute) => {
//         const hourStart = Math.floor(minute / 60) * 60;
//         const hourWidth = 100 / 8; // 100% divided by the number of hours

//         return (
//           <Box
//             key={minute}
//             sx={{
//               position: 'absolute',
//               left: `${((minute % 60) / 60) * hourWidth + (Math.floor(minute / 60) * hourWidth)}%`,
//               transform: 'translateX(-50%)',
//               textAlign: 'center',
//               bottom: 0, // Align to bottom
//             }}
//           >
//             {minute % 10 === 0}
//             {minute % 10 === 0 && minute % 60 !== 0 && (
//               <Box sx={{ height: '8px', width: '1px', backgroundColor: '#E6E7E8', marginTop: '4px' }} />
//             )}

//           </Box>
//         );
//       })} */}

//         {/* {minuteMarkers.map((minute) => (
//           <Box
//             key={minute}
//             sx={{
//               position: 'absolute',
//               left: `${(minute / 1440) * 100}%`,
//               transform: 'translateX(-50%)',
//               textAlign: 'center',
//               bottom: 0, // Align to bottom
//             }}
//           >
//             {minute % 60 !== 0 && (
//               <Box sx={{ height: '8px', width: '1px', backgroundColor: '#E6E7E8', marginTop: '4px' }} />
//             )}
//           </Box>
//         ))} */}
//         {/* Current Time Indicator */}
//         <Box
//           sx={{
//             position: 'absolute',
//             left: '50%', // Example: current time at the midpoint
//             bottom: 0,
//             width: '8px',
//             height: '8px',
//             backgroundColor: '#000',
//             borderRadius: '50%',
//             transform: 'translateX(-50%)',
//             marginTop: '16px',
//           }}
//         />
//       </Box>
//   );
// };

// export default TimelineTimePanel;

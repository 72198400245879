export const SearchIcon = () => {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M16.233 17.081L13.258 14.105C11.7577 15.3836 9.82086 16.0323 7.85306 15.9152C5.88526 15.7982 4.03903 14.9244 2.70085 13.4769C1.36268 12.0294 0.636311 10.1204 0.673807 8.1495C0.711303 6.17858 1.50976 4.29857 2.90202 2.90302C4.26745 1.53363 6.1017 0.733461 8.03427 0.66415C9.96685 0.594839 11.8537 1.26155 13.3137 2.52961C14.7738 3.79767 15.6981 5.57258 15.9001 7.49581C16.1021 9.41905 15.5667 11.3473 14.402 12.891L17.414 15.902C17.5318 16.0202 17.6118 16.1707 17.644 16.3344C17.6762 16.4981 17.6591 16.6677 17.5948 16.8216C17.5306 16.9756 17.4221 17.1071 17.2831 17.1993C17.1441 17.2916 16.9809 17.3406 16.814 17.34C16.7047 17.3381 16.597 17.3141 16.4972 17.2696C16.3974 17.2251 16.3075 17.161 16.233 17.081ZM4.08102 4.08102C3.10519 5.05679 2.49788 6.34073 2.36255 7.71406C2.22722 9.0874 2.57225 10.4652 3.33885 11.6126C4.10545 12.7601 5.2462 13.6063 6.56673 14.007C7.88726 14.4077 9.30587 14.3382 10.5809 13.8102C11.8559 13.2823 12.9084 12.3286 13.5591 11.1116C14.2098 9.89467 14.4184 8.48975 14.1494 7.13624C13.8804 5.78272 13.1504 4.56435 12.0838 3.6887C11.0172 2.81305 9.68001 2.3343 8.30002 2.33402C6.71764 2.33473 5.20023 2.96242 4.08102 4.08102Z'
                fill='#92929D'
            />
        </svg>
    );
};

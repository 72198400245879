import { useLocation, useNavigate } from 'react-router-dom';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import showPasswordIcon from '../../assets/icons/show-password.svg';
import hidePasswordIcon from '../../assets/icons/hide-password.svg';

export const ResetPassword = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const isMountedRef = useIsMountedRef();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            submit: null
        },
        validationSchema: Yup.object().shape({
            password: Yup
                .string()
                .required('Password is required'),
            confirmPassword: Yup
                .string()
                .required('Confirm password is required').oneOf([Yup.ref('password')], 'Password does not same')
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                console.log("Data", values)
                var data = await apiCalling(values.password, values.confirmPassword);
                console.log(data)
                if (data !== undefined && data.success) {
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    setStatus({ success: true });
                    setSubmitting(false);
                    navigate("/authentication/login")
                } else {
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                    setStatus({ success: false });
                    setErrors({ submit: data.message });
                    setSubmitting(false);
                }

            } catch (err) {
                console.log("inCATCH", err);
                console.error(err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    });

    useEffect(() => {
        console.log("queryParams", queryParams.token)
    }, []);

    const apiCalling = async (password, confirmPassword) => {
        var data = {
            newPassword: password,
            token: queryParams.token
        }
        console.log("obj", data)

        var obj = await axios.post('/apis/CompanyLogin/changePassword/', data)
        return obj.data
    }

    return (
        <Stack
            alignItems={'flex-start'}
            justifyContent={'center'}
            bgcolor={'#000000'}
            py={10}
            px={20}
            width={'100%'}
            position={'relative'}
            overflow={'hidden'}
        >
            <Box
                src={'/assets/login-bg.png'}
                component={'img'}
                sx={{
                    width: '1000px',
                    height: '600px',
                    position: 'absolute',
                    left: '500px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            />
            <FormikProvider value={formik}>
                <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={350}>
                    <Box width={285} height={54} src={'/icons/logo.svg'} component={'img'} mb={4} />
                    <Typography color={'common.white'} fontWeight={700} fontSize={25} mb={2}>
                        Reset Password
                    </Typography>
                    <Box component={'form'} onSubmit={formik.handleSubmit} width={'100%'}>
                        <Stack spacing={1.5} width={'100%'}>
                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'common.white'}>
                                    Pssword
                                </Typography>
                                <Box position={'relative'}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '30px', //'50%',
                                            right: '30px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            zIndex: 1,
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                        }}
                                        component={'img'}
                                        src={showPassword ? hidePasswordIcon : showPasswordIcon}
                                        onClick={() => setShowPassword((prevState) => !prevState)}
                                    />
                                    <TextField
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                lineHeight: '5',
                                                padding: '17px 60px 17px 25px',
                                                borderRadius: '10px',
                                                background: 'white',
                                                border: '1px solid #E1E3E8',
                                                '&::placeholder': {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(calc(-50% + 2px))',
                                                },
                                            },
                                        }}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='*************************'
                                        name={'password'}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        error={Boolean(formik.touched.password && formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Box>
                            </Stack>
                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'common.white'}>
                                    Confirm Password
                                </Typography>
                                <Box position={'relative'}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '30px', //'50%',
                                            right: '30px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            zIndex: 1,
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                        }}
                                        component={'img'}
                                        src={showConfirmPassword ? hidePasswordIcon : showPasswordIcon}
                                        onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                                    />
                                    <TextField
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                lineHeight: '5',
                                                padding: '17px 60px 17px 25px',
                                                borderRadius: '10px',
                                                background: 'white',
                                                border: '1px solid #E1E3E8',
                                                '&::placeholder': {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(calc(-50% + 2px))',
                                                },
                                            },
                                        }}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder='*************************'
                                        name={'confirmPassword'}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    />
                                </Box>
                            </Stack>
                            <Box />
                            <Button
                                sx={{
                                    fontSize: 20,
                                    py: 1.2,
                                    borderRadius: '30px',
                                    maxWidth: 300,
                                    width: '100%',
                                    alignSelf: 'center',
                                    bgcolor: 'common.white',
                                    color: 'common.black',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        bgcolor: 'common.white',
                                    },
                                }}
                                type={'submit'}
                                disabled={formik.isSubmitting}
                            >
                                Send
                            </Button>
                        </Stack>
                    </Box>
                    <Typography
                        fontWeight={700}
                        href={'https://google.com'}
                        component={'a'}
                        target={'_blank'}
                        color={'common.white'}
                        mt={4}
                    >
                        SIGNALTRACKS, LLC
                    </Typography>
                </Stack>
            </FormikProvider>
        </Stack>
    );
};
export const tabs = [
    {
        title: 'Light',
        value: 'clwun35vw012v01qsagva4ax3',
    },
    {
        title: 'Satellite',
        value: 'clwunxsro00ql01po8infgxuq',
    },
    {
        title: 'Street',
        value: 'clwunzkj401an01pn7nb97tu1',
    },
];

export const trackersTabs = [
    {
        title: 'Primary',
        value: 'primary',
    },
    {
        title: 'Secondary',
        value: 'secondary',
    },
];

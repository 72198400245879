import { Backdrop, Box, ButtonBase, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { DefaultInput } from '../../../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../../../ui/DefaultSelect/index.js';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { ConfirmAlert } from '../../../../../customControls/confirmAlert.js';
import { useEffect, useState } from 'react';
import api from '../../../../../lib/axios.js';
import { arrayStatus } from '../../User/Data/UserData.jsx';

export const AssetCategoriesManage = ({ children, selectedDataID, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const formik = useFormik({
        initialValues: {
            submit: null,
            name: '',
            description: '',
            selectedStatus: null
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Category name is required'),
            description: Yup.string().required('Description is required'),
            selectedStatus: Yup.object().required('Status is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    name: values.name,
                    description: values.description,
                    selectedStatus: values.selectedStatus,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apiCalling(values.name, values.description, values.selectedStatus);
                console.log("Data", data.data.success)
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    console.log("IF")
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Category ' + (selectedDataID == "0" ? "added" : "updated"), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    onClose(null)
                } else {
                    console.log("ELSE")
                    setStatus({ success: false });
                    setErrors({ submit: data.data.message });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })

    useEffect(() => {
        if (selectedDataID !== "" && selectedDataID !== "0") {
            getData();
        } else {
            setIsLoading(false);
        }
    }, [selectedDataID]);

    function getData() {
        setIsLoading(true)
        api.get('/apis/Categories/' + selectedDataID,).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                console.log("got data", data)

                var selectedStatus = arrayStatus.filter(status => status.id == data.status)
                if (selectedStatus.length > 0) {
                    selectedStatus = selectedStatus[0]
                } else {
                    selectedStatus = null
                }

                var dataValues = {
                    submit: null,
                    name: data.name,
                    description: data.description,
                    selectedStatus: selectedStatus,
                }
                formik.setValues(dataValues)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(name, description, selectedStatus) {
        var data = {
            name: name,
            description: description,
            status: selectedStatus.id,
        }
        console.log("data", data)
        if (selectedDataID != "0") {
            return await api.put('/apis/Categories/' + selectedDataID, data)
        } else {
            return await api.post('/apis/Categories/', data)
        }

    }

    function deleteAssetCategory() {
        setIsLoading(true)
        api.delete('/apis/Categories/' + selectedDataID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Category deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                onClose(null)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteAssetCategory()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    return (
        <>
            <Stack>
                <FormikProvider value={formik}>
                    <Stack
                        px={4}
                        pt={3}
                        pb={2}
                        gap={4}
                        overflowY={'auto'}
                        minWidth={'700px'}
                        height={'750px'}
                        maxHeight={'calc(100vh - 100px)'}
                        maxWidth={'700px'}
                        position={'fixed'}
                        zIndex={10}
                        sx={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            overflowY: 'auto',
                            borderRadius: '10px',
                            bgcolor: 'common.white',
                            border: '1px solid #D5D5D5',
                        }}
                        component={'form'}
                        onSubmit={formik.handleSubmit}
                    >
                        <Typography fontSize={18}>Asset Category</Typography>
                        <Stack gap={3} maxWidth={400}>
                            <DefaultInput
                                label={'Category Name'}
                                placeholder={'E.g. ABC'}
                                value={formik.values.name}
                                name={'name'}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <DefaultSelect
                                sx={{
                                    '.MuiInputBase-root': {
                                        height: 40,
                                    },
                                }}
                                label={'Status'}
                                value={formik.values.selectedStatus}
                                onItemSelected={(e) => {
                                    formik.setFieldValue('selectedStatus', e.target.value)
                                }}
                                helperText={formik.touched.selectedStatus && formik.errors.selectedStatus}
                                error={Boolean(formik.touched.selectedStatus && formik.errors.selectedStatus)}
                                placeholder="Select status"
                            >
                                {arrayStatus.map((item, index) => (
                                    <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                ))}
                            </DefaultSelect>
                            <DefaultInput
                                label={'Description'}
                                placeholder={'Description'}
                                value={formik.values.description}
                                name={'description'}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.description && formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Stack>
                        <Box flex={1}>{children}</Box>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Stack>
                                {selectedDataID != "0" && <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #FF3941',
                                        bgcolor: 'common.white',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    onClick={(e) => {
                                        setDeleteConfirmationShouldOpen(true)
                                    }}
                                >
                                    <Typography fontSize={14} fontWeight={700}>
                                        Delete
                                    </Typography>
                                </ButtonBase>}
                            </Stack>
                            <Stack direction={'row'} gap={2}>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: 'primary.main',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    onClick={() => onClose(null)}
                                >
                                    <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                        Cancel
                                    </Typography>
                                </ButtonBase>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: '#EFEFEF',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                >
                                    <Typography fontSize={14} fontWeight={700}>
                                        Save
                                    </Typography>
                                </ButtonBase>
                            </Stack>
                        </Stack>
                    </Stack>
                </FormikProvider>

            </Stack>

            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
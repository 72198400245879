export const nodeConfigurationTabs = [
    {
        title: 'Categories',
        value: 'categories',
    },
    {
        title: 'Types',
        value: 'types',
    },
];

export const tabs = [
    {
        title: 'Graph Data',
        value: 'graphData',
    },
    {
        title: 'Logs',
        value: 'logs',
    },
]

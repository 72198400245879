import { Close, Edit } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

export const arrayGraphColor = [
    { name: '', code: '#130870' },
    { name: '', code: '#2B35AF' },
    { name: '', code: '#4360ED' },
    { name: '', code: '#4895EF' },
    { name: '', code: '#4CC9F0' },
    { name: '', code: '#39597E' },
    { name: '', code: '#6E9FC0' },
    { name: '', code: '#A5CAE9' },
    { name: '', code: '#EAECED' },
    { name: '', code: '#ACACAC' },
    { name: '', code: '#4D8BC3' },
    { name: '', code: '#96CBFC' },
    { name: '', code: '#C1E2FB' },
    { name: '', code: '#FFC3D9' },
    { name: '', code: '#FE99BD' },
    { name: '', code: '#07075B' },
    { name: '', code: '#0D49B5' },
    { name: '', code: '#A4DBE1' },
    { name: '', code: '#EC0201' },
    { name: '', code: '#A80007' },
    { name: '', code: '#7ED449' },
    { name: '', code: '#27B270' },
    { name: '', code: '#F5C500' },
    { name: '', code: '#FFDD60' },
    { name: '', code: '#FD7978' },
];

export const arrayGraphData = [
    { id: '1', name: 'Circle Graph 1' },
    { id: '2', name: 'Circle Graph 2' },
    { id: '3', name: 'Circle Graph 3' },
    { id: '4', name: 'Circle Graph 4' },
    { id: '5', name: 'Circle Graph 5' },
];

export const documentColumns = (onDeleteClick) => [

    { field: 'name', headerName: 'File Name', width: 240 },
    {
        field: 'delete',
        headerName: '',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <Stack alignItems={'flex-end'} width={'100%'}>
                <IconButton
                    sx={{
                        padding: "3",
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#262B40',
                        color: 'white',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        onDeleteClick(params.row)
                    }}
                >
                    <Close fontSize="small" />
                </IconButton >
            </Stack>
        ),
    },
];

export const documentRow = [
    { id: 1, name: 'TEST' },
    { id: 2, name: 'DOCUMENTT' },
];

export const geoFenceColumns = (onDeleteClick, onEditClick) => [

    { field: 'name', headerName: 'Zone Name', width: 120 },
    {
        field: 'notification',
        headerName: 'Alert',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>
                {value == "1" ? "Yes" : "No"}
            </Typography>
        ),
    },
    {
        field: 'edit',
        headerName: '',
        width: 30,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton
                sx={{
                    padding: "3",
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#262B40',
                    color: 'white',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    onEditClick(params.row)
                }}
            >
                <Edit fontSize="small" />
            </IconButton >
        ),
    },
    {
        field: 'delete',
        headerName: '',
        width: 30,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton
                sx={{
                    padding: "3",
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#262B40',
                    color: 'white',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    onDeleteClick(params.row)
                }}
            >
                <Close fontSize="small" />
            </IconButton >
        ),
    },
];
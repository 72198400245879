import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';

export const CustomDatepicker = ({ sx, ...props }) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [date, setDate] = useState(dayjs(props.initialDate));

    useEffect(() => {
        setDate(dayjs(props.initialDate));
    }, [props.initialDate]);

    const toggleDatePicker = (event) => {
        setIsDatePickerOpen(!isDatePickerOpen);
    };

    const handleWeekChange = (newDate) => {
        setDate(newDate);
        props.handleDateChange(newDate);
        // setIsDatePickerOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '2px',
                    borderRadius: '5px',
                    bgcolor: 'common.white',
                    border: '1px solid #E2E2EA',
                    backgroundColor: '#FFFFFF',
                    width: "150px",
                    height: "36px",
                    '.MuiIconButton-root': {
                        width: '102px',
                        mr: '0',
                        borderRadius: '5px',
                        height: '35px',
                        '&::after': {
                            content: "''",
                            position: 'absolute',
                            height: '25px',
                            width: '1px',
                            backgroundColor: 'grey.light',
                            left: 0,
                        },
                    },
                }}
            >
                <Button sx={{ width: "100%", bgcolor: "#FFFFFF" }} onClick={toggleDatePicker}>
                    <Stack justifyContent={'space-between'} direction={'row'} width={"100%"} px={1} alignItems={"center"}>
                        <Typography fontSize={12} textTransform={'capitalize'}>  {dayjs(date).isSame(dayjs(), 'day') ? 'Today' : dayjs(date).format('MM-DD-YYYY')} </Typography>
                        <KeyboardArrowDownIcon sx={{ color: '#374147' }} />
                    </Stack>
                </Button>
                {isDatePickerOpen && (<DateTimePicker
                    slots={{
                        openPickerIcon: ArrowForwardIos,
                    }}
                    views={['year', 'month', 'day']}
                    sx={{
                        width: '0px',
                        height: '0px',
                        marginTop: -4,
                        borderRadius: '5px',
                        bgcolor: 'common.white',
                        border: '1px solid #E2E2EA',
                        position: 'absolute',
                        overflow: 'hidden',
                        '.MuiOutlinedInput-input': {
                            p: '0 12px',
                            height: '36px',
                            order: 2,
                        },
                        '.MuiInputBase-root': {
                            pr: 0,
                        },
                        '.MuiInputAdornment-root': {
                            ml: 0,
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            p: '0',
                        },
                        '.MuiIconButton-root': {
                            width: '100%',
                            mr: '0',
                            height: '100%',
                            position: 'absolute',
                            borderRadius: '0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            svg: {
                                width: '16px',
                                height: '16px',
                                transform: 'rotate(90deg)',
                            },
                        },
                    }}
                    open={isDatePickerOpen}
                    onClose={() => {
                        setIsDatePickerOpen(false)
                    }}
                    defaultValue={props.initialDate}
                    onChange={handleWeekChange}
                />
                )}
            </Box>

        </LocalizationProvider >
    );
};

import { Box, Stack, Typography, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg'
import CloseIcon from '../../../assets/icons/CloseIcon.svg'

export const DefaultChart = ({ arrayData, arraySelectedSensor, arraySensorDataType, sensorData, selectedChart, selectedSensor, onUpChartClick, ondownChartClick, onCloseChartClick, chartId, index, sx }) => {
    let fieldValue = arraySensorDataType.find(obj => obj.id == selectedSensor)?.field;
    let symbol = arraySensorDataType.find(obj => obj.id == selectedSensor)?.symbol || "";
    const calculateMinAvgMax = (data, field) => {

        if (!data || data.length === 0) return { min: 0, avg: 0, max: 0 };
        var values = []
        if (field == "pin") {
            data.map(item => {
                values = item.arraySecondaryData
                    .map((secondaryData) => Number(secondaryData[field]))
                    .filter((value) => !isNaN(value));
            })

        } else {
            values = data
                .map((item) => Number(item[field]))
                .filter((value) => !isNaN(value));
        }


        if (values.length === 0) return { min: 0, avg: 0, max: 0 };

        const min = Math.min(...values);

        const max = Math.max(...values);

        const avg = (values.reduce((acc, curr) => acc + curr, 0) / values.length).toFixed(2);

        return { min, avg, max };
    };

    const { min, avg, max } = calculateMinAvgMax(arrayData, fieldValue);

    useEffect(() => {
        if (fieldValue != null && fieldValue != "") {
            if (selectedChart == "1") {
                LineChart()
            } else if (selectedChart == "2") {
                ColumnChart()
            }
        }
    }, [selectedChart, arrayData, fieldValue]);

    function LineChart() {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartId) {
                root.dispose();
            }
        });

        let root = am5.Root.new(chartId);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        root.dateFormatter.setAll({
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["valueX"]
        });

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));

        let easing = am5.ease.linear;

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.1,
            groupData: false,
            baseInterval: {
                timeUnit: "minute",
                count: 1
            },
            dateFormats: {
                hour: "hh:mm a",
                minute: "hh:mm a",
                day: "MMM dd",
            },
            periodChangeDateFormats: {
                day: "MMM dd",
                minute: "hh:mm a",
                hour: "MMM dd",    // Show "MMM DD" when the date changes
            },
            tooltipDateFormats: {
                hour: "hh:mm a" // 12-hour format with minutes and AM/PM for tooltips
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 70
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));
        // xAxis.get("renderer").labels.template.setAll({
        //     rotation: -90, // Rotate labels by -90 degrees
        //     centerY: am5.p50, // Center vertically
        //     centerX: am5.p100, // Center horizontally (align with the axis line)
        //     paddingRight: 10 // Add some padding to prevent overlapping with the axis
        //   });
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.2,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });
        yAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            minBulletDistance: 10,
            connect: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldValue,
            valueXField: "timestamp",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueX}: {valueY}"
            })
        }));

        series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
        });

        series.strokes.template.setAll({
            strokeWidth: 2
        });

        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["timestamp"]
        });

        let data = [];

        arrayData.map((chartData) => {
            if (sensorData.product == "4") {
                let obj = {
                    [fieldValue]: parseFloat(chartData[fieldValue]),
                    "timestamp": new Date(chartData.createdAt)
                };
                data.push(obj);

            } else {
                let obj = {
                    "battery": parseFloat(chartData.battery),
                    "temperature": parseFloat(chartData.temperature),
                    "altitude": parseFloat(chartData.altitude),
                    "speed": parseFloat(chartData.speed),
                    "cellSignal": parseFloat(chartData.cellSignal),
                    "timestamp": new Date(chartData.timestamp)
                };

                chartData.arraySecondaryData.forEach((secondaryData) => {
                    obj.pin = parseFloat(secondaryData.value);
                });
                data.push(obj);
            }
        });
        data.reverse()
        series.data.setAll(data);

        // series.bullets.push(function () {
        //     let circle = am5.Circle.new(root, {
        //         radius: 4,
        //         fill: root.interfaceColors.get("background"),
        //         stroke: series.get("fill"),
        //         strokeWidth: 2
        //     });

        //     return am5.Bullet.new(root, {
        //         sprite: circle
        //     });
        // });

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        // }));

        chart.appear(1000, 100);
    }

    function ColumnChart() {

        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartId) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartId);

        const myTheme = am5.Theme.new(root);

        // myTheme.rule("AxisLabel", ["minor"]).setAll({
        //     dy: 1
        // });

        root.dateFormatter.setAll({
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["valueX"]
        });
        root.setThemes([
            am5themes_Animated.new(root),
            myTheme,
            am5themes_Responsive.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));

        cursor.lineY.set("visible", false);

        // generateData(value)

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0,
            baseInterval: {
                timeUnit: "minute",
                count: 1
            },
            dateFormats: {
                hour: "hh:mm a",
                minute: "hh:mm a",
                day: "MMM dd",
            },
            periodChangeDateFormats: {
                day: "MMM dd",
                minute: "hh:mm a",
                hour: "MMM dd",    // Show "MMM DD" when the date changes
            },
            tooltipDateFormats: {
                hour: "hh:mm a" // 12-hour format with minutes and AM/PM for tooltips
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 70
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        // xAxis.set("minorDateFormats", {
        //     "day": "dd",
        //     "month": "MM"
        // });

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldValue,
            valueXField: "timestamp",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            })
        }));

        series.columns.template.setAll({ strokeOpacity: 0 })

        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        // }));
        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["timestamp"]
        });
        let data = []
        arrayData.map((chartData) => {
            if (sensorData.product == "4") {
                let obj = {
                    [fieldValue]: parseFloat(chartData[fieldValue]),
                    "timestamp": new Date(chartData.createdAt)
                };
                data.push(obj);

            } else {
                let obj = {
                    "battery": parseFloat(chartData.battery),
                    "temperature": parseFloat(chartData.temperature),
                    "altitude": parseFloat(chartData.altitude),
                    "speed": parseFloat(chartData.speed),
                    "cellSignal": parseFloat(chartData.cellSignal),
                    "timestamp": new Date(chartData.timestamp)
                }
                chartData.arraySecondaryData.forEach((secondaryData) => {
                    obj.pin = parseFloat(secondaryData.value)

                })
                data.push(obj)
            }
        });
        data.reverse()
        series.data.setAll(data);

        // series.data.setAll(arrayData);
        series.appear(1000);
        chart.appear(1000, 100);
    }

    return (
        <Box sx={{ width: "100%", border: '1px solid #E2E2EA', borderRadius: '3.5px', marginBottom: "21px" }}>
            <Stack direction="row" sx={{ display: "flex", height: "40px", borderBottom: '1px solid #E2E2EA', alignItems: 'center' }} px={'15px'}>
                <Typography fontWeight={500} fontSize={12} width="250px">{arraySensorDataType.length > 0 && arraySensorDataType.find(obj => obj.id == selectedSensor)?.value}</Typography>
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <Box width={'1px'} height={'18px'} bgcolor={"#051219"} />
                    <Typography color={"#A0A3A6"} fontSize={12}>Min.</Typography>
                    <Typography fontWeight={500} fontSize={12}>{`${min} ${symbol}`}</Typography>
                    <Typography color={"#A0A3A6"} fontSize={12}>Avg.</Typography>
                    <Typography fontWeight={500} fontSize={12}>{`${avg} ${symbol}`}</Typography>
                    <Typography color={"#A0A3A6"} fontSize={12}>Max.</Typography>
                    <Typography fontWeight={500} fontSize={12}>{`${max} ${symbol}`}</Typography>
                </Stack>
                <Box flexGrow={1}></Box>
                <Stack direction={'row'} alignItems={'center'}  >
                    <Box
                        sx={{
                            height: '13px',
                            width: '10px',
                            opacity: index === 0 ? 0.5 : 1,
                            cursor: index === 0 ? 'not-allowed' : 'pointer',
                        }}
                        component={'img'}
                        onClick={index > 0 ? () => onUpChartClick(selectedSensor, index) : null}
                        src={ArrowUpIcon}
                    />
                    <Box
                        sx={{
                            height: '13px',
                            width: '10px',
                            ml: '18px',
                            mr: '11px',
                            opacity: (index === (arraySelectedSensor.length - 1)) ? 0.5 : 1,
                            cursor: (index === (arraySelectedSensor.length - 1)) ? 'not-allowed' : 'pointer',
                        }}
                        component={'img'}
                        onClick={(index != (arraySelectedSensor.length - 1)) ? () => ondownChartClick(selectedSensor, index) : null}
                        src={ArrowDownIcon}></Box>
                    <Box
                        sx={{
                            height: '24px',
                            width: '24px',
                            cursor: 'pointer',
                        }}
                        component={'img'}
                        onClick={() => {
                            onCloseChartClick(selectedSensor)
                        }}
                        src={CloseIcon}></Box>
                </Stack>
            </Stack>
            <Box sx={{ height: "260px" }} id={chartId} mx={'15px'}>

            </Box>
        </Box>
    );
};

export const arrayManufacturer = [
    {id: "1",value:"Digital Matter"},
    {id: "2",value:"Particle.io"},
    {id: "3",value:"Myriota"},
    {id: "4",value:"NCD.io"}
]

export const arrayProduct = [
    {id: "1",value:"HAWK"},
    {id: "2",value:"Monitor One"},
    {id: "3",value:"FlexSense"},
    {id: "4",value:"Smart Vibration"}
]
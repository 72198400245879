import { useEffect, useState } from "react";
import { Backdrop, Box, ButtonBase, CircularProgress, Grid, Stack, Tabs, Typography } from '@mui/material';
import { companyTabs } from '../../../../modules/Settings/data/companyTabs.js';
import api from "../../../../lib/axios.js";
import { SettingCompanyManage } from "./SettingCompanyManage.jsx";


export const SettingCompany = () => {
    const [companyTab, setCompanyTab] = useState(companyTabs[0].value);
    const [companyName, setCompanyName] = useState(null);
    const [contactName, setContactName] = useState(null);
    const [contactEmail, setContactEmail] = useState(null);
    const [address1, setAddress1] = useState(null);
    const [address2, setAddress2] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [timeZone, setTimeZone] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [taxId, setTaxId] = useState(null);
    const [netTerms, setNetTerms] = useState(null);
    const [dealer, setDealer] = useState(null);
    const [billingContact, setBillingContact] = useState(null);
    const [billingEmail, setBillingEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [companyDetailsOpen, setCompanyDetailsOpen] = useState(false);
    const [companyObj, setCompanyObj] = useState({});

    const handleChangeCompanyTab = (tab) => {
        setCompanyTab(tab);
    };

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.post('/apis/companies/byID').then((res) => {
            if (res.data.success) {
                var obj = res.data.data
                console.log("got data", obj)
                localStorage.setItem("companyName", obj.companyName)
                if (localStorage.getItem("role") == "SuperAdmin") {
                    localStorage.setItem("fullName", localStorage.getItem("role") == "SuperAdmin" ? obj.contact : obj.accountFirstName + " " + obj.accountLastName)
                }
                setCompanyObj(obj)
                setCompanyName(obj.companyName)
                setContactName(obj.contact)
                setContactEmail(obj.email)
                setAddress1(obj.address1)
                setAddress2(obj.address2)
                setCity(obj.city)
                setState(obj.state)
                setZipCode(obj.zipCode)
                setMobileNumber(obj.telephone)
                setTimeZone(obj.timeZone)
                setPhoneNumber(obj.accountTelephone)
                setTaxId(obj.taxId)
                setNetTerms(obj.netTerms)
                setDealer(obj.dealer)
                setBillingContact(obj.billingContact)
                setBillingEmail(obj.accountEmail)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            {companyDetailsOpen == true && <SettingCompanyManage companyObj={companyObj} onClose={() => setCompanyDetailsOpen(false)} getData={getData} />}
            <Stack
                flex={1}
                width={'100% + 24px'}
                bgcolor={'#F5F7FB'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
                px={3}
                py={2}
            >
            
                <Box>
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            '& > div': {
                                '& > div': {
                                    borderRight: '1px solid #E2E2EA',
                                    pl: 3,
                                    pr: 6,
                                },
                                '&:first-child': {
                                    '& > div': {
                                        pl: 0,
                                    },
                                },
                                '&:last-child': {
                                    '& > div': {
                                        borderRight: 'none',
                                        pr: 0,
                                    },
                                },
                            },
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack gap={3}>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Company
                                    </Typography>
                                    <Typography fontSize={14}>{companyName ? companyName : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Contact Name
                                    </Typography>
                                    <Typography fontSize={14}>{contactName ? contactName : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Contact Email
                                    </Typography>
                                    <Typography fontSize={14}>{contactEmail ? contactEmail : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Address 1
                                    </Typography>
                                    <Typography fontSize={14}>{address1 ? address1 : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Address 2
                                    </Typography>
                                    <Typography fontSize={14}>{address2 ? address2 : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        City
                                    </Typography>
                                    <Typography fontSize={14}>{city ? city : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        State
                                    </Typography>
                                    <Typography fontSize={14}>{state ? state : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Zip Code
                                    </Typography>
                                    <Typography fontSize={14}>{zipCode ? zipCode : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Mobile Number
                                    </Typography>
                                    <Typography fontSize={14}>{mobileNumber ? mobileNumber : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Time Zone
                                    </Typography>
                                    <Typography fontSize={14}>{timeZone ? timeZone : "-"}</Typography>
                                </Stack>
                                {/* <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Phone Number
                                    </Typography>
                                    <Typography fontSize={14}>{phoneNumber ? phoneNumber : "-"}</Typography>
                                </Stack> */}
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack gap={3}>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        TAX ID
                                    </Typography>
                                    <Typography fontSize={14}>{taxId ? taxId : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Net Terms
                                    </Typography>
                                    <Typography fontSize={14}>{netTerms ? netTerms : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Dealer
                                    </Typography>
                                    <Typography fontSize={14}>{dealer ? dealer : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Billing Contact
                                    </Typography>
                                    <Typography fontSize={14}>{billingContact ? billingContact : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Billing Email
                                    </Typography>
                                    <Typography fontSize={14}>{billingEmail ? billingEmail : "-"}</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                    <Typography color={'#AAB0BC'} fontSize={14}>
                                        Phone Number
                                    </Typography>
                                    <Typography fontSize={14}>{phoneNumber ? phoneNumber : "-"}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack direction={'row'} justifyContent="flex-end">
                                {localStorage.getItem("role") === "SuperAdmin" && <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #E2E2EA',
                                        bgcolor: '#FCFCFE',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    onClick={() => {
                                        setCompanyDetailsOpen(true)
                                    }}
                                >
                                    <Typography fontSize={14} fontWeight={700}>
                                        Edit
                                    </Typography>
                                </ButtonBase>}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
export const tabs = [
    {
        title: 'Rule Setup',
        text: 'Basic Information & Devices',
        value: 'setup',
    },
    {
        title: 'Triggers & Conditions',
        text: 'Trigger, Measurement & Conditions',
        value: 'triggersCondition',
    },
    {
        title: 'Rules Actions',
        text: 'Action and Recipients',
        value: 'nodeID',
    },
];

import { ButtonBase, Stack, Typography, Backdrop, CircularProgress, Box, IconButton, Grid, MenuItem } from "@mui/material"
import React, { useState, useEffect, useRef } from 'react';
import api from '../../../lib/axios.js';
import moment from 'moment'
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import { arrayAnaloguePin, arrayDataType, arrayDigitalPin, arrayPinType } from "./Data/configArrayData.jsx";
import { FormikProvider, useFormik } from 'formik';
import { ConfirmAlert } from "../../../customControls/confirmAlert.js";
import { QuestionAnswer, QuestionMark } from "@mui/icons-material";
import { ConfigDataLogicManage } from "./ConfigDataLogicManage.jsx";

export const ConfigDataManage = ({ selectedDataId, nodeID, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [dataType, setDataType] = useState(null);
    const [sampleLogicOutput, setSampleLogicOutput] = useState(null);
    const [logicPopupOpen, setLogicPopupOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            inputName: '',
            selectedPinType: null,
            selectedPinNumber: null,
            value: '',
            converstionLogic: '',
            selectedDataType: null,
            decimalPlace: null,
            testValue: '',
            output: '',
        },
        validationSchema: Yup.object().shape({
            inputName: Yup.string().max(255).required('Input name is required'),
            selectedPinType: Yup.object().required('Pin type is required'),
            selectedPinNumber: Yup.object().required('Pin number is required'),
            selectedDataType: Yup.object().required('Data Type is required'),
            decimalPlace: dataType != null && dataType.id == '1' ? Yup.string().max(255).required('Decimal place is required') : "",
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {

                var dataValues = {
                    inputName: values.inputName,
                    selectedPinType: values.selectedPinType,
                    selectedPinNumber: values.selectedPinNumber,
                    value: values.value,
                    converstionLogic: values.converstionLogic,
                    selectedDataType: values.selectedDataType,
                    decimalPlace: values.decimalPlace,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apicalling(values);
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    console.log("formik", formik.errors);

                    enqueueSnackbar('Secondary datas ' + (selectedDataId == "" ? "added" : "updated"), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    onClose(true)
                } else {
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        if (formik.values.converstionLogic != "") {
            if (formik.values.testValue != null && formik.values.testValue !== "") {
                formik.setFieldValue("output", handleConverstionLogic(formik.values.testValue))
            } else {
                formik.setFieldValue("output", "")
            }
        }
    }, [formik.values.testValue]);
    useEffect(() => {
        formik.setFieldValue("value", "100")
        if (formik.values.converstionLogic != "") {
            setSampleLogicOutput(handleConverstionLogic(100))
            if (formik.values.testValue != null && formik.values.testValue !== "") {
                formik.setFieldValue("output", handleConverstionLogic(formik.values.testValue))
            } else {
                formik.setFieldValue("output", "")
            }
        }
    }, [formik.values.converstionLogic]);
    useEffect(() => {
        if (selectedDataId !== "" && selectedDataId !== "0") {
            getData();
        } else {
            setIsLoading(false);
        }
    }, [selectedDataId]);

    function getData() {
        setIsLoading(true)
        api.get('/apis/SecondaryDatas/getById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                console.log("got data", data)
                let selectedPin = null
                var selectedPinType = arrayPinType.find((pinType) => pinType.id == data.pinType)
                var selectedDataType = arrayDataType.find((dataType) => dataType.id == data.dataType)
                console.log("SelectedPinType", selectedPinType);

                if (selectedPinType != null) {                    
                    if (selectedPinType.id == "1") {
                        selectedPin = arrayAnaloguePin.filter(pin => pin.pin == data.pin)
                        if (selectedPin.length > 0) {
                            selectedPin = selectedPin[0]
                        } else {
                            selectedPin = null
                        }
                    } else {
                        selectedPin = arrayDigitalPin.filter(pin => pin.pin == data.pin)
                        if (selectedPin.length > 0) {
                            selectedPin = selectedPin[0]
                        } else {
                            selectedPin = null
                        }
                    }
                }

                formik.setFieldValue("output", "")
                var dataValues = {
                    submit: null,
                    inputName: data.name,
                    selectedPinType: selectedPinType,
                    selectedPinNumber: selectedPin,
                    converstionLogic: data.logic,
                    selectedDataType: selectedDataType,
                    decimalPlace: data.decimalPlaces,
                }
                formik.setValues(dataValues)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apicalling(values) {
        var data = {
            name: values.inputName,
            pinType: values.selectedPinType.id,
            pin: values.selectedPinNumber.pin,
            logic: values.converstionLogic,
            dataType: values.selectedDataType.id,
            decimalPlaces: values.decimalPlace,
            nodeID: nodeID
        }
        console.log("dataValues", data);
        if (selectedDataId != "") {
            console.log("IF");
            return await api.put('/apis/SecondaryDatas/updateById/' + selectedDataId, data)
        } else {
            console.log("ELSE");
            return await api.post('/apis/SecondaryDatas/add', data)
        }
    }

    const handleConverstionLogic = (testValue) => {
        if (testValue != "") {
            var rawValue = testValue
            var logic = formik.values.converstionLogic;
            // If 'x' is not present in the logic, append ' * rawValue'
            if (!logic.includes('x')) {
                logic = "x" + logic
            }
            // Replace 'x' with the actual rawValue in the logic string
            logic = logic.replace(/x/g, rawValue);


            console.log("replacedLogic", logic, rawValue);

            // Evaluate the replaced logic
            try {
                const value = eval(logic).toFixed(2);
                return value;
            } catch (error) {
                console.error('Error evaluating logic:', error);
                return 'Please correct conversion logic';
            }
        }

    }


    function deleteSecondaryData() {
        setIsLoading(true)
        api.delete('/apis/SecondaryDatas/deleteById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Secondary data deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                onClose(true)
            } else {
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteSecondaryData()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    const handleQuestionMarkClick = () => {
        setLogicPopupOpen(true)
    }
    return (
        <FormikProvider value={formik}>
            <Stack
                width="100%"
                height="calc(100vh - 280px)"
                mb={1}
                mt={2}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 'none',
                    bgcolor: '#FFFFFF',
                    overflow: 'auto',
                }}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Stack
                    height="40px"
                    bgcolor="#F6F8FA"
                    justifyContent="center"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                    <Typography
                        fontFamily="Roboto"
                        fontWeight="Bold"
                        fontSize={13}
                        color="#615D5D"
                        ml={1.5}
                        mt={2}
                        mb={2}
                    >
                        Input Setup
                    </Typography>
                </Stack>
                <Stack gap={3} maxWidth={750} ml={3.5} mt={2}>
                    <Box>
                        <Grid spacing={3} container>
                            <Grid xs={6} item >
                                <DefaultInput
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            pr: 6,
                                        },
                                    }}
                                    label={'Input Name'}
                                    value={formik.values.inputName}
                                    name={'inputName'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.inputName && formik.errors.inputName)}
                                    helperText={formik.touched.inputName && formik.errors.inputName}
                                />
                            </Grid>
                            <Grid xs={6} item>

                                <DefaultSelect
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Pin Type'}
                                    value={formik.values.selectedPinType}
                                    onItemSelected={(e) => {
                                        formik.setFieldValue('selectedPinType', e.target.value)
                                    }}
                                    helperText={formik.touched.selectedPinType && formik.errors.selectedPinType}
                                    error={Boolean(formik.touched.selectedPinType && formik.errors.selectedPinType)}
                                    placeholder="Select pin type"
                                    displayKey="name"
                                >
                                    {arrayPinType.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                    ))}
                                </DefaultSelect>

                            </Grid>
                            <Grid xs={6} item>
                                <DefaultSelect
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Pin Number'}
                                    value={formik.values.selectedPinNumber}
                                    onItemSelected={(e) => formik.setFieldValue('selectedPinNumber', e.target.value)}
                                    helperText={formik.touched.selectedPinNumber && formik.errors.selectedPinNumber}
                                    error={Boolean(formik.touched.selectedPinNumber && formik.errors.selectedPinNumber)}
                                    placeholder="Select pin number"
                                    displayKey="pin"
                                >
                                    {formik.values.selectedPinType != null && formik.values.selectedPinType.id == "1" ?
                                        arrayAnaloguePin.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.pin}</MenuItem>
                                        ))
                                        : arrayDigitalPin.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.pin}</MenuItem>
                                        ))}
                                </DefaultSelect>
                            </Grid>
                            <Grid xs={6} item>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 6,
                                            },
                                        }}
                                        label={'Value(x)'}
                                        disabled={true}
                                        name={'value'}
                                        value={'100'}
                                    // onBlur={formik.handleBlur}
                                    // onChange={formik.handleChange}
                                    // error={Boolean(formik.touched.ruleName && formik.errors.ruleName)}
                                    // helperText={formik.touched.ruleName && formik.errors.ruleName}
                                    />
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                            },
                                        }}
                                        label={
                                            <span>Conversion Logic
                                                <ButtonBase onClick={handleQuestionMarkClick}>
                                                    <QuestionMark fontSize="small" sx={{ height: 12, width: 12, ml: 2 }} />
                                                </ButtonBase>
                                            </span>} // Add the icon here
                                        placeholder={'(x * 9 / 5 ) + 32'}
                                        value={formik.values.converstionLogic}
                                        name={'converstionLogic'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    // ... other props
                                    />
                                    <Typography fontSize={14} pt={2}>
                                        =
                                    </Typography>
                                    <Typography fontSize={14} pt={2}>
                                        {sampleLogicOutput}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={6} item>
                                <DefaultSelect
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Data Type'}
                                    value={formik.values.selectedDataType}
                                    onItemSelected={(e) => {
                                        formik.setFieldValue('selectedDataType', e.target.value),
                                            setDataType(e.target.value)
                                    }}
                                    helperText={formik.touched.selectedDataType && formik.errors.selectedDataType}
                                    error={Boolean(formik.touched.selectedDataType && formik.errors.selectedDataType)}
                                    placeholder="Select Data Type"
                                    displayKey="name"
                                >
                                    {arrayDataType.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                    ))}
                                </DefaultSelect>
                            </Grid>
                            <Grid xs={6} item>
                                {formik.values.selectedDataType != null && formik.values.selectedDataType.id == "1" && <DefaultInput
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            pr: 6,
                                            bgcolor: '#F6F8FA'
                                        },
                                    }}
                                    label={'Decimal Places'}
                                    value={formik.values.decimalPlace}
                                    name={'decimalPlace'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.decimalPlace && formik.errors.decimalPlace)}
                                    helperText={formik.touched.decimalPlace && formik.errors.decimalPlace}
                                />}
                            </Grid>
                            <Grid xs={6} item>
                                <Box sx={{
                                    mt: -1.5,
                                    height: '40px',
                                    bgcolor: '#F6F8FA',
                                    borderRadius: '8px 8px 8px 8px',
                                    border: '1px solid #E2E2EA'
                                }}>
                                    <Stack justifyContent="center" height={'100%'} ml={2}>
                                        {formik.values.selectedDataType != null && formik.values.selectedDataType.id == "1" ? <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#151D26'}>Sample: 20.5</Typography>
                                            : formik.values.selectedDataType != null && formik.values.selectedDataType.id == "2" ? <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#151D26'}>Sample: 20</Typography>
                                                : formik.values.selectedDataType != null && formik.values.selectedDataType.id == "3" ? <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#151D26'}>Sample: Hello World</Typography> : "-"}
                                    </Stack>
                                </Box>

                            </Grid>
                            <Grid xs={6} item>
                                {formik.values.selectedDataType != null && formik.values.selectedDataType.id == "1" && <Box sx={{
                                    mt: -1.5,
                                    height: '40px',
                                    bgcolor: '#F6F8FA',
                                    borderRadius: '8px 8px 8px 8px',
                                    border: '1px solid #E2E2EA'
                                }}>
                                    <Stack justifyContent="center" height={'100%'} ml={2}>
                                        {formik.values.decimalPlace ? `20.${'5'.repeat(formik.values.decimalPlace)}` : "-"}
                                    </Stack>
                                </Box>}
                            </Grid>
                            <Grid xs={10} item>
                                <Box
                                    position={'relative'}
                                    height={'100%'}
                                    display={'flex'}
                                    alignItems={'flex-end'}
                                    pb={0.5}
                                >
                                    <Stack>
                                        <Typography color={'#1F243B'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Medium'}>
                                            Logic Test
                                        </Typography>
                                        <Typography color={'#AAB0BC'} fontSize={12} fontFamily={'Roboto'} fontWeight={'Regular'} mt={1}>
                                            Verify logic by entering a test value below and review the output and decimal place configuration.
                                        </Typography>
                                    </Stack>

                                </Box>
                            </Grid>
                            <Grid xs={6} item>
                                <DefaultInput
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            pr: 6,
                                        },
                                    }}
                                    label={'Test Value'}
                                    value={formik.values.testValue}
                                    name={'testValue'}
                                    type={'number'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.testValue && formik.errors.testValue)}
                                    helperText={formik.touched.testValue && formik.errors.testValue}
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <DefaultInput
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            pr: 6,
                                        },
                                    }}
                                    label={'Output'}
                                    name={'output'}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                <Stack direction={'row'} gap={3} justifyContent={'space-between'} mr={2} mb={2} mt={2} ml={3.5}>
                    <Stack>
                        {selectedDataId != "" && <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '5px',
                            }}
                            onClick={(e) => {
                                setDeleteConfirmationShouldOpen(true)
                            }}
                        >

                            <Typography fontSize={13} fontWeight={'Medium'} color={"#151D26"} fontFamily={'Roboto'}>
                                DELETE DATA
                            </Typography>
                        </ButtonBase>}
                    </Stack>
                    <Stack direction={'row'} gap={2}>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '5px',
                            }}
                            onClick={() => onClose(true)}
                        >
                            <Typography fontSize={13} fontWeight={'Medium'} color={"#151D26"} fontFamily={'Roboto'} >
                                CANCEL
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                bgcolor: '#1E75FF',
                                borderRadius: '5px',
                            }}
                            disabled={formik.isSubmitting}
                            type="submit"
                            onClick={() => console.log('Save button clicked', formik.errors)} // Add this line

                        >
                            <Typography fontSize={13} fontWeight={'Medium'} color={"#FAFAFB"} fontFamily={'Roboto'}>
                                SAVE DATA
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>
            </Stack>
            {console.log("logic", logicPopupOpen)}
            {logicPopupOpen == true && <ConfigDataLogicManage onClose={(e) => {
                setLogicPopupOpen(false)
                if (e) {
                    // getData()
                }
            }}></ConfigDataLogicManage>}
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    )
}
import { Box, ButtonBase, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography, Backdrop, CircularProgress, MenuItem, Switch } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { tabs } from './data/tabs.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import { CheckCircle } from '@mui/icons-material';
import { DefaultToggle } from '../../ui/DefaultToggle/DefaultToggle.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { enqueueSnackbar } from 'notistack';
import { arrayRegisteredState } from './data/RegisteredState.jsx';
import { ConfirmAlert } from '../../customControls/confirmAlert.js';
import { SearchInput } from '../../components/SearchInput/SearchInput.jsx';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { assignedTrackerColumns, trackerColumns } from './data/tracker.jsx';
import { DeviceDetailsModal } from './DeviceDetailsModal.jsx';

export const AssetNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [arrayCategory, setArrayCategory] = useState([]);
    const [arrayType, setArrayType] = useState([]);
    const [arrayAllTrackers, setArrayAllTrackers] = useState([]);
    const [arrayAvailableTrackers, setArrayAvailableTrackers] = useState([]);
    const [arrayselectedTrackers, setArraySelectedTrackers] = useState([]);
    const [arrayGroups, setArrayGroups] = useState([]);
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [selectedTracker, setSelectedTracker] = useState(null);
    const [deviceDetailsModal, setDeviceDetailsModal] = useState(false);
    const formik = useFormik({
        initialValues: {
            assetName: '',
            category: null,
            type: null,
            allGroups: false,
            selectedGroups: null,
            color: null,
            groups: [],
            manufacturer: '',
            model: '',
            vinNumber: '',
            selectedRegisterState: null,
            usdot: '',
            dealer: '',
            length: '',
            weightCapacity: '',
            description: '',
            firstName: '',
            lastName: '',
            department: '',
            title: '',
            email: '',
            cellular: '',
            address1: '',
            address2: '',
            city: '',
            state: null,
            zipCode: '',
            minTemperature: '',
            maxTemperature: '',
            minSpeed: '',
            maxSpeed: '',
            selectedPrimaryTracker: null,
            selectedSecondaryTracker: null,
            billingEnabled: true,
            isEnable: false,
        },
        validationSchema: Yup.object().shape({
            assetName: Yup.string().max(255).required('Asset name is required'),
            category: Yup.object().required('Category is required'),
            type: Yup.object().required('Type is required'),
            email: Yup.string().email('Must be a valid email'),
            //selectedPrimaryTracker: Yup.object().required('Primary tracker is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                if (values.selectedPrimaryTracker == null) {
                    enqueueSnackbar('Primary tracker is required', { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                } else if (values.selectedPrimaryTracker === values.selectedSecondaryTracker) {
                    enqueueSnackbar('Primary tracker and a secondary tracker can not be same', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                } else {
                    var dataValues = {
                        assetName: values.assetName,
                        category: values.category,
                        type: values.type,
                        allGroups: false,
                        selectedGroups: null,
                        color: null,
                        groups: values.groups,
                        manufacturer: values.manufacturer,
                        model: values.model,
                        vinNumber: values.vinNumber,
                        selectedRegisterState: values.selectedRegisterState,
                        usdot: values.usdot,
                        dealer: values.dealer,
                        length: values.length,
                        weightCapacity: values.weightCapacity,
                        description: values.description,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        department: values.department,
                        title: values.title,
                        email: values.email,
                        cellular: values.cellular,
                        address1: values.address1,
                        address2: values.address2,
                        city: values.city,
                        state: values.state,
                        zipCode: values.zipCode,
                        minTemperature: values.selectedPrimaryTracker.minTemperature,
                        maxTemperature: values.selectedPrimaryTracker.maxTemperature,
                        minSpeed: values.selectedPrimaryTracker.minSpeed,
                        maxSpeed: values.selectedPrimaryTracker.maxSpeed,
                        selectedPrimaryTracker: values.selectedPrimaryTracker,
                        selectedSecondaryTracker: values.selectedSecondaryTracker,
                        billingEnabled: true,
                        isEnable: values.isEnable,
                    }
                    formik.setValues(dataValues)
                    setIsLoading(true)
                    var data = await apicalling(values);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        enqueueSnackbar('Asset ' + (selectedDataId == "" ? "added" : "updated"), {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                        navigate(-1)
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                    setSubmitting(false);
                }
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        getAllDetail()
    }, []);

    const getAllDetail = () => {
        setIsLoading(true)
        api.get('/apis/Assets/getDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                //var arrayAvailableTrackers = resData.arrayTracker.filter((tracker) => tracker.asset == null)
                setArrayCategory(resData.arrayCategory)
                setArrayType(resData.arrayType)
                setArrayGroups(resData.arrayGroup)
                setArrayAllTrackers(resData.arrayTracker)
                // setArrayAvailableTrackers(arrayAvailableTrackers)
                if (selectedDataId != "") {
                    getData(resData.arrayCategory, resData.arrayType, resData.arrayTracker)
                } else {
                    setArrayAvailableTrackers(resData.arrayTracker)
                }
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = (arrayCategory, arrayType, arrayTracker) => {
        setIsLoading(true)
        api.get('/apis/Assets/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedCategory = arrayCategory.find((category) => category._id == resData.categoryID)
                var selectedType = arrayType.find((type) => type._id == resData.typeID)
                var selectedRegisteredState = arrayRegisteredState.find(status => status.value == resData.registeredState)
                var selectedState = arrayRegisteredState.find(status => status.value == resData.state)

                var selectedPrimaryTracker = null
                var selectedSecondaryTracker = null
                var arrayAvailableTrackers = arrayTracker
                var arraySelectedTracker = []
                if (resData.tracker != null) {
                    var primaryTraker = { ...resData.tracker }
                    primaryTraker.role = "Primary"
                    selectedPrimaryTracker = primaryTraker
                    arraySelectedTracker.push(primaryTraker);
                    arrayAvailableTrackers = arrayAvailableTrackers.filter((tracker) => tracker._id != resData.tracker._id)
                }
                if (resData.secondaryTracker != null) {
                    var secondaryTraker = { ...resData.secondaryTracker }
                    secondaryTraker.role = "Secondary"
                    selectedSecondaryTracker = secondaryTraker
                    arraySelectedTracker.push(secondaryTraker);
                    arrayAvailableTrackers = arrayAvailableTrackers.filter((tracker) => tracker._id != resData.secondaryTracker._id)
                }
                setArraySelectedTrackers(arraySelectedTracker)
                setArrayAvailableTrackers(arrayAvailableTrackers)
                var values = {
                    assetName: resData.assetName,
                    category: selectedCategory,
                    type: selectedType,
                    allGroups: resData.groups,
                    groups: resData.groups,
                    inMaintenance: true,
                    ready: false,
                    inInspection: false,
                    scheduled: true,
                    damaged: false,
                    manufacturer: resData.manufacturer,
                    model: resData.model,
                    vinNumber: resData.vin,
                    selectedRegisterState: selectedRegisteredState,
                    usdot: resData.usDot,
                    dealer: resData.dealer,
                    length: resData.length,
                    weightCapacity: resData.weightCapacity,
                    description: resData.description,
                    firstName: resData.firstName,
                    lastName: resData.lastName,
                    department: resData.department,
                    title: resData.title,
                    email: resData.email,
                    cellular: resData.mobile,
                    address1: resData.address1,
                    address2: resData.address2,
                    city: resData.city,
                    state: selectedState,
                    zipCode: resData.zipCode,
                    minTemperature: selectedPrimaryTracker ? selectedPrimaryTracker.minTemperature : "",
                    maxTemperature: selectedPrimaryTracker ? selectedPrimaryTracker.maxTemperature : "",
                    minSpeed: selectedPrimaryTracker ? selectedPrimaryTracker.minSpeed : "",
                    maxSpeed: selectedPrimaryTracker ? selectedPrimaryTracker.maxSpeed : "",
                    selectedPrimaryTracker: selectedPrimaryTracker,
                    selectedSecondaryTracker: selectedSecondaryTracker,
                    billingEnabled: true,
                    isEnable: false,
                }
                formik.setValues(values);

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const goToNextTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

        // if (activeTabIndex < tabs.length - 1) {
        //     setActiveTab(tabs[activeTabIndex + 1].value);
        // }
        formik.validateForm().then((errors) => {
            console.log("validation 1", errors);
            formik.setErrors(errors);
            formik.setTouched({ assetName: true, category: true, type: true, selectedPrimaryTracker: true });

            if (activeTab === tabs[0].value) {
                if (errors.assetName || errors.category || errors.type) {
                    console.log("err")
                } else {
                    if (activeTabIndex < tabs.length - 1) {
                        setActiveTab(tabs[activeTabIndex + 1].value);
                    }
                }
            }
            // else if (activeTab === tabs[3].value) {
            //     if (formik.errors.selectedPrimaryTracker) {
            //         console.log("err")
            //     } else {
            //         if (activeTabIndex < tabs.length - 1) {
            //             setActiveTab(tabs[activeTabIndex + 1].value);
            //         }
            //     }
            // }
            else {
                if (activeTabIndex < tabs.length - 1) {
                    setActiveTab(tabs[activeTabIndex + 1].value);
                }
            }
        });
    };

    const goToPrevTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

        if (activeTabIndex > 0) {
            setActiveTab(tabs[activeTabIndex - 1].value);
        }
    };


    async function apicalling(values) {
        var data = {
            assetName: values.assetName,
            //customerID: values.customerID,
            categoryID: values.category._id,
            category: values.category.name,
            type: values.type.name,
            typeID: values.type._id,
            serialNumber: values.selectedPrimaryTracker.deviceSerialNumber,
            serialNumberID: values.selectedPrimaryTracker._id,
            secondarySerialNumber: values.selectedSecondaryTracker != null ? values.selectedSecondaryTracker.deviceSerialNumber : "",
            secondarySerialNumberID: values.selectedSecondaryTracker != null ? values.selectedSecondaryTracker._id : "",
            groups: values.groups,
            //assetSerialNumber: values.assetSerialNumber,
            manufacturer: values.manufacturer,
            model: values.model,
            vin: values.vinNumber,
            registeredState: values.selectedRegisterState != null ? values.selectedRegisterState.value : "",
            usDot: values.usdot,
            dealer: values.dealer,
            length: values.length,
            weightCapacity: values.weightCapacity,
            description: values.description,
            //hazmat: values.hazmat,
            firstName: values.firstName,
            lastName: values.lastName,
            department: values.department,
            title: values.title,
            email: values.email,
            mobile: values.cellular,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state != null ? values.state.value : "",
            zipCode: values.zipCode,
        }
        if (selectedDataId != "") {
            return await api.put('/apis/Assets/' + selectedDataId, data)
        } else {
            return await api.post('/apis/Assets/', data)
        }
    }

    const handleColorChange = (selectedColor) => {
        formik.setFieldValue('groups', selectedColor);
    };

    function deleteAsset() {
        setIsLoading(true)
        api.delete('/apis/Assets/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Asset deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-2)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteAsset()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    async function changeStatus(status, id) {
        setIsLoading(true)
        var data = {
            status: status,
        }
        return await api.put('/apis/Trackers/changestatus/' + id, data).then((res) => {
            if (res.data.success) {
                //getAllDetail()
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayAvailableTrackers, event.target.value)
        setFilteredArrayData(filterArray)
    };

    const applyFilters = (arrayDataAll, query) => {
        if (query.length <= 3) {
            return []
        }
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['deviceSerialNumber'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []
        return finalArray
    }

    function handleAssignedTracker(tracker) {
        if (arrayselectedTrackers.length >= 2) {
            enqueueSnackbar('Only two tracker can be added ', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            var filterDeviceArray = arrayAvailableTrackers.filter(obj => obj._id != tracker._id)
            setArrayAvailableTrackers(filterDeviceArray)
            var filterArray = applyFilters(filterDeviceArray, query)
            setFilteredArrayData(filterArray)

            var objTracker = { ...tracker }
            var arraySelectedDevice = [...arrayselectedTrackers]
            var findObj = arrayselectedTrackers.find(obj => obj.role == "Primary")
            if (findObj) {
                objTracker.role = "Secondary"
            } else {
                objTracker.role = "Primary"
            }
            arraySelectedDevice.push(objTracker)
            setArraySelectedTrackers(arraySelectedDevice);
            var selectedPrimaryTracker = arraySelectedDevice.find(obj => obj.role == "Primary")
            var selectedSecondaryTracker = arraySelectedDevice.find(obj => obj.role == "Secondary")
            formik.setFieldValue('selectedPrimaryTracker', selectedPrimaryTracker);
            formik.setFieldValue('selectedSecondaryTracker', selectedSecondaryTracker);
        }


    }

    function handleUnassignedTracker(tracker) {
        if (arrayselectedTrackers.length == 1) {
            enqueueSnackbar('Primary tracker is required', { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
        } else {
            var objTracker = { ...tracker }
            objTracker.role = null
            var arrayDevice = arrayAvailableTrackers
            arrayDevice.push(objTracker)
            setArrayAvailableTrackers(arrayDevice);
            var filterArray = applyFilters(arrayDevice, query)
            setFilteredArrayData(filterArray)

            var arraySelectedDevice = arrayselectedTrackers.filter(obj => obj._id != tracker._id)
            if (arraySelectedDevice.length == 1) {
                arraySelectedDevice[0].role = "Primary"
            }
            setArraySelectedTrackers(arraySelectedDevice)

            var selectedPrimaryTracker = arraySelectedDevice.find(obj => obj.role == "Primary")
            var selectedSecondaryTracker = arraySelectedDevice.find(obj => obj.role == "Secondary")
            formik.setFieldValue('selectedPrimaryTracker', selectedPrimaryTracker);
            formik.setFieldValue('selectedSecondaryTracker', selectedSecondaryTracker);
        }

    }

    function onTrackerClick(tracker) {
        setSelectedTracker(tracker)
        setDeviceDetailsModal(true)
    }

    function onCloseTracker(tracker) {
        if (arrayselectedTrackers.length == 1 && tracker.role == "Secondary") {
            enqueueSnackbar('Primary tracker is required', { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
        } else {
            var arraySelectedDevice = arrayselectedTrackers.filter(obj => obj._id != tracker._id)
            var findObj = arraySelectedDevice.find(obj => obj.role == tracker.role)
            if (findObj) {
                arraySelectedDevice[0].role = tracker.role == "Primary" ? "Secondary" : "Primary"
            }
            arraySelectedDevice.push(tracker)
            setArraySelectedTrackers(arraySelectedDevice)
            var selectedPrimaryTracker = arraySelectedDevice.find(obj => obj.role == "Primary")
            var selectedSecondaryTracker = arraySelectedDevice.find(obj => obj.role == "Secondary")
            formik.setFieldValue('selectedPrimaryTracker', selectedPrimaryTracker);
            formik.setFieldValue('selectedSecondaryTracker', selectedSecondaryTracker);
        }
    }
    return (
        <FormikProvider value={formik}>
            {deviceDetailsModal && <DeviceDetailsModal
                selectedDataId={selectedTracker._id}
                assetName={formik.values.assetName}
                tracker={selectedTracker}
                onClose={(e, value) => {
                    setSelectedTracker(null)
                    setDeviceDetailsModal(false)
                    if (e) {
                        onCloseTracker(value)
                    }
                }} />}
            <Stack
                flex={1}
                p={3}
                gap={3}
                overflow={'hidden'}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography textTransform={'uppercase'}>ASSET SETUP</Typography>
                    {/* <HeaderMenu /> */}
                </Stack>
                <Typography>Follow these quick steps to setup or edit an asset in the system.</Typography>
                <Stack direction={'row'} overflow={'hidden'} width={'calc(100% + 24px)'}>
                    {tabs.map((tab, idx) => (
                        <Stack
                            direction={'row'}
                            gap={1}
                            key={tab.value}
                            px={2}
                            py={1}
                            border={'1px solid #E2E2EA'}
                            borderRight={idx === tabs.length - 1 ? '1px solid #E2E2EA' : 'none'}
                            width={'25%'}
                            position={'relative'}
                            sx={{
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '0',
                                    left: 0,
                                    width: '100%',
                                    height: 3,
                                    bgcolor: 'secondary.main',
                                    display: activeTab === tab.value ? 'block' : 'none',
                                },
                            }}
                        >
                            <Typography>{idx + 1}.</Typography>
                            <Stack gap={0.5} overflow={'hidden'} flex={1}>
                                <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography>{tab.title}</Typography>
                                    {tabs.findIndex((tab) => tab.value === activeTab) !== idx &&
                                        tabs.findIndex((tab) => tab.value === activeTab) > idx && (
                                            <CheckCircle
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    color: 'green.main',
                                                }}
                                            />
                                        )}
                                </Stack>
                                <Typography
                                    fontSize={12}
                                    color={'#AAB0BC'}
                                    overflow={'hidden'}
                                    whiteSpace={'nowrap'}
                                    textOverflow={'ellipsis'}
                                >
                                    {tab.text}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                    <Stack
                        py={1}
                        px={5}
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderTop={'1px solid #E2E2EA'}
                        borderBottom={'1px solid #E2E2EA'}
                    >
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#FCFCFE',
                                borderRadius: '5px',
                                height:'30px'
                            }}
                            type='submit'
                            disabled={formik.isSubmitting}
                        >
                            <Typography color={'secondary.main'} fontSize={14} fontWeight={700} whiteSpace={'nowrap'}>
                                Save & Close
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'flex-start'}>
                    {activeTab === tabs[0].value && (
                        <Stack gap={1}>
                            <Typography>Basic Setup Details</Typography>
                            <Typography fontSize={14} color={'#AAB0BC'}>
                                Enter the basic details of the asset including it’s desired name, category and type.
                            </Typography>
                        </Stack>
                    )}
                    {activeTab === tabs[1].value && (
                        <Stack gap={1}>
                            <Typography>Asset Details</Typography>
                            <Typography fontSize={14} color={'#AAB0BC'}>
                                Enter the applicable asset details and description.
                            </Typography>
                        </Stack>
                    )}
                    {activeTab === tabs[2].value && (
                        <Stack
                            direction={'row'}
                            gap={1}
                            alignItems={'flex-end'}
                            justifyContent={'space-between'}
                            maxWidth={650}
                            width={'100%'}
                        >
                            <Stack gap={1}>
                                <Typography>Contact Details</Typography>
                                <Typography fontSize={14} color={'#AAB0BC'}>
                                    Enter the applicable contact details for this asset.
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'} mb={-1}>
                                <Typography fontSize={14}>Contact Details</Typography>
                                <DefaultToggle name={'isEnable'} />
                            </Stack>
                        </Stack>
                    )}
                    {activeTab === tabs[3].value && (
                        <Stack gap={1}>
                            <Typography>Device Details & Settings</Typography>
                            <Typography fontSize={14} color={'#AAB0BC'}>
                                Enter the basic details of the asset including it’s desired name, category and type.
                            </Typography>
                        </Stack>
                    )}
                    <Stack direction={'row'} gap={1}>
                        {selectedDataId != "" &&
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '5px',
                                    bgcolor: '#E2E2EA',
                                    mt: 'auto',
                                    alignSelf: 'flex-end',
                                    height:'30px'
                                }}
                                onClick={(e) => {
                                    setDeleteConfirmationShouldOpen(true)
                                }}
                            >
                                <Typography fontSize={14}>Remove</Typography>
                            </ButtonBase>
                        }
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#E2E2EA',
                                borderRadius: '5px',
                                height:'30px'
                            }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <Typography fontSize={14}>Cancel</Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#f0f0f3',
                                height:'30px'
                            }}
                            disabled={tabs.findIndex((tab) => tab.value === activeTab) === 0}
                            onClick={goToPrevTab}
                        >
                            <Typography fontSize={14} color={tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#9a9aa4'}>
                                Back
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#f0f0f3',
                                height:'30px'
                            }}
                            onClick={goToNextTab}
                            disabled={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1}
                        >
                            <Typography
                                fontSize={14}
                                color={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#9a9aa4'}
                            >
                                Next
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>
                {tabs[0].value === activeTab && (
                    <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                        <Stack gap={3} maxWidth={650}>
                            <Box>
                                <Grid spacing={3} container>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultInput
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        pr: 6,
                                                    },
                                                }}
                                                label={'Asset Name'}
                                                value={formik.values.assetName}
                                                name={'assetName'}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.touched.assetName && formik.errors.assetName)}
                                                helperText={formik.touched.assetName && formik.errors.assetName}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        {/* <Box
                                            position={'relative'}
                                            height={'100%'}
                                            display={'flex'}
                                            alignItems={'flex-end'}
                                            pb={0.5}
                                        >
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Asset Name is Available
                                            </Typography>
                                        </Box> */}
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'Category'}
                                                value={formik.values.category}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('category', e.target.value)
                                                    formik.setFieldValue('type', null)
                                                }}
                                                helperText={formik.touched.category && formik.errors.category}
                                                error={Boolean(formik.touched.category && formik.errors.category)}
                                                // placeholder="Select category"
                                                displayKey="name"
                                            >
                                                {arrayCategory.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'Type'}
                                                value={formik.values.type}
                                                onItemSelected={(e) => formik.setFieldValue('type', e.target.value)}
                                                helperText={formik.touched.type && formik.errors.type}
                                                error={Boolean(formik.touched.type && formik.errors.type)}
                                                // placeholder="Select type"
                                                displayKey="name"
                                            >
                                                {formik.values.category != null && arrayType.filter(obj => obj.categoryID == formik.values.category._id).map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} bgcolor={'#E2E2EA'} />
                            <Stack gap={1}>
                                <Typography>Groups</Typography>
                                <Typography fontSize={12} color={'#AAB0BC'}>
                                    Assign to one or more groups to help manage your assets. To Edit Groups to go Settings &gt;
                                    Groups.
                                </Typography>
                            </Stack>
                            <Stack gap={1}>
                                <Typography fontSize={14}>Configured Groups</Typography>
                                <FormGroup
                                    sx={{
                                        '.MuiFormControlLabel-label': {
                                            color: '#696974',
                                            fontSize: 14,
                                        },
                                        '.MuiCheckbox-root': {
                                            color: '#B5B5BE',

                                            '&.Mui-checked': {
                                                color: '#44444F',
                                            },
                                        },
                                    }}
                                >
                                    <Stack px={2} ml={-2}>
                                        {arrayGroups.length > 0 &&
                                            <Stack
                                                direction={'row'}
                                                gap={3}
                                                maxWidth={300}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <FormControlLabel
                                                    name={'allGroups'}
                                                    control={
                                                        <Checkbox
                                                            checked={formik.values.groups != null ? arrayGroups.length == formik.values.groups.length : false}
                                                            onChange={(e) => {
                                                                var groups = formik.values.groups ? formik.values.groups : []
                                                                if (arrayGroups.length == groups.length) {
                                                                    groups = []
                                                                } else {
                                                                    groups = arrayGroups.map(data => data._id)
                                                                }
                                                                handleColorChange(groups)
                                                            }}
                                                        />
                                                    }
                                                    label='All Groups'
                                                />
                                            </Stack>
                                        }
                                        {arrayGroups.map((group, index) => (
                                            <Stack
                                                key={index}
                                                direction={'row'}
                                                gap={3}
                                                maxWidth={300}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <FormControlLabel
                                                    key={index}
                                                    value={group.name}
                                                    control={
                                                        <Checkbox
                                                            checked={formik.values.groups != null ? formik.values.groups.includes(group._id) : false}
                                                            onChange={(e) => {
                                                                var groups = formik.values.groups ? formik.values.groups : []
                                                                if (groups.includes(group._id)) {
                                                                    groups = groups.filter(obj => obj != group._id)
                                                                } else {
                                                                    groups.push(group._id)
                                                                }
                                                                handleColorChange(groups)
                                                            }}
                                                        />
                                                    }
                                                    label={group.name}
                                                />
                                                <Box width={45} height={23} bgcolor={group.color} borderRadius={'5px'} />
                                            </Stack>
                                        ))}
                                    </Stack>
                                </FormGroup>
                            </Stack>
                        </Stack>
                    </Box>
                )}
                {tabs[1].value === activeTab && (
                    <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                        <Stack gap={3} maxWidth={650}>
                            <Box>
                                <Grid spacing={3} container>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Manufacturer'}
                                            value={formik.values.manufacturer}
                                            name={'manufacturer'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.manufacturer && formik.errors.manufacturer)}
                                            helperText={formik.touched.manufacturer && formik.errors.manufacturer}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Model'}
                                            value={formik.values.model}
                                            name={'model'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.model && formik.errors.model)}
                                            helperText={formik.touched.model && formik.errors.model}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'VIN Number'}
                                            value={formik.values.vinNumber}
                                            name={'vinNumber'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.vinNumber && formik.errors.vinNumber)}
                                            helperText={formik.touched.vinNumber && formik.errors.vinNumber}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'Registered State'}
                                                value={formik.values.selectedRegisterState}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('selectedRegisterState', e.target.value)
                                                }}
                                                helperText={formik.touched.registeredState && formik.errors.registeredState}
                                                error={Boolean(formik.touched.registeredState && formik.errors.registeredState)}
                                            // placeholder="Select registered state"
                                            >
                                                {arrayRegisteredState.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'USDOT#'}
                                            value={formik.values.usdot}
                                            name={'usdot'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.usdot && formik.errors.usdot)}
                                            helperText={formik.touched.usdot && formik.errors.usdot}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Dealer'}
                                            value={formik.values.dealer}
                                            name={'dealer'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.dealer && formik.errors.dealer)}
                                            helperText={formik.touched.dealer && formik.errors.dealer}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Length'}
                                            value={formik.values.length}
                                            name={'length'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.length && formik.errors.length)}
                                            helperText={formik.touched.length && formik.errors.length}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Weight Capacity'}
                                            value={formik.values.weightCapacity}
                                            name={'weightCapacity'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.weightCapacity && formik.errors.weightCapacity)}
                                            helperText={formik.touched.weightCapacity && formik.errors.weightCapacity}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Description'}
                                            value={formik.values.description}
                                            name={'description'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.description && formik.errors.description)}
                                            helperText={formik.touched.description && formik.errors.description}
                                            isTextarea

                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                )}
                {tabs[2].value === activeTab && (
                    <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                        <Stack gap={3} maxWidth={650}>
                            <Box>
                                <Grid spacing={3} container>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'First Name'}
                                            value={formik.values.firstName}
                                            name={'firstName'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Last Name'}
                                            value={formik.values.lastName}
                                            name={'lastName'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Department'}
                                            value={formik.values.department}
                                            name={'department'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.department && formik.errors.department)}
                                            helperText={formik.touched.department && formik.errors.department}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Title'}
                                            value={formik.values.title}
                                            name={'title'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.title && formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Email'}
                                            value={formik.values.email}
                                            name={'email'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.email && formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Cellular'}
                                            value={formik.values.cellular}
                                            name={'cellular'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.cellular && formik.errors.cellular)}
                                            helperText={formik.touched.cellular && formik.errors.cellular}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Address 1'}
                                            value={formik.values.address1}
                                            name={'address1'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.address1 && formik.errors.address1)}
                                            helperText={formik.touched.address1 && formik.errors.address1}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Address 2'}
                                            value={formik.values.address2}
                                            name={'address2'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.address2 && formik.errors.address2)}
                                            helperText={formik.touched.address2 && formik.errors.address2}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'City'}
                                            value={formik.values.city}
                                            name={'city'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.city && formik.errors.city)}
                                            helperText={formik.touched.city && formik.errors.city}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'State'}
                                                value={formik.values.state}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('state', e.target.value)
                                                }}
                                                helperText={formik.touched.state && formik.errors.state}
                                                error={Boolean(formik.touched.state && formik.errors.state)}
                                                disabled={!formik.values.isEnable}
                                            // placeholder="Select state"
                                            >
                                                {arrayRegisteredState.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                ))}

                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Zip Code'}
                                            value={formik.values.zipCode}
                                            name={'zipCode'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
                                            helperText={formik.touched.zipCode && formik.errors.zipCode}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                )}
                {tabs[3].value === activeTab && (
                    <>
                        <Stack spacing={2} flex={1}>
                            <Stack
                                px={3}
                                py={2}
                                width={'100%'}
                                sx={{
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '8px',
                                    borderRightWidth: '1px',
                                }}
                                overflow={'hidden'}
                                position={'relative'}

                            >
                                <Stack direction={'row'} ml={-1.5}>
                                    <SearchInput name={'search'} handleQueryChange={handleQueryChange} />
                                </Stack>
                                <Stack direction={'row'} ml={-1.5} mt={2}>
                                    <DefaultDataGrid columns={trackerColumns(handleAssignedTracker)} rows={filteredArrayData} />
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography fontWeight={700} fontSize={14}>Assigned Devices</Typography>
                            </Stack>
                            <Stack
                                px={3}
                                py={2}
                                width={'100%'}
                                sx={{
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '8px',
                                    borderRightWidth: '1px',
                                }}
                                overflow={'hidden'}
                                position={'relative'}
                                mb={3}
                            >
                                <Stack direction={'row'} ml={-1.5}>
                                    <DefaultDataGrid columns={assignedTrackerColumns(handleUnassignedTracker, formik.values.assetName)} rows={arrayselectedTrackers} onRowClick={(e) => { onTrackerClick(e.row) }} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    );
};

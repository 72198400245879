import { Box, Grid, Stack, Typography, MenuItem, Backdrop, CircularProgress, Switch, ButtonBase } from '@mui/material';
import React, { useState, useEffect, createRef } from 'react';
import { AccessTimeFilled, CheckCircle } from '@mui/icons-material';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import moment from 'moment'
import { DefaultSelectForm } from '../../../ui/DefaultSelect/DefaultSelectForm.jsx';
import api from '../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { ColorStatus } from '../../../ui/ColorStatus/ColorStatus.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

export const Device = ({ assetData, trackerData, updateData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTracker, setSelectedTracker] = useState(assetData.tracker)
    const [arrayTracker, setArrayTracker] = useState([])
    const [status, setStatus] = useState("")
    const [selectedSettingDataId, setSelectedSettingDataId] = useState("");
    const formik = useFormik({
        initialValues: {
            submit: null,
            minTemperature: '',
            maxTemperature: '',
            minSpeed: '',
            maxSpeed: '',
        },
        validationSchema: Yup.object().shape({
            minTemperature: Yup.string().required('Min temperature is required'),
            maxTemperature: Yup.string().required('Max temperature is required'),
            minSpeed: Yup.string().required('Min Speed is required'),
            maxSpeed: Yup.string().required('Max Speed is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    minTemperature: values.minTemperature,
                    maxTemperature: values.maxTemperature,
                    minSpeed: values.minSpeed,
                    maxSpeed: values.maxSpeed,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apiCallingMinMax(values.minSpeed, values.maxSpeed, values.minTemperature, values.maxTemperature);
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    getAssetData()
                } else {
                    setStatus({ success: false });
                    setErrors({ submit: data.data.message });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })

    useEffect(() => {
        getData()
        var arrayTracker = [];

        var dataValues = {
            submit: null,
            minTemperature: trackerData && trackerData.minTemperature || "",
            maxTemperature: trackerData && trackerData.maxTemperature || "",
            minSpeed: trackerData && trackerData.minSpeed || "",
            maxSpeed: trackerData && trackerData.maxSpeed || ""
        }
        formik.setValues(dataValues)

        if (assetData.tracker != null) {
            arrayTracker.push(assetData.tracker);
        }
        if (assetData.secondaryTracker != null) {
            arrayTracker.push(assetData.secondaryTracker);
        }
        setArrayTracker(arrayTracker)
    }, []);

    async function getData() {
        setIsLoading(true)
        return await api.get('/apis/NotificationSettings/byAsset/' + assetData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                // setEmail(resData.email)
                // setMobile(resData.mobile)
                setStatus(resData.status)
                //setAssetStatus(resData.assetStatus)
                setSelectedSettingDataId(resData._id)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(status) {
        var data = {
            assetID: assetData._id,
            email: "",
            mobile: "",
            status: status,
            assetStatus: status,
        }
        if (selectedSettingDataId == "") {
            return await api.post('/apis/NotificationSettings/', data).then((res) => {
                if (res.data.success) {
                    var resData = res.data.data
                    setSelectedSettingDataId(resData._id)
                    if (resData.assetStatus === "1") {
                        enqueueSnackbar("Asset notifications activated", {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                    } else {
                        enqueueSnackbar("Asset notifications deactivated", {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                    }

                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            return await api.put('/apis/NotificationSettings/' + selectedSettingDataId, data).then((res) => {
                if (res.data.success) {
                    var resData = res.data.data
                    if (resData.assetStatus === "1") {
                        enqueueSnackbar("Asset notifications activated", {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                    } else {
                        enqueueSnackbar("Asset notifications deactivated", {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                    }

                }
            }).catch(function (error) {
                console.log(error);
            });
        }

    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "0 mins";
        }
        return durationString.trim();
    }

    const getAssetData = () => {
        setIsLoading(true)
        api.get('/apis/Assets/byId/' + assetData._id).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                var trackerData = data.tracker
                updateData(data)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function changeStatus(status, id) {
        setIsLoading(true)
        var data = {
            status: status,
        }
        return await api.put('/apis/Trackers/changestatus/' + id, data).then((res) => {
            if (res.data.success) {
                console.log("data", res.data)

                getAssetData()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCallingMinMax(minSpeed, maxSpeed, minTemperature, maxTemperature) {
        var data = {
            minSpeed: minSpeed,
            maxSpeed: maxSpeed,
            minTemperature: minTemperature,
            maxTemperature: maxTemperature,
        }
        if (trackerData != null && trackerData._id) {
            return await api.put('/apis/Trackers/changeRanage/' + trackerData._id, data)
        }
    }

    return (
        <>
            {/* <Stack mb={2} direction={'row'} gap={6}>
                <Stack gap={1}>
                    <Typography>{trackerData.positionSpeed || "-"}</Typography>
                    <Typography fontSize={13} color={'#A0A3A6'}>
                        Speed
                    </Typography>
                </Stack>
                <Stack gap={1}>
                    <Typography fontWeight={500}>{trackerData.temperature != null && trackerData.temperature != "" && trackerData.temperature != "-" ? (trackerData.temperature + " °F") : "-"}</Typography>
                    <Typography fontSize={13} color={'#A0A3A6'}>
                        Temp
                    </Typography>
                </Stack>
                <Stack gap={1}>
                    <Stack direction={'row'} gap={2}>
                        <Typography>{assetData.duration || "-"}</Typography>
                        <AccessTimeFilled />
                    </Stack>
                    <Typography fontSize={13} color={'#A0A3A6'}>
                        Trip Duration
                    </Typography>
                </Stack>
                <Stack gap={1}>
                    <Typography>{trackerData.positionSpeed > 0 ? "Moving" : "In Transit"}</Typography>
                    <Typography fontSize={13} color={'#A0A3A6'}>
                        Movement
                    </Typography>
                </Stack>
                <Stack gap={1}>
                    <Typography>{trackerData.address || "-"}</Typography>
                    <Typography fontSize={13} color={'#A0A3A6'}>
                        Last Updated: {trackerData.positionLatitude != null && trackerData.positionLatitude != "" && trackerData.positionLatitude != "-" && trackerData.timestamp != null && trackerData.timestamp !== "" ? moment(trackerData.timestamp).format("MM-DD-YY hh:mma") : "-"}
                    </Typography>
                </Stack>
            </Stack> */}
            <FormikProvider value={formik}>
                <Stack
                    flex={1}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 0 0 0',
                        borderRight: 'none',
                        borderBottom: 'none',
                    }}
                    mb={-3}
                    width={'calc(100% + 24px)'}
                    overflow={'hidden'}
                    bgcolor={'#F5F7FB'}
                    p={3}
                    position={'relative'}
                    component={'form'}
                    onSubmit={formik.handleSubmit}
                >
                    <Box maxWidth={200} mb={4}>
                        <DefaultSelectForm
                            onChange={(event, newValue) => {
                                setSelectedTracker(newValue)
                            }}
                            defaultValue={selectedTracker}
                            placeholder="device"
                            options={arrayTracker}
                            getOptionLabel={(option) => option.deviceSerialNumber + (option.deviceSerialNumber == assetData.serialNumber ? " (primary)" : " (secondary)")}
                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                        />
                        {/* <DefaultSelect defaultValue={'808654 (primary)'} name={'device'} /> */}
                    </Box>
                    <Box>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                '& > div': {
                                    '& > div': {
                                        borderRight: '1px solid #E2E2EA',
                                        pl: 3,
                                        pr: 6,
                                    },
                                    '&:first-child': {
                                        '& > div': {
                                            pl: 0,
                                        },
                                    },
                                    '&:last-child': {
                                        '& > div': {
                                            borderRight: 'none',
                                            pr: 0,
                                        },
                                    },
                                },
                            }}
                        >

                            <Grid item xs={5}>
                                <Stack height={'100%'}>
                                    <Stack direction={'row'} gap={3} justifyContent={'space-between'} mb={4}>
                                        <Typography fontSize={14}>BILLING ACTIVATION</Typography>
                                        {selectedTracker && selectedTracker.deviceSerialNumber &&
                                            <Stack direction={'row'} gap={2} alignItems={'center'}>
                                                <Switch
                                                    sx={{
                                                        ml: 0,
                                                        '.MuiSwitch-thumb': {
                                                            backgroundColor: 'green.main',
                                                        },
                                                        '.Mui-checked+.MuiSwitch-track': {
                                                            backgroundColor: 'grey.light',
                                                        },
                                                    }}
                                                    checked={selectedTracker.status === "1"}
                                                    onChange={(event) => {
                                                        const checked = event.target.checked;
                                                        selectedTracker.status = checked ? "1" : "2"
                                                        changeStatus(checked ? "1" : "2", selectedTracker._id)
                                                    }}
                                                />
                                                <Typography
                                                    fontWeight={700}
                                                    sx={{
                                                        fontSize: '15px',
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    {selectedTracker.status == "1" ? "Active billing" : "Inactive billing"}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                    <Typography fontSize={14} mb={8}>
                                        Manage device billing status. when the device is disabled location and sensor data will not
                                        update.
                                    </Typography>
                                    <Stack mt={'auto'} gap={3}>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Identifier
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.ident || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Activated
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.lastOnlineDate ? moment(selectedTracker.lastOnlineDate).format("MM/DD/YY hh:mma") : "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Days Active
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker ? (selectedTracker.lastOnlineDate ? calculateDuration(selectedTracker.lastOnlineDate, Date()) : '0 Days 0 hrs 0 mins') : "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Latitude
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.positionLatitude || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Longitude
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.positionLongitude || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Altitude(m)
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.positionAltitude || "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                    {/* <ButtonBase
                                        sx={{
                                            py: 1,
                                            px: 3,
                                            border: '1px solid #E2E2EA',
                                            borderRadius: '5px',
                                            bgcolor: '#E2E2EA',
                                            mt: 'auto',
                                            alignSelf: 'flex-end',
                                        }}
                                        disabled={formik.isSubmitting}
                                        type="submit"
                                    >
                                        <Typography fontSize={14}>Save</Typography>
                                    </ButtonBase> */}
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack height={'100%'}>
                                    {/* <Stack direction={'row'} gap={3} justifyContent={'space-between'} mb={4}>
                                        <Typography fontSize={14}>THRESHOLDS</Typography>
                                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                                            <Switch
                                                sx={{
                                                    '.MuiSwitch-thumb': {
                                                        backgroundColor: status === "1" ? 'green.main' : '#F8F8FA',
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                    {
                                                        backgroundColor: status === "1" ? 'grey.blue' : '#D5D5D5',
                                                    },
                                                }}
                                                checked={status === "1"}
                                                onChange={(event) => {
                                                    const checked = event.target.checked;
                                                    setStatus(checked ? "1" : "2")
                                                    apiCalling(checked ? "1" : "2")

                                                }}
                                            />
                                            <Typography fontWeight={700}>
                                                {status === "1" ? "Activated" : "Deactivated"}
                                            </Typography>
                                            <ColorStatus code={status === "1" ? 1 : 3}></ColorStatus>
                                        </Stack>
                                    </Stack>
                                    <Stack gap={2} mb={8}>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Stack gap={0.5} width={90} flexShrink={0}>
                                                <Typography fontSize={14}>Temperature</Typography>
                                                <Typography fontSize={14} color={'#A0A3A6'}>
                                                    Unit: °F)
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'row'} gap={3}>
                                                <DefaultInput
                                                    value={formik.values.minTemperature}
                                                    fixedValue={'Min:'}
                                                    name={'minTemperature'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.minTemperature && formik.errors.minTemperature)}
                                                    helperText={formik.touched.minTemperature && formik.errors.minTemperature}
                                                />

                                                <DefaultInput
                                                    value={formik.values.maxTemperature}
                                                    fixedValue={'Max:'}
                                                    name={'maxTemperature'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.maxTemperature && formik.errors.maxTemperature)}
                                                    helperText={formik.touched.maxTemperature && formik.errors.maxTemperature}
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Stack gap={0.5} width={90} flexShrink={0}>
                                                <Typography fontSize={14}>Speed</Typography>
                                                <Typography fontSize={14} color={'#A0A3A6'}>
                                                    Unit: mph)
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'row'} gap={3}>
                                                <DefaultInput
                                                    value={formik.values.minSpeed}
                                                    fixedValue={'Min:'}
                                                    name={'minSpeed'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.minSpeed && formik.errors.minSpeed)}
                                                    helperText={formik.touched.minSpeed && formik.errors.minSpeed}
                                                />

                                                <DefaultInput
                                                    value={formik.values.maxSpeed}
                                                    fixedValue={'Max:'}
                                                    name={'maxSpeed'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.maxSpeed && formik.errors.maxSpeed)}
                                                    helperText={formik.touched.maxSpeed && formik.errors.maxSpeed}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Stack> */}
                                    <Stack
                                        mt={'auto'}
                                        gap={3}
                                    >
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Device State
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker ? (selectedTracker.state == "1" ? "Online" : "Offline") : "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Serial Number
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.deviceSerialNumber || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Tracker Type
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.type || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Battery (V)
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.loadedVoltage || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                Firmware
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.deviceFirmwareVersion || "-"}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                            <Typography color={'#AAB0BC'} fontSize={14}>
                                                HW Version
                                            </Typography>
                                            <Typography fontSize={14}>{selectedTracker && selectedTracker.hardwareVersion || "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </FormikProvider>

            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

import { Box, Stack, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tag } from '../../../ui/Tag/index.js';
import moment from 'moment';
import { arrayDeviceProduct, arrayDeviceType } from '../../../modules/RuleNew/data/arrayData.jsx';

export const columns = (calculateDuration, arrayDevices) => [
    {
        field: 'ruleName',
        headerName: 'Rule Name',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.ruleName ? value.row.ruleName : '-'}
            </Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: (value) => {
            var status = value.row.status == "1" ? "Active" : "Disabled"
            const borderColor = status == "Active" ? "green.main" : "#D5D5D5";
            const text = status
            const textColor = status == "Active" ? "green.main" : "";
            return (
                <Box
                    sx={{
                        pl: 2,
                        pr: 2,
                        border: '1px solid',
                        borderColor: borderColor,
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center'
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} >
                        <Typography fontSize={12}>{text}</Typography>
                    </Stack>
                </Box>
            );
        },
    },
    {
        field: 'product',
        headerName: 'Product',
        width: 130,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.product ? arrayDeviceProduct.find((product) => product.id == value.row.product)?.value : "-"}
            </Typography>
        ),
    },
    {
        field: 'type',
        headerName: 'Device',
        width: 250,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.type ? arrayDevices.find((type) => type.id == value.row.type)?.value : "-"}
            </Typography>
        ),
    },
    {
        field: 'updatedAt',
        headerName: 'Last Modified',
        width: 220,
        renderCell: ({ value }) => {
            return (
                <Typography fontSize={13}>{value ? moment(value).format("MMMM DD, YYYY h:mmA") : "-"}</Typography>
            )
        },
    },
    {
        field: 'arrowIcon',
        headerName: '',
        width: 130,
        renderCell: () => (
            <Stack direction={'row'} justifyContent={'flex-end'} pr={5} alignItems={'center'} width={'100%'} gap={1}>
                <ArrowForwardIos
                    sx={{
                        color: 'Black',
                        width: 18,
                        height: 18,
                    }}
                />
                <Typography color={'Black'} fontSize={14}>
                    Details
                </Typography>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const rows = [
    {
        "id": 1,
        "ruleName": 'Tempreature Violation',
        "status": '1',
        "product": 'Digital Matter',
        "device": 'Hawk Data Logger',
        "lastModified": 'July 2,2024',
    },
    {
        "id": 2,
        "ruleName": 'Tempreature Violation',
        "status": '0',
        "product": 'Digital Matter',
        "device": 'Hawk Data Logger',
        "lastModified": 'July 2,2024',
    },
    {
        "id": 3,
        "ruleName": 'Tempreature Violation',
        "status": '1',
        "product": 'Digital Matter',
        "device": 'Hawk Data Logger',
        "lastModified": 'July 2,2024',
    },
    {
        "id": 4,
        "ruleName": 'Tempreature Violation',
        "status": '0',
        "product": 'Digital Matter',
        "device": 'Hawk Data Logger',
        "lastModified": 'July 2,2024',
    },
];


import React from 'react';
import { Box } from '@mui/material';
import { getAssetStatusColor, getStatusColor } from '../../core/functions/statusHelpers.js';

export const ColorStatus = ({ code, size = 10, isAssetColor = false }) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor: isAssetColor ? getAssetStatusColor : getStatusColor(code),
            }}
        />
    );
};

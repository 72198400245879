import { Backdrop, Box, ButtonBase, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { DefaultInput } from '../../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../../ui/DefaultSelect/index.js';
import { FormikProvider, useFormik } from 'formik';
import api from '../../../../lib/axios.js';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { ConfirmAlert } from '../../../../customControls/confirmAlert.js';
import { arrayRole, arrayStatus } from './Data/UserData.jsx';

export const SettingUserManage = ({ children, selectedDataID, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            submit: null,
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            password: '',
            confirmPassword: '',
            selectedRole: null,
            company: '',
            selectedStatus: null,
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().max(255).required('First name is required'),
            lastName: Yup.string().max(255).required('Last name is required'),
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            // password: selectedDataID == "0" ? Yup.string().required('Password is required') : "",
            // confirmPassword: selectedDataID == "0" ? Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Password does not same') : "",
            mobile: Yup.string().max(15).required('Mobile is required'),
            selectedRole: Yup.object().required('Role is required'),
            // company: Yup.string().max(255).required('Company is required'),
            selectedStatus: Yup.object().required('Status is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                console.log("dataValues", values)
                var dataValues = {
                    submit: null,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobile: values.mobile,
                    password: values.password,
                    confirmPassword: values.password,
                    selectedRole: values.selectedRole,
                    company: values.company,
                    selectedStatus: values.selectedStatus
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apiCalling(values.firstName, values.lastName, values.email, values.mobile, values.password, values.selectedRole, values.selectedStatus, values.company);
                console.log("Data", data.data.success)
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    console.log("IF")
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('User ' + (selectedDataID == "0" ? "added" : "updated"), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    onClose(true, null)
                } else {
                    console.log("ELSE")
                    setStatus({ success: false });
                    setErrors({ submit: data.data.message });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })

    useEffect(() => {
        if (selectedDataID !== "" && selectedDataID !== "0") {
            getData();
        } else {
            setIsLoading(false);
        }
    }, [selectedDataID]);

    function getData() {
        setIsLoading(true)
        api.post('/apis/CompanyUsers/byID', { id: selectedDataID }).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                console.log("got data", data)
                var selectedRole = arrayRole.filter(role => role.id == data.role)
                if (selectedRole.length > 0) {
                    selectedRole = selectedRole[0]
                } else {
                    selectedRole = null
                }

                var selectedStatus = arrayStatus.filter(status => status.id == data.status)
                if (selectedStatus.length > 0) {
                    selectedStatus = selectedStatus[0]
                } else {
                    selectedStatus = null
                }

                var dataValues = {
                    submit: null,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    mobile: data.mobile,
                    password: "",
                    confirmPassword: "",
                    selectedRole: selectedRole,
                    company: data.company,
                    selectedStatus: selectedStatus,
                }
                console.log("dataValues", dataValues)
                formik.setValues(dataValues)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(firstName, lastName, email, mobile, password, selectedRole, selectedStatus, company) {
        var data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobile: mobile,
            password: password,
            role: selectedRole.id,
            status: selectedStatus.id,
            company: company
        }
        console.log("data", selectedRole)
        if (selectedDataID != "0") {
            return await api.put('/apis/CompanyUsers/' + selectedDataID, data)
        } else {
            return await api.post('/apis/CompanyUsers/', data)
        }

    }

    function deleteUser() {
        setIsLoading(true)
        api.delete('/apis/CompanyUsers/' + selectedDataID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('User deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                onClose(true, null)
            } else {
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteUser()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    function InvitationEmail() {
        setIsLoading(true)
        var data = {
            userId: selectedDataID
        }
        api.post('/apis/CompanyUsers/sendInvitationMail', data).then((res) => {
            if (res.data.success) {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            <Stack>
                <FormikProvider value={formik}>
                    <Stack
                        px={4}
                        pt={3}
                        pb={2}
                        gap={4}
                        overflowY={'auto'}
                        minWidth={'700px'}
                        height={'750px'}
                        maxHeight={'calc(100vh - 100px)'}
                        maxWidth={'700px'}
                        position={'fixed'}
                        zIndex={10}
                        sx={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            overflowY: 'auto',
                            borderRadius: '10px',
                            bgcolor: 'common.white',
                            border: '1px solid #D5D5D5',
                        }}
                        component={'form'}
                        onSubmit={formik.handleSubmit}
                    >
                        <Typography fontSize={18}>User Details</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'First Name'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.firstName}
                                    name={'firstName'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Last Name'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.lastName}
                                    name={'lastName'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                                <DefaultSelect
                                    sx={{
                                        '.MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Role'}
                                    value={formik.values.selectedRole}
                                    onItemSelected={(e) => {
                                        formik.setFieldValue('selectedRole', e.target.value)
                                    }}
                                    helperText={formik.touched.selectedRole && formik.errors.selectedRole}
                                    error={Boolean(formik.touched.selectedRole && formik.errors.selectedRole)}
                                    placeholder="Select role"
                                >
                                    {arrayRole.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item.value} {console.log("item.value : ", item.value)} {console.log(" Only item : ", item)} </MenuItem>

                                    ))}
                                </DefaultSelect>
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultSelect
                                    sx={{
                                        '.MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Status'}
                                    value={formik.values.selectedStatus}
                                    onItemSelected={(e) => {
                                        formik.setFieldValue('selectedStatus', e.target.value)
                                    }}
                                    helperText={formik.touched.selectedStatus && formik.errors.selectedStatus}
                                    error={Boolean(formik.touched.selectedStatus && formik.errors.selectedStatus)}
                                    placeholder="Select status"
                                >
                                    {arrayStatus.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                    ))}
                                </DefaultSelect>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Mobile'}
                                    placeholder={'E.g. 000-000-0000'}
                                    value={formik.values.mobile}
                                    name={'mobile'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                                    helperText={formik.touched.mobile && formik.errors.mobile}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <DefaultInput
                                    label={'Email'}
                                    placeholder={'E.g. example@oilservices.com'}
                                    value={formik.values.email}
                                    name={'email'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Box
                                    sx={{
                                        height: '1px',
                                        width: '100%',
                                        borderTop: '1px dashed #D5D5D5',
                                    }}
                                />
                            </Grid> */}
                            {/* <Grid item xs={4}>
                                <DefaultInput
                                    label={'Password'}
                                    placeholder={'E.g. ******************'}
                                    value={formik.values.password}
                                    type={'password'}
                                    name={'password'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.password && formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </Grid> */}
                            {/* <Grid item xs={4}>
                                <DefaultInput
                                    label={'Confirm Password'}
                                    placeholder={'E.g. ******************'}
                                    value={formik.values.confirmPassword}
                                    type={'password'}
                                    name={'confirmPassword'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                />
                            </Grid> */}
                            <Grid item xs={4} />
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        height: '1px',
                                        width: '100%',
                                        borderTop: '1px dashed #D5D5D5',
                                    }}
                                />
                            </Grid>
                            {selectedDataID != "0" && <Grid item xs={12}>
                                <Typography color={'#A8A8A7'} fontSize={12} mb={2}>
                                    Invitation Email
                                </Typography>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: '#EFEFEF',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    onClick={(e) => {
                                        InvitationEmail()
                                    }}
                                >
                                    <Typography fontSize={14} fontWeight={700}>
                                        Send
                                    </Typography>
                                </ButtonBase>
                            </Grid>}
                        </Grid>
                        <Box flex={1}>{children}</Box>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Stack>
                                {selectedDataID != "0" && <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #FF3941',
                                        bgcolor: 'common.white',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    onClick={(e) => {
                                        setDeleteConfirmationShouldOpen(true)
                                    }}
                                >

                                    <Typography fontSize={14} fontWeight={700}>
                                        Delete
                                    </Typography>
                                </ButtonBase>}
                            </Stack>
                            <Stack direction={'row'} gap={2}>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: 'primary.main',
                                        borderRadius: '5px',
                                        height: '30px'

                                    }}
                                    onClick={() => onClose(false, null)}
                                >
                                    <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                        Cancel
                                    </Typography>
                                </ButtonBase>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: '#EFEFEF',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                    onClick={() => console.log('Save button clicked', formik.isSubmitting)} // Add this line

                                >
                                    <Typography fontSize={14} fontWeight={700}>
                                        Save
                                    </Typography>
                                </ButtonBase>
                            </Stack>
                        </Stack>
                    </Stack>
                </FormikProvider >
            </Stack>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
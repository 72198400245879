import { Box, Grid, Stack, Typography, ButtonBase, Backdrop, CircularProgress, MenuItem } from '@mui/material';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import React, { useState, useEffect } from 'react';
import { tabs } from './data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import api from '../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import moment, { max } from 'moment';
import { CheckCircle } from '@mui/icons-material';
import { Filter } from '../../ui/Filter/Filter.jsx';
import { ConfirmAlert } from '../../customControls/confirmAlert.js';
import { useNavigate } from 'react-router';

export const TrackerDetailsModal = ({ selectedDataId, onClose, assetName }) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [trackerData, setTrackerType] = useState({});
    const [oemServer, setOemServer] = useState(null);
    const [arrayTimeIntervals, setArrayTimeIntervals] = useState([]);
    const [selectedIntervals, setSelectedIntervals] = useState(-1)
    const [second, setSecond] = useState(null)
    const [minutes, setMinutes] = useState(null);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [arrayTrackerType, setArrayTrackerType] = useState([]);

    const formik = useFormik({
        initialValues: {
            submit: null,
            serialNumber: "",
            selectedTrakerType: null,
            assetName: assetName,

        },
        validationSchema: Yup.object().shape({
            serialNumber: Yup.string().max(255).required('Serial number is required'),
            selectedTrakerType: Yup.object().required('Type is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setIsLoading(true)
                setSubmitting(true);
                if (activeTab == "intervals") {
                    updateIntervals(second)
                } else {
                    var dataValues = {
                        submit: null,
                        serialNumber: values.serialNumber,
                        selectedTrakerType: values.selectedTrakerType,
                    }
                    formik.setValues(dataValues)
                    var data = await apiCalling(values.serialNumber, values.selectedTrakerType);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        enqueueSnackbar('Tracker ' + (selectedDataId == "" ? "added" : "updated"), {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                        onClose(true)
                    } else {
                        setStatus({ success: false });
                        setErrors({ submit: data.data.message });
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                }
                setSubmitting(false);
            } catch (err) {
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })

    useEffect(() => {
        getTrackerType()
        //getCredentialData()
        // getDeviceList()
    }, [selectedDataId]);

    function getTrackerType() {
        setIsLoading(true)
        api.get('/apis/TrackerTypes/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTrackerType(resData)
                if (selectedDataId != "") {
                    const intervals = getTimeIntervals();
                    setArrayTimeIntervals(intervals)
                    getData()
                } else {
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        // setIsLoading(true);
        api.get('/apis/Trackers/byIdWithOem/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var dataValues = {
                    serialNumber: resData.deviceSerialNumber,
                }
                setTrackerType(resData)
                setOemServer(resData.objOemServer)
                setMinutes(parseInt(resData.interval, 10));
                formik.setValues(dataValues)
            }
            setIsLoading(false);
            // getCredentialData(resData.deviceSerialNumber)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(serialNumber, selectedTrakerType) {
        var data = {
            deviceSerialNumber: serialNumber,
            productId: selectedTrakerType.trackerTypeId,
            type: selectedTrakerType.name,
        }
        if (selectedDataId != "") {
            return await api.put('/apis/Trackers/' + selectedDataId, data)
        } else {
            return await api.post('/apis/Trackers/', data)
        }
    }

    const handleTabChange = (value) => {
        setActiveTab(value);
    };

    const getTimeIntervals = () => {
        const arrayTime = [];

        const timeData = [
            { id: "2", seconds: "600", timePeriod: "10 Minutes" },
            { id: "3", seconds: "900", timePeriod: "15 Minutes" },
            { id: "4", seconds: "1200", timePeriod: "20 Minutes" },
            { id: "5", seconds: "1500", timePeriod: "25 Minutes" },
            { id: "6", seconds: "1800", timePeriod: "30 Minutes" },
            { id: "7", seconds: "3600", timePeriod: "1 Hour" },
            { id: "8", seconds: "7200", timePeriod: "2 Hours" },
            { id: "9", seconds: "14400", timePeriod: "4 Hours" },
            { id: "10", seconds: "21600", timePeriod: "6 Hours" },
            { id: "11", seconds: "43200", timePeriod: "12 Hours" },
            { id: "12", seconds: "86400", timePeriod: "1 Day" }
        ];

        timeData.forEach(data => arrayTime.push(data));

        return arrayTime;
    };

    // async function setDeviceIntervals(second) {
    //     try {
    //         setIsLoading(true)
    //         const userName = localStorage.getItem("email")
    //         const password = localStorage.getItem("password")
    //         const credentials = `${userName}:${password}`;
    //         const base64Credentials = btoa(credentials);
    //         const duration = second / 60
    //         const oemServerProductID = oemServer && oemServer.ProductId ? oemServer.ProductId : null
    //         const productId = Math.floor(oemServerProductID);
    //         const mode = (productId === 97 || productId === 87) ? "2" : "1";
    //         const params = {
    //             Devices: [trackerData.deviceSerialNumber],
    //             ParamSections: [
    //                 {
    //                     Id: "2000",
    //                     Params: {
    //                         bPeriodicUploadHrMin: `${duration}`,
    //                         bTrackingMode: mode
    //                     }
    //                 }
    //             ]
    //         };
    //         const headers = {
    //             'Accept': 'application/json',
    //             'Authorization': `Basic ${base64Credentials}`
    //         }
    //         const response = await axios.post('/oemserver/v1.0/TrackingDevice/SetDeviceParameters/' + productId,
    //             params,
    //             { headers }
    //         );
    //         if (response.status == 200) {
    //             console.log("IF");
    //             updateIntervals(duration)
    //         }
    //         // setIsLoading(false);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // }

    // const updateIntervals = (duration) => {
    //     setIsLoading(true);
    //     const data = {
    //         interval: duration
    //     }
    //     api.put('apis/Trackers/updateInterval/' + selectedDataId, data).then((res) => {
    //         if (res.data.success) {
    //             console.log("res.data.success", res.data.success);
    //         }
    //         setIsLoading(false);
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    const updateIntervals = (second) => {
        //setIsLoading(true);
        const duration = second / 60
        const oemServerProductID = oemServer && oemServer.ProductId ? oemServer.ProductId : null
        const productId = Math.floor(oemServerProductID);
        const mode = (productId === 97 || productId === 87) ? "2" : "1";
        const data = {
            interval: duration,
            productId: productId,
            mode: mode
        }
        api.put('apis/Trackers/updateIntervalWithOem/' + selectedDataId, data).then((res) => {
            if (res.data.success) {
                console.log("res.data.success", res.data.success);
                enqueueSnackbar('Interval updated successfully', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getData()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const calculateTime = () => {
        if (trackerData && trackerData.timestamp && trackerData.interval) {
            var nextTransmittionDate = moment(trackerData.timestamp).add(trackerData.interval, 'minutes')
            return calculateDuration(moment(), nextTransmittionDate)
        } else {
            return "-"
        }
    };

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to hours and minutes
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        if (hours > 0 || minutes > 0) {
            return hours > 0 ? (hours + " hrs " + minutes + " mins ") : (minutes + " mins ");
        } else {
            return "-"
        }
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteTracker()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    function deleteTracker() {
        setIsLoading(true)
        api.delete('/apis/Trackers/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Tracker deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                onClose(true)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (

        <FormikProvider value={formik} component={'form'} onSubmit={formik.handleSubmit}>
            <Backdrop
                sx={{
                    zIndex: 9,
                    bgcolor: 'rgba(0, 0, 0, 0)',
                }}
                open={true}
            />
            <Stack
                px={4}
                pt={3}
                pb={2}
                gap={4}
                overflowY={'auto'}
                minWidth={'700px'}
                height={'750px'}
                maxHeight={'calc(100vh - 100px)'}
                maxWidth={'700px'}
                position={'fixed'}
                zIndex={10}
                sx={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY: 'auto',
                    borderRadius: '10px',
                    bgcolor: 'common.white',
                    border: '1px solid #D5D5D5',
                }}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Typography fontSize={18}>Tracker Details</Typography>
                <Box flex={1}>
                    <Stack gap={3}>
                        {selectedDataId == "" &&
                            <Stack direction={'row'} gap={3} >
                                <Stack flex={1}>
                                    <DefaultInput
                                        label='Serial Number'
                                        name={"serialNumber"}
                                        placeholder="E.g. 123"
                                        value={formik.values.serialNumber}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.serialNumber && formik.errors.serialNumber)}
                                        helperText={formik.touched.serialNumber && formik.errors.serialNumber} />
                                </Stack>
                                <Stack flex={1}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Type'}
                                        value={formik.values.selectedTrakerType}
                                        onItemSelected={(e) => formik.setFieldValue('selectedTrakerType', e.target.value)}
                                        helperText={formik.touched.selectedTrakerType && formik.errors.selectedTrakerType}
                                        error={Boolean(formik.touched.selectedTrakerType && formik.errors.selectedTrakerType)}
                                        placeholder="Select Type"
                                        displayKey="name"
                                    >
                                        {arrayTrackerType.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                        ))}

                                    </DefaultSelect>
                                </Stack>
                            </Stack>}
                        {selectedDataId != "" && <Stack gap={3}>
                            <Stack direction={'row'} gap={3} >
                                <Stack>
                                    <DefaultInput
                                        readOnly={true}
                                        sx={{
                                            '.MuiInput-input': {
                                                fontSize: 20,
                                                height: 40,
                                                py: 0,
                                                pr: 2,
                                                pl: 2,
                                                border: '0px solid #E2E2EA',
                                                borderRadius: '6px',
                                                boxSizing: 'border-box',
                                                bgcolor: '#F6F8FA',
                                            },
                                        }}
                                        label='Serial Number'
                                        name={"serialNumber"}
                                        value={formik.values.serialNumber}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.serialNumber && formik.errors.serialNumber)}
                                        helperText={formik.touched.serialNumber && formik.errors.serialNumber} />
                                </Stack>
                                <Stack position={'relative'}>
                                    <DefaultInput
                                        readOnly={true}
                                        sx={{
                                            '.MuiInput-input': {
                                                fontSize: 20,
                                                height: 40,
                                                py: 0,
                                                pr: 2,
                                                pl: 2,
                                                border: '0px solid #E2E2EA',
                                                borderRadius: '6px',
                                                boxSizing: 'border-box',
                                                bgcolor: '#F6F8FA',
                                            },
                                        }}
                                        label='Assign Asset'
                                        name={"assetName"}
                                        value={assetName} />
                                    {assetName != "-" &&
                                        <CheckCircle
                                            sx={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 24,
                                                width: 30,
                                                height: 30,
                                                color: 'green.main',
                                            }}
                                        />
                                    }
                                </Stack>
                            </Stack>
                            {/* <Stack direction={'row'} gap={3}>
                                <Stack gap={1}>
                                    <Typography color={'#A8A8A7'} fontSize={12}>
                                        Battery
                                    </Typography>
                                    <Box
                                        sx={{
                                            py: 0.5,
                                            px: 3,
                                            border: '1px solid #24D5AA',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Typography fontSize={14}>4.443</Typography>
                                    </Box>
                                </Stack>
                                <Stack gap={1}>
                                    <Typography color={'#A8A8A7'} fontSize={12}>
                                        Status
                                    </Typography>
                                    <Box
                                        sx={{
                                            py: 0.5,
                                            px: 3,
                                            border: '1px solid #24D5AA',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Typography fontSize={14}>Online</Typography>
                                    </Box>
                                </Stack>
                            </Stack> */}
                            <Box>
                                <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleTabChange} />
                            </Box>
                            <Stack gap={3} maxWidth={600}>
                                {activeTab === tabs[0].value && (
                                    <>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{oemServer && oemServer.IMEI ? oemServer.IMEI : "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            IMEI
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{oemServer && oemServer.ICCID ? oemServer.ICCID : "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            ICCID
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{oemServer && oemServer.ProductId ? oemServer.ProductId : "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Product
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{oemServer && oemServer.Vendor ? oemServer.Vendor : "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Vendor
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{oemServer && oemServer.BatchString ? oemServer.BatchString : "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Batch String
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{oemServer && oemServer.BatchString ? oemServer.FirmwareVersion : "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Firmware
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Typography fontSize={15} fontWeight={500}>TRACKER DATA</Typography>
                                        <Box
                                            sx={{
                                                p: 1,
                                                bgcolor: '#F6F8FA',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}>Last Connected</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{trackerData.lastOnlineDate ? moment(trackerData.lastOnlineDate).format("MM-DD-YYYY hh:mm:ss") : "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}> Device Temp.</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{trackerData.temperature || "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}>Battery(v)</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{oemServer && oemServer.LastBatteryLevel ? oemServer.LastBatteryLevel : "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }} color={'#A0A3A6'}>Longitude</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{oemServer && oemServer.LastLongitude ? oemServer.LastLongitude : "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}>Latitude</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{oemServer && oemServer.LastLongitude ? oemServer.LastLatitude : "-"}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={1}>
                                                        <Typography color={'#A8A8A7'} fontSize={15}>
                                                            Tracker
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                py: 0.5,
                                                                px: 3,
                                                                border: '1px solid #24D5AA',
                                                                borderRadius: '5px',
                                                                maxWidth: 90
                                                            }}
                                                        >
                                                            <Typography fontSize={14}>
                                                                {/* {trackerData.state == "1" ? "Online" : "Offline"} */}
                                                                {trackerData.timestamp ? moment().diff(moment(trackerData.timestamp), 'hour') > 12 ? "Offline" : "Online" : "-"}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {/* {activeTab === tabs[1].value && (
                                    <>
                                        <DefaultSelect
                                            defaultValue={'6 Hours'}
                                            name={'deviceDetails.expiration'}
                                            helperText={'Select Expiration'}
                                        />
                                        <Typography fontSize={14} color={'#A0A3A6'}>
                                            * Higher intervals will reduce battery life.
                                        </Typography>
                                        <Box
                                            sx={{
                                                p: 1,
                                                bgcolor: '#F6F8FA',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>07-07-2023 06:31:59</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Last Connected
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>07-07-2023 12:31:59</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Communication
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>Pending</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Operation Status
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500} fontSize={18}>
                                                            8 hrs 23 mins
                                                        </Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Transmission
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )} */}
                                {activeTab === tabs[1].value && (
                                    <>
                                        <Box width={'100%'}>
                                            <Filter
                                                onChange={(event) => {
                                                    var value = event.target.value
                                                    setSelectedIntervals(value)
                                                    console.log("value", value.seconds)
                                                    setSecond(value.seconds)
                                                }}
                                                defaultValue={selectedIntervals}
                                                placeholder="intervals"
                                            >
                                                {arrayTimeIntervals.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.timePeriod}</MenuItem>
                                                ))}
                                            </Filter>
                                        </Box>
                                        <Typography fontSize={14} color={'#A0A3A6'}>
                                            * Higher intervals will reduce battery life.
                                        </Typography>
                                        <Box
                                            sx={{
                                                p: 1,
                                                bgcolor: '#F6F8FA',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>{trackerData.timestamp ? moment(trackerData.timestamp).format("MM-DD-YYYY hh:mm:ss") : "-"}</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Last Connected
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>
                                                            {trackerData.timestamp && trackerData.interval ? moment(trackerData.timestamp).add(trackerData.interval, 'minutes').format("MM-DD-YYYY hh:mm:ss") : "-"}
                                                        </Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Communication
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>
                                                            {trackerData.intervalStatus ? trackerData.intervalStatus == 2 ? "Recive Successfully" : "Pending" : "-"}
                                                        </Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Operation Status
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500} fontSize={18}>
                                                            {calculateTime(minutes)}
                                                            {/* {trackerData.timestamp && trackerData.interval ? moment(trackerData.timestamp).add(trackerData.interval, 'minute').format("hh:mm") : "-"} */}
                                                        </Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Transmission
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                            </Stack>
                        </Stack>}
                    </Stack>
                </Box>
                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                    <Box></Box>
                    <Stack direction={'row'} gap={2}>
                        {selectedDataId != "" && <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #FF3941',
                                bgcolor: 'common.white',
                                borderRadius: '5px',
                            }}
                            onClick={(e) => {
                                setDeleteConfirmationShouldOpen(true)
                            }}
                        >
                            <Typography fontSize={14} fontWeight={700}>
                                Delete
                            </Typography>
                        </ButtonBase>}
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                bgcolor: 'primary.main',
                                borderRadius: '5px',
                                height: '30px'

                            }}
                            onClick={() => onClose(false)}
                        >
                            <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                Cancel
                            </Typography>
                        </ButtonBase>
                        {(selectedDataId == "" || (activeTab == "intervals" && oemServer)) && < ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                bgcolor: '#EFEFEF',
                                borderRadius: '5px',
                                height: '30px'

                            }}
                            disabled={formik.isSubmitting}
                            type="submit"
                        >
                            <Typography fontSize={14} fontWeight={700}>
                                Save
                            </Typography>
                        </ButtonBase>}
                    </Stack>
                </Stack>

                <Backdrop style={{ zIndex: 10 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                        updateConfirmAlert={(e, value) => {
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
            </Stack >
        </FormikProvider>
    );
};

import React, { createContext, useContext, useState } from 'react';

const CompanyContext = createContext();

export const useLogo = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
    const [logo, setLogo] = useState("");
    const [fullName, setFullName] = useState("");

    const updateLogo = (newLogo) => {
        setLogo(newLogo);
    };

    const updateFullName = (newFullName) => {
        console.log("newFullName", newFullName)
        setFullName(newFullName);
    };

    return (
        <CompanyContext.Provider value={{ logo, updateLogo, fullName, updateFullName }}>
            {children}
        </CompanyContext.Provider>
    );
};


export default CompanyContext;
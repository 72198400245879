import { Box, ButtonBase, Grid, Stack, Typography, Backdrop, CircularProgress, IconButton, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ConfirmAlert } from '../../../customControls/confirmAlert.js';
import api from '../../../lib/axios.js';
import { Map } from '../Details/Map.jsx';
import moment from 'moment'
import { enqueueSnackbar } from 'notistack';
// import { geoFenceColumns, sharingColumns } from '../data/locationMap.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Filter } from '../../../ui/Filter/Filter.jsx';
import { useNavigate } from 'react-router';

export const Interval = ({ handleChangeTrackersTab, activeTrackerTab, trackerID, activeTab, assetName, oemServer, trackerData }) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [sharingDeleteConfirmationShouldOpen, setSharingDeleteConfirmationShouldOpen] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [arrayTimeIntervals, setArrayTimeIntervals] = useState([]);
    const [selectedIntervals, setSelectedIntervals] = useState(-1)
    const [second, setSecond] = useState(null)
    const [minutes, setMinutes] = useState(null);
    const formik = useFormik({
        initialValues: {
            submit: null,
            serialNumber: "",
            selectedTrackerType: null,
            assetName: assetName,
        },
        validationSchema: Yup.object().shape({
            serialNumber: Yup.string().max(255).required('Serial number is required'),
            selectedTrackerType: Yup.object().required('Type is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setIsLoading(true)
                setSubmitting(true);
                updateIntervals(second)
                // setIsLoading(false)
                setSubmitting(false);
            } catch (err) {
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })

    useEffect(() => {
        getTrackerData()
    }, []);


    const onDeleteClick = (row) => {
        setSelectedDataId(row._id)
        setDeleteConfirmationShouldOpen(true)
    };

    function getTrackerData() {
        setIsLoading(true)
        api.get('/apis/TrackerTypes/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                //setArrayTrackerType(resData)
                if (trackerID != "") {
                    const intervals = getTimeIntervals();
                    setArrayTimeIntervals(intervals)
                    // getData()
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const calculateTime = () => {
        if (trackerData && trackerData.timestamp && trackerData.interval) {
            var nextTransmittionDate = moment(trackerData.timestamp).add(trackerData.interval, 'minutes')
            return calculateDuration(moment(), nextTransmittionDate)
        } else {
            return "-"
        }
    };
    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to hours and minutes
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        if (hours > 0 || minutes > 0) {
            return hours > 0 ? (hours + " hrs " + minutes + " mins ") : (minutes + " mins ");
        } else {
            return "-"
        }
    }

    const getTimeIntervals = () => {
        const arrayTime = [];

        const timeData = [
            { id: "2", seconds: "600", timePeriod: "10 Minutes" },
            { id: "3", seconds: "900", timePeriod: "15 Minutes" },
            { id: "4", seconds: "1200", timePeriod: "20 Minutes" },
            { id: "5", seconds: "1500", timePeriod: "25 Minutes" },
            { id: "6", seconds: "1800", timePeriod: "30 Minutes" },
            { id: "7", seconds: "3600", timePeriod: "1 Hour" },
            { id: "8", seconds: "7200", timePeriod: "2 Hours" },
            { id: "9", seconds: "14400", timePeriod: "4 Hours" },
            { id: "10", seconds: "21600", timePeriod: "6 Hours" },
            { id: "11", seconds: "43200", timePeriod: "12 Hours" },
            { id: "12", seconds: "86400", timePeriod: "1 Day" }
        ];

        timeData.forEach(data => arrayTime.push(data));

        return arrayTime;
    };

    const updateIntervals = (second) => {
        const duration = second / 60
        const oemServerProductID = oemServer && oemServer.ProductId ? oemServer.ProductId : null
        const productId = Math.floor(oemServerProductID);
        const mode = (productId === 97 || productId === 87) ? "2" : "1";
        const data = {
            interval: duration,
            productId: productId,
            mode: mode
        }
        api.put('apis/Trackers/updateIntervalWithOem/' + selectedDataId, data).then((res) => {
            if (res.data.success) {
                console.log("res.data.success", res.data.success);
                enqueueSnackbar('Interval updated successfully', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getData()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
            <FormikProvider value={formik} component={'form'} onSubmit={formik.handleSubmit}>

                <Stack
                    flex={1}
                    direction={'row'}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 0 0 8px',
                        borderRight: 'none',
                    }}
                    mb={1}
                    width={'calc(100% + 24px)'}
                >
                    <Stack gap={3} p={3} borderRight={'1px solid #E2E2EA'} width={380} flexShrink={0}>
                        <Stack spacing={1}>
                            <Typography fontSize={15} fontWeight={'Medium'} fontFamily={'Roboto'} color={'#1F243B'}>Interval TX Settings</Typography>
                            <Typography fontSize={12} color={'#A0A3A6'} fontWeight={'Regular'} fontFamily={'Roboto'}>Higher interval transmission settings will reduce battery life. Please use caution when selecting an interval.</Typography>
                        </Stack>
                        <Box width={'100%'}>
                            <Filter
                                onChange={(event) => {
                                    var value = event.target.value
                                    setSelectedIntervals(value)
                                    console.log("value", value.seconds)
                                    setSecond(value.seconds)
                                }}
                                defaultValue={selectedIntervals}
                                placeholder="Select time interval"
                            >
                                {arrayTimeIntervals.map((item, index) => (
                                    <MenuItem key={index} value={item}> {item.timePeriod}</MenuItem>
                                ))}
                            </Filter>
                        </Box>
                        <Stack gap={1}>
                            <Box px={2} py={1} ml={-1} width={'calc(100%)'} bgcolor={'#F6F8FA'} borderRadius={'12px'}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={'Medium'} fontFamily={'Roboto'} fontSize={15} color={'#1F243B'}>Data</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} color={'#AAB0BC'} fontWeight={'Regular'} fontFamily={'Roboto'}>
                                            Last Connected
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} fontWeight={'Regular'} fontFamily={'Roboto'} color={'#1F243B'}>{trackerData.timestamp ? moment(trackerData.timestamp).format("MM-DD-YYYY hh:mm:ss") : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} color={'#AAB0BC'} fontWeight={'Regular'} fontFamily={'Roboto'}>
                                            Next Communication
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} fontWeight={'Regular'} fontFamily={'Roboto'} color={'#1F243B'}>
                                            {trackerData.timestamp && trackerData.interval ? moment(trackerData.timestamp).add(trackerData.interval, 'minutes').format("MM-DD-YYYY hh:mm:ss") : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} fontWeight={'Regular'} fontFamily={'Roboto'} color={'#AAB0BC'}>
                                            Transmission Counter
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} fontWeight={'Regular'} fontFamily={'Roboto'} color={'#1F243B'}>
                                            {calculateTime(minutes)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} fontWeight={'Regular'} fontFamily={'Roboto'} color={'#AAB0BC'}>
                                            Operation Status
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={12} fontWeight={'Regular'} fontFamily={'Roboto'} color={'#1F243B'}>
                                            {trackerData.intervalStatus ? trackerData.intervalStatus == 2 ? "Recive Successfully" : "Pending" : "-"}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Stack>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Box></Box>
                            <Stack direction={'row'} gap={2}>
                                {/* {trackerID != "" && <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #FF3941',
                                    bgcolor: 'common.white',
                                    borderRadius: '5px',
                                }}
                                onClick={(e) => {
                                    setDeleteConfirmationShouldOpen(true)
                                }}
                            >
                                <Typography fontSize={14} fontWeight={700}>
                                    Delete
                                </Typography>
                            </ButtonBase>} */}
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: '#EFEFEF',
                                        border: '1px solid #F1F1F5',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    onClick={() => navigate(-1)}
                                >
                                    <Typography fontSize={14} fontWeight={700} fontFamily={'Roboto'} color={'#151D26'}>
                                        Cancel
                                    </Typography>
                                </ButtonBase>
                                {(trackerID == "" || (activeTab == "interval" && oemServer)) && < ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        bgcolor: '#FCFCFE',
                                        border: '1px solid #F1F1F5',
                                        borderRadius: '5px',
                                        height: '30px'
                                    }}
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                >
                                    <Typography fontSize={14} fontWeight={700} fontFamily={'Roboto'} color={'#151D26'}>
                                        Save
                                    </Typography>
                                </ButtonBase>}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Map trackerData={trackerData} mode={activeTab} activeTrackerTab={activeTrackerTab} handleChangeTrackersTab={handleChangeTrackersTab} />
                </Stack>
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                        updateConfirmAlert={(e, value) => {
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
                {
                    sharingDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                        updateConfirmAlert={(e, value) => {
                            setSharingDeleteConfirmationShouldOpen(false)
                            updateSharingConfirmAlert(value)
                        }} />)
                }
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </FormikProvider>
        </>
    );
};

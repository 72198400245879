import { Box, Typography } from '@mui/material';
import moment from 'moment';

export const movementColumns = (selectedData) => [
    {
        field: 'locations',
        headerName: 'Location (Area)',
        width: 200,
        // flex: 1,
        renderCell: (params) => (
            <Typography fontSize={13}>
                {params.row.positionLatitude + " , " + params.row.positionLongitude}
            </Typography>
        ),
    },
    {
        field: 'startTime',
        headerName: 'Timestamp',
        width: 220,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>
                {value ? moment(value).format("MMMM DD, YYYY hh:mm A") : '-'}
            </Typography>
        ),
    },
    {
        field: 'data',
        headerName: 'Data',
        flex: 1,
        renderCell: (params) => {
            const temperature = params.row.temperature;
            const speed = params.row.positionSpeed;
            const data = temperature || speed ? `${`${temperature} °F` || ''}${temperature && speed ? '&nbsp;&nbsp;&nbsp;&nbsp;' : ''}${`${speed} MPH` || ''}` : "-";
            return (
                <Typography fontSize={13} dangerouslySetInnerHTML={{ __html: data }} />
                // <Typography fontSize={13}>
                //     {data}
                // </Typography>
            );
        },
    }
];

export const rows = [
    {
        id: 1,
        asset: 'TR-500-14A',
        eventId: '1002',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 2,
        asset: 'TR-500-14A',
        eventId: '1003',
        sensor: 'GPS Device',
        type: 'Speed',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 3,
        asset: 'TR-500-14A',
        eventId: '1004',
        sensor: 'GPS Device',
        type: 'Altitude',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 1,
        },
    },
    {
        id: 4,
        asset: 'TR-500-14A',
        eventId: '1005',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 5,
        asset: 'TR-500-14A',
        eventId: '1006',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 6,
        asset: 'TR-500-14A',
        eventId: '1007',
        sensor: 'GPS Device',
        type: 'Speed',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 1,
        },
    },
];

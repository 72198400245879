import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, MenuItem, LinearProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DefaultCircularProgress } from '../../ui/DefaultCircularProgress/index.js';
import { ArrowIcon } from '../../assets/icons/ArrowIcon.jsx';
import { SearchInput } from '../../components/SearchInput/index.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import plusIcon from '../../assets/images/plusIcon.svg';
import excelFile from '../../assets/images/excelFile.svg';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns, rows } from './data/devices.jsx';
import { FormikProvider, useFormik } from 'formik';
import { TrackerDetailsModal } from './TrackerDetailsModal.jsx';
import api from '../../lib/axios.js';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { Filter } from '../../ui/Filter/Filter.jsx';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import moment from 'moment';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { enqueueSnackbar } from 'notistack';

export const Tracker = () => {
    const navigate = useNavigate();
    const [trackerDetailsModal, setTrackerDetailsModal] = useState(false);
    const [selectedDataId, setSelectedDataId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedTrackerType, setSelectedTrackerType] = useState(-1)
    const [arrayTrackerType, setArrayTrackerType] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [assetName, setAssetName] = useState("");
    const [trackerSerialNumber, setTrackerSerialNumber] = useState("");
    const trackerStatus = [
        { id: "1", value: "Assigned" },
        { id: "2", value: "Available" }
    ]
    const arrayTopStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Assigned", totals: 0, color: "#32D9A2" },
        { id: "2", name: "Available", totals: 0, color: "#FF3941" }
    ]
    const arrayTrackerStatstic = [
        { id: "0", name: "ASSIGNED", totals: 0, color: "#0A00FF" },
        { id: "1", name: "AVAILABLE", totals: 0, color: "green.main" },
        { id: "2", name: "DISABLED", totals: 3, color: "#FF3941" },
        { id: "3", name: "ORDERED", totals: 40, color: "Yellow" }
    ]

    const formik = useFormik({
        initialValues: {
            search: '',
            groups: '',
            status: '',
            devices: '',
            deviceDetails: {
                serialNumber: '',
                deviceType: '',
                expiration: '',
                interval: '',
            },
        },
        onSubmit: (values) => {
            console.log(values);
        },
        validateOnChange: false,
    });

    useEffect(() => {
        getTrackerType()
        getData()
    }, []);

    function getData() {
        setIsLoading(true)

        api.get('/apis/Trackers/').then((res) => {
            console.log("res.data", res.data.data)
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query, selectedStatus, selectedTrackerType)
                setFilteredArrayData(filterArray)
                setArrayData(resData)
                // pieChart("chartdiv", resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getTrackerType() {
        // setIsLoading(true)
        api.get('/apis/TrackerTypes/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTrackerType(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyFilters = (arrayDataAll, query, selectedStatus, selectedTrackerType) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['deviceSerialNumber', 'asset.assetName', 'type', 'loadedVoltage', 'temperature'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = getProperty(dataObj, property) + "";
                    console.log("str", str)
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (selectedStatus !== null && selectedStatus != -1) {
            var filteredArrayStatus = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (selectedStatus == 1 && dataObj.asset) {
                        containsQuery = true;
                    } else if (selectedStatus == 2 && dataObj.asset == null) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayStatus]
        }


        if (selectedTrackerType !== null && selectedTrackerType != -1) {
            var filteredArrayType = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.productId == selectedTrackerType) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayType]
        }

        if (query === "" && selectedStatus === -1 && selectedTrackerType === -1) {
            return arrayDataAll
        }

        return finalArray
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value, selectedStatus, selectedTrackerType)
        setFilteredArrayData(filterArray)
    };

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "0 mins";
        }
        return durationString.trim();
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    function onAssetClick(asset) {
        navigate(`/asset-listing/detail`, { state: { id: asset._id } })
    }

    function pieChart(chartID, arrayData, percentageInSummery) {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            // console.log("root.dom.id", root.dom.id);
            // console.log("chartID", chartID);

            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: am5.percent(90),
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        series.get("colors").set("colors", [
            am5.color(0x1E75FF),
            am5.color(0xE6E7E8),
        ]);
        series.data.setAll([
            { sector: "", size: 0 },
            { sector: "", size: 100 },
        ]);
        label.set("text", "0%");

        let percenatage = arrayData.filter(obj => obj.asset != null).length / arrayData.length * 100 || 0
        console.log("percentage", percenatage);

        if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
            const remainingValue = 100 - percenatage;
            console.log("remainingValue", remainingValue)
            series.data.setAll([
                { sector: "", size: percenatage },
                { sector: "", size: remainingValue },
            ]);

            label.set("text", `${parseInt(percenatage)}%`);
        } else {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            series.data.setAll([
                { sector: "", size: 0 },
                { sector: "", size: 100 },
            ]);
            label.set("text", "0%");
        }
        series.appear(1000, 100);
    }
    const totalAssigned = arrayData.filter(obj => obj.asset != null).length
    const totalAvailable = arrayData.filter(obj => obj.asset == null).length
    return (
        <FormikProvider value={formik}>
            {trackerDetailsModal &&
                <TrackerDetailsModal
                    selectedDataId={selectedDataId}
                    assetName={assetName}
                    serialNumber={trackerSerialNumber}
                    onClose={(e) => {
                        setTrackerDetailsModal(false)
                        if (e) {
                            getData()
                        }
                    }} />}
            <Stack width={'100%'} mb={1}>
                <Stack p={3}>
                    <Stack direction={'row'} gap={10} alignItems={'center'}>
                        <Stack>
                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'}>
                                TRACKERS
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'} gap={5}>
                                <Stack alignItems={'center'} mr={3} >
                                    <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                        Total
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: '#FFFFFF',
                                            mt: '16px'
                                        }}
                                    />
                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                        {arrayData.length}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />

                        <Stack width={"100%"}>
                            <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                Statistics
                            </Typography>
                            {arrayTrackerStatstic.length > 0 ?
                                <Grid container xs={12} columnSpacing={"26px"}>
                                    {arrayTrackerStatstic.map((obj) => {
                                        return (
                                            <Grid item xs={3} key={obj.id}>
                                                <Stack>
                                                    <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                        {obj.name + " " + `${obj.id == "0" ? parseInt((totalAssigned / arrayData.length) * 100) || 0 : obj.id == "1" ? parseInt((totalAvailable / arrayData.length) * 100) || 0 : obj.totals}%`}
                                                    </Typography>
                                                    <LinearProgress
                                                        sx={{
                                                            width: '100%',
                                                            height: "6px",
                                                            flexShrink: 0,
                                                            borderRadius: '2px',
                                                            bgcolor: '#EFEFEF',
                                                            mt: "17px",
                                                            '.MuiLinearProgress-bar': {
                                                                borderRadius: '3px',
                                                                bgcolor: obj.color ? obj.color : "",
                                                            },
                                                        }}
                                                        variant={'determinate'}
                                                        value={obj.id == "0" ? (totalAssigned / arrayData.length) * 100 || 0 : obj.id == "1" ? (totalAvailable / arrayData.length) * 100 || 0 : obj.totals}
                                                    />
                                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                        {obj.id == "0" ? totalAssigned : obj.id == "1" ? totalAvailable : obj.totals}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                : <Stack height="70px" />}
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} gap={2} mt={"32px"}>
                        <SearchInput name={'search'} handleQueryChange={handleQueryChange} />
                        {/* <Box maxWidth={200} width={'100%'}>
                            <DefaultSelect defaultValue={'Groups'} name={'groups'} />
                        </Box> */}
                        <Box maxWidth={200} width={'100%'}>
                            <Filter
                                onChange={(event) => {
                                    var value = event.target.value
                                    setSelectedStatus(value)
                                    var filterArray = applyFilters(arrayData, query, value, selectedTrackerType)
                                    setFilteredArrayData(filterArray)
                                }}
                                defaultValue={selectedStatus}
                                placeholder="All Tracker Status"
                            >
                                {trackerStatus.map((item, index) => (
                                    <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                ))}
                            </Filter>
                        </Box>
                        <Box maxWidth={200} width={'100%'}>
                            <Filter
                                onChange={(event) => {
                                    var value = event.target.value
                                    setSelectedTrackerType(value)
                                    var filterArray = applyFilters(arrayData, query, selectedStatus, value)
                                    setFilteredArrayData(filterArray)
                                }}
                                defaultValue={selectedTrackerType}
                                placeholder="All Tracker Type"
                            >
                                {arrayTrackerType.map((item, index) => (
                                    <MenuItem key={index} value={item.trackerTypeId}> {item.name}</MenuItem>
                                ))}
                            </Filter>
                        </Box>
                        {/* <Box maxWidth={200} width={'100%'}>
                            <DefaultSelect defaultValue={'Devices'} name={'devices'} />
                        </Box> */}
                        <ButtonBase
                            sx={{
                                height: 30,
                                pl: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #E2E2EA',
                                gap: 1,
                                borderRadius: '6px',
                            }}
                            onClick={(e) => {
                                setSelectedDataId("")
                                setTrackerDetailsModal(true)
                            }}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                                <Typography fontSize={12} color={'#615D5D'}>
                                    Add
                                </Typography>
                                <Box component={'img'} src={plusIcon} sx={{ pl: 1, pr: 1 }} />
                            </Stack>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}
                            onClick={() => {
                                if (filteredArrayData.length > 0) {
                                    const updatedArrayData = filteredArrayData.map((res) => {
                                        const date = res.timestamp ? res.timestamp : null
                                        var formatedDate = date != null ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"
                                        var assignedAsset = res.asset && res.asset.assetName ? res.asset.assetName : "None"
                                        var Status = res.asset ? "Assigned" : "Available"
                                        return {
                                            Device: res.deviceSerialNumber,
                                            "Assigned Asset": assignedAsset,
                                            Status: Status,
                                            Type: res.type || "-",
                                            "Battery(v)": res.loadedVoltage || "-",
                                            "Device Temp": res.temperature || "-",
                                            "Last Updated": formatedDate
                                        };
                                    });
                                    const csvData = Papa.unparse(updatedArrayData);

                                    // Create a Blob object containing the CSV data
                                    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

                                    // Create a URL for the Blob
                                    const url = URL.createObjectURL(blob);

                                    // Create a download link and trigger the click event to download the CSV file
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.download = `Trackers.csv`;
                                    link.click();

                                    // Release the Blob URL
                                    URL.revokeObjectURL(url);
                                } else {
                                    enqueueSnackbar("There is no data to export", {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                }

                            }}
                        >
                            <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />

                        </ButtonBase>
                    </Stack>
                </Stack>
                <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'}>
                    <DefaultDataGrid
                        pageItems={10}
                        pageSizeOptions={[10, 25, 50]}
                        rows={filteredArrayData}
                        columns={columns(calculateDuration, onAssetClick)}
                        onRowClick={(e) => {
                            navigate("/devices/detail", { state: { id: e.id, assetName: e.row.asset && e.row.asset.assetName ? e.row.asset.assetName : "-" } })
                            console.log("Data", e.row.deviceSerialNumber);
                            // setTrackerSerialNumber(e.row.deviceSerialNumber)
                            // setSelectedDataId(e.id)
                            // setTrackerDetailsModal(true)
                            // setAssetName(e.row.asset && e.row.asset.assetName ? e.row.asset.assetName : "-")
                        }}
                    />
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    );
};

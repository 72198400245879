export const arraycolors = [
    "#FC5308",
    "#0246FE",
    "#FCC267",
    "#6790FE",
    "#F9BC02",
    "#3E02A4",
    "#FFFE32",
    "#8602B0",
    "#D0E92B",
    "#A7194B",
    "#66B132",
    "#FF3941"
]
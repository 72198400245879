import { SettingsIcon } from '../../../assets/icons/SettingsIcon';
import { DashboardIcon } from '../../../assets/icons/DashboardIcon.jsx';
import { AssetMapViewIcon } from '../../../assets/icons/AssetMapViewIcon.jsx';
import { AssetListingIcon } from '../../../assets/icons/AssetListingIcon.jsx';
import { DevicesIcon } from '../../../assets/icons/DevicesIcon.jsx';
import { EventsIcon } from '../../../assets/icons/EventsIcon.jsx';
import { ReportingIcon } from '../../../assets/icons/ReportingIcon.jsx';
import { MessagesIcon } from '../../../assets/icons/MessagesIcon.jsx';
import { SupportIcon } from '../../../assets/icons/SupportIcon.jsx';

export const mainMenu = [
    // {
    //     title: 'Dashboard',
    //     link: '/dashboard',
    //     icon: <DashboardIcon />,
    // },
    {
        title: 'Maps',
        link: '/asset/asset-mapview',
        icon: 'fi fi-rs-marker',
        selectedIcon: 'fi fi-bs-marker',
    },
    {
        title: 'Assets',
        link: '/asset-listing',
        icon: 'fi fi-rr-layers',
        selectedIcon: 'fi fi-br-layers',
    },
    {
        title: 'Nodes',
        link: '/node-listing',
        icon: 'fi fi-rr-pulse',
        selectedIcon: 'fi fi-br-pulse',
    },
    {
        title: 'Events',
        link: '/events',
        icon: 'fi fi-rr-exclamation',
        selectedIcon: 'fi fi-br-exclamation',
        statusCode: 3,
        notificationsCount: 0,
    },
    {
        title: 'Rules Engine',
        link: '/rule-listing',
        icon: 'fi fi-rr-calendar-clock',
        selectedIcon: 'fi fi-br-calendar-clock',
    },

];

export const systemMenu = [
    // {
    //     title: 'Messages',
    //     link: '/messages',
    //     icon: <MessagesIcon />,
    //     notificationsCount: 200,
    // },
    {
        title: 'Billing Reports',
        link: '/billing-reports-listing',
        icon: 'fi fi-rs-clipboard-list-check',
        selectedIcon: 'fi fi-bs-clipboard-list-check',
    },
    {
        title: 'Trackers',
        link: '/devices',
        icon: 'fi fi-rr-location-alt',
        selectedIcon: 'fi fi-br-location-alt',
    },
    {
        title: 'Configuration',
        link: '/settings',
        icon: 'fi fi-rr-settings-sliders',
        selectedIcon: 'fi fi-br-settings-sliders',
    },
    // {
    //     title: 'Support',
    //     link: '/support',
    //     icon: <SupportIcon />,
    // },
];

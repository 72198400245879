import { Backdrop, Box, ButtonBase, CircularProgress, Stack, Typography, MenuItem, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '../../components/SearchInput/index.js';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns } from './data/reports.jsx';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import { Filter } from '../../ui/Filter/Filter.jsx';
import { arrayBillingCycle, arrayStatus, arrayYear } from './data/arrayData.jsx';

export const BillingReportListing = () => {
    const navigate = useNavigate();
    const [arrayBillingReport, setArrayBillingReport] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedCycle, setSelectedCycle] = useState(-1)
    const [selectedYear, setSelectedYear] = useState(-1)
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const [percentageInSummery, setPercentageInSummery] = useState(0);

    const arrayTopStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Paid", totals: 0, color: "#32D9A2" },
        { id: "2", name: "PastDue", totals: 0, color: "#FF3941" }
    ]
    useEffect(() => {
        // getSummery()
        getData()
    }, [selectedYear]);

    // const getSummery = async () => {
    //     // setIsLoading(true)
    //     api.get('/apis/Assets/summery').then(async (res) => {
    //         if (res.data.success) {
    //             var resData = res.data.data
    //             setArraySummeryGroups(resData.arrayGroup)
    //             setPercentageInSummery(resData.inServicePercentage)
    //             // pieChart("chartdiv", resData.inServicePercentage)
    //             // await new Promise(resolve => setTimeout(resolve, 0));
    //         }
    //         // setIsLoading(false)
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    const getData = () => {
        setIsLoading(true)
        var data = {
            year: selectedYear != -1 ? selectedYear : ""
        }
        api.post('/apis/Invoices/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                var filterArray = applyFilters(resData, query, selectedCycle, selectedStatus)
                setArrayBillingReport(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyFilters = (arrayDataAll, query, selectedCycle, selectedStatus) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['invoice'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (selectedStatus !== null && selectedStatus != -1) {
            var filteredArrayStatus = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.status === selectedStatus) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }

                    return matches;
                });
            finalArray = [...filteredArrayStatus]
        }

        if (selectedCycle !== null && selectedCycle != -1) {
            var filteredArrayGroup = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.billingCycle === selectedCycle) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayGroup]
        }

        if (query === "" && selectedCycle === -1 && selectedStatus === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayBillingReport, event.target.value, selectedCycle, selectedStatus)
        setFilteredArrayData(filterArray)
    };

    return (
        <Stack width={'100%'} mb={1}>
            <Stack p={3}>
                <Stack direction={'row'} gap={5} alignItems={'center'}>
                    <Stack >
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={'15px'} >
                            Invoices
                        </Typography>

                        <Stack direction={'row'} alignItems={'center'} gap={5} width={'100%'}>
                            {arrayTopStatusData.map((status) => (
                                <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                    <Stack width={'100%'}>
                                        <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                            {status.name}
                                        </Typography>
                                    </Stack>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: status.color,
                                            mt: '16px'
                                        }}
                                    />
                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                        {status.id == "0"
                                            ? arrayBillingReport.length
                                            : status.id == "1"
                                                ? arrayBillingReport.filter((data) => data.status == "2").length
                                                : arrayBillingReport.filter((data) => data.status == "1").length
                                        }
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                    <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                    <Stack width={"100%"}>
                        {/* <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                            {arraySummeryGroups.length > 0 ? "TOP Groups" : ""}
                        </Typography> */}
                        {arraySummeryGroups.length > 0 ?
                            <Grid container xs={12} columnSpacing={"26px"}>
                                {arraySummeryGroups.map((obj) => {
                                    return (
                                        <Grid item xs={2.4} key={obj._id}>
                                            <Stack>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                    {obj.name + " " + `${obj.percentage}%`}
                                                </Typography>
                                                <LinearProgress
                                                    sx={{
                                                        width: '100%',
                                                        height: "6px",
                                                        flexShrink: 0,
                                                        borderRadius: '2px',
                                                        bgcolor: '#EFEFEF',
                                                        mt: "17px",
                                                        '.MuiLinearProgress-bar': {
                                                            borderRadius: '3px',
                                                            bgcolor: obj.color ? obj.color : "",
                                                        },
                                                    }}
                                                    variant={'determinate'}
                                                    value={obj.percentage ? obj.percentage : 0}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                    {obj.assignedAsset}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            : <Stack height="70px" />}
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={1} mt={"32px"}>
                    <SearchInput name={'search'} handleQueryChange={handleQueryChange} sx={{ minWidth: '200px' }} />
                    <Box maxWidth={150} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedCycle(value)
                                var filterArray = applyFilters(arrayBillingReport, query, value, selectedStatus)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedCycle}
                            placeholder="All Cycles"
                        >
                            {arrayBillingCycle.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={160} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedStatus(value)
                                var filterArray = applyFilters(arrayBillingReport, query, selectedCycle, value)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedStatus}
                            placeholder="Status"
                        >
                            {arrayStatus.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={150} width={'100%'}>
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedYear(value)
                            }}
                            defaultValue={selectedYear}
                            placeholder="All Year"
                        >
                            {arrayYear.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                </Stack>
            </Stack>
            <Stack flex={1} border={'1px solid #E1E3E8'} borderRadius={'10px 0 0 10px'} ml={'22px'} mb={'19px'}>
                <DefaultDataGrid pageItems={10} pageSizeOptions={[10, 25, 50]} rows={filteredArrayData} columns={columns} onRowClick={(e) => navigate(`/billing-reports-listing/detail`, { state: { id: e.row._id } })} />
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};

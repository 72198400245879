import { Stack, Typography, Box } from '@mui/material';
import { getPriorityColor } from '../../../core/functions/statusHelpers.js';
import { arrayManufacturer } from '../../NodeNew/data/arrayData.jsx';
export const eventsColumns = [
    {
        field: 'eventID',
        headerName: 'Event ID',
        width: 130,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.eventID ? value.row.eventID : '-'}
            </Typography>
        ),
    },
    {
        field: 'ruleName',
        headerName: 'Rule',
        width: 150,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>
                {value}
            </Typography>
        ),
    },
    {
        field: 'eventType',
        headerName: 'Mesurement',
        width: 160,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{arrayManufacturer.find(obj => obj.id == value)?.value}</Typography>
        ),
    },
    {
        field: 'eventText',
        headerName: 'Condition',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.eventText ? value.row.eventText : '-'}
            </Typography>
        ),
    },
    {
        field: 'value',
        headerName: 'Data Value',
        width: 150,
        renderCell: (params) => (
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontSize={15}>{params.row.value}</Typography>
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getPriorityColor(params.row.value == "0" ? "3" : params.row.value),
                    }}
                />
            </Stack>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'Timestamp',
        width: 190,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{value}</Typography>
        )
    },
];
export const eventsColumns1 = [
    {
        field: 'eventID',
        headerName: 'Event ID',
        width: 130,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.eventID ? value.row.eventID : '-'}
            </Typography>
        ),
    },
    {
        field: 'assetName',
        headerName: 'Sensor',
        width: 150,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>GPS Device</Typography>
        ),
    },
    {
        field: 'eventType',
        headerName: 'Type',
        width: 150,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{value == "-1" ? "Geofence" : "Asset Alert"}</Typography>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'Timestamp',
        width: 200,
    },
    {
        field: 'eventText',
        headerName: 'Threshold',
        width: 250,
    },
    {
        field: 'value',
        headerName: 'Data Value',
        width: 130,
        renderCell: (params) => (
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontSize={15}>{params.row.value}</Typography>
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getPriorityColor(params.row.value == "0" ? "3" : params.row.value),
                    }}
                />
            </Stack>
        ),
    },
    {
        field: 'spacing',
        headerName: '',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
];

import { TimeLineGraph } from "../../../components/Timeline/TimeLineGraph"
import { Box, Stack, Typography } from "@mui/material"
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg'

export const TimelineChart = ({ arrayData, totalPercentage, title, subTitle, timelineOrder, index, onUp, onDown }) => {

    return (
        <Box
            sx={{
                // height: '155px',
                bgcolor: '#FFFFFF',
                borderRadius: '3.5px',
                border: '1px solid #E6E7E8',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '16px'
            }}
        >
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} py={'18px'} alignItems={'center'} gap={'25px'} pl={'25px'}>
                    <Typography fontFamily={'Roboto'} fontWeight={500} fontSize={12} color={'#051219'} sx={{ width: '200px' }}>{title} </Typography>
                    <Box width={'1px'} height={'18px'} bgcolor={"#051219"} />
                    <Typography fontFamily={'Roboto'} fontWeight={500} fontSize={12} color={'#051219'}> {subTitle} </Typography>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} mr={'24px'} >
                    <Box
                        sx={{
                            height: '13px',
                            width: '10px',
                            opacity: index === 0 ? 0.5 : 1,
                            cursor: index === 0 ? 'not-allowed' : 'pointer',
                        }}
                        component={'img'}
                        onClick={index > 0 ? () => onUp() : null}
                        src={ArrowUpIcon}
                    ></Box>
                    <Box
                        sx={{
                            height: '13px',
                            width: '10px',
                            ml: '18px',
                            mr: '11px',
                            opacity: (index === (timelineOrder.length - 1)) ? 0.5 : 1,
                            cursor: (index === (timelineOrder.length - 1)) ? 'not-allowed' : 'pointer',
                        }}
                        component={'img'}
                        onClick={index != (timelineOrder.length - 1) ? () => onDown() : null}
                        src={ArrowDownIcon}></Box>
                    {/* <Box
                        sx={{
                            height: '24px',
                            width: '24px'
                        }}
                        component={'img'}
                        src={CloseIcon}></Box> */}
                </Stack>
            </Stack>
            <Box width={'100%'} height={'2px'} bgcolor={"#E6E7E8"} />

            {arrayData.length > 0 && <TimeLineGraph arrayData={arrayData} totalPercentage={totalPercentage} />}
        </Box>
    )

}
import { Backdrop, Box, ButtonBase, CircularProgress, MenuItem, Stack, Typography, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../../ui/DefaultInput/DefaultInput.jsx';
import { arrayGraphColor } from './data/arrayData.jsx';
import api from '../../../lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { arrayChartType } from '../Charts/Data/arrayData.jsx';
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx'

export const SelectChartAlert = ({ selectedDataId, nodeData, sensorData, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGraphColor, setSelectedGraphColor] = useState("");
    const [arraySensorDataType, setArraySensorDataType] = useState([]);
    const formik = useFormik({
        initialValues: {
            groupName: nodeData?.chartGroupName || '',
            selectedChart: null,
            selectedSensor: null,
            chartTitle: nodeData?.objChart?.chartName || '',
        },
        validationSchema: Yup.object().shape({
            groupName: Yup.string().required('Group name is required'),
            selectedChart: Yup.object().required('Chart type is required'),
            selectedSensor: Yup.object().required('Sensor is required'),
            chartTitle: Yup.string().required('Chart title is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                if (selectedGraphColor == "") {
                    enqueueSnackbar('Chart color is required', { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                } else {
                    var dataValues = {
                        groupName: values.groupName,
                        selectedChart: values.selectedChart,
                        selectedSensor: values.selectedSensor,
                        chartTitle: values.chartTitle,
                    }
                    formik.setValues(dataValues)
                    setIsLoading(true)
                    var data = await apicalling(values);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        enqueueSnackbar('Chart updated', {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                        onClose(true)
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                    setSubmitting(false);
                }
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        if (nodeData != null && sensorData != null) {
            console.log("PRODUCT:", sensorData.product)
            if (sensorData.product == '4') {
                const arrayMeasurment = [
                    { id: "1", value: "Temperature", field: "temperature", symbol: "°F" },
                    { id: "4", value: "Battery", field: "battery", symbol: "" },
                    { id: "6", value: "Rssi", field: "rssi", symbol: "" },
                    { id: '7', value: 'rms_ACC_G ', field: "RmsACCG" },
                    { id: '8', value: 'max_ACC_G', field: "MaxACCG" },
                    { id: '9', value: 'velocity_mm_sec', field: "VelocityMmSec" },
                    { id: '10', value: 'displacement_mm', field: "DisplacementMm" },
                    { id: '11', value: 'peak_one_Hz', field: "PeakOneHz" },
                    { id: '12', value: 'peak_two_Hz', field: "PeakTwoHz" },
                    { id: '13', value: 'peak_three_Hz', field: "PeakThreeHz" }
                ]
                setArraySensorDataType(arrayMeasurment)
            } else {
                getSecondaryData()
            }
        }
    }, []);

    useEffect(() => {
        var selectedSensor = arraySensorDataType.find(obj => obj.id == nodeData?.objChart?.sensor)
        var selectedChart = arrayChartType.find(obj => obj.id == nodeData?.objChart?.chartType)
        var dataValues = {
            groupName: nodeData?.chartGroupName || '',
            selectedChart: selectedChart,
            selectedSensor: selectedSensor,
            chartTitle: nodeData?.objChart?.chartName || '',
        }
        formik.setValues(dataValues)
        setSelectedGraphColor(nodeData?.objChart?.color || '')
    }, [arraySensorDataType]);


    const getSecondaryData = () => {
        setIsLoading(true)
        api.get('/apis/SecondaryDatas/byNode/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                let arraySensorDataType = arrayMeasurment.filter(item => item.array.includes(sensorData.product + ""))
                data.forEach((obj) => {
                    const exists = arraySensorDataType.some(sensor =>
                        sensor.id === obj._id);
                    if (!exists) {
                        let newData = { id: obj._id, value: obj.name, field: "pin", symbol: "" }
                        arraySensorDataType.push(newData);

                    }
                })
                setArraySensorDataType(arraySensorDataType)

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleCheckboxChange = (graphColor) => {
        setSelectedGraphColor(selectedGraphColor === graphColor ? "" : graphColor);
    };

    async function apicalling(values) {
        var data = {
            chartGroupName: values.groupName,
            chartType: values.selectedChart.id,
            sensor: values.selectedSensor.id,
            chartName: values.chartTitle,
            color: selectedGraphColor
        }
        return await api.put('/apis/Nodes/updateChart/' + selectedDataId, data)
    }


    return (
        <>
            <FormikProvider value={formik}>
                <Stack
                    ml={1}
                    mr={3}
                    gap={3}
                    p="17px"
                    sx={{
                        border: '1px solid #E6E7E8',
                        borderRadius: '3.5px',
                        width: 332,
                    }}
                    component={'form'}
                    onSubmit={formik.handleSubmit}>
                    <Stack gap={2}>
                        <Typography fontFamily={'Roboto'} fontWeight={'Bold'} color={'#1F243B'} fontSize={15}>Chart Settings</Typography>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Stack gap="15px" width={'100%'}>
                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                            },
                                        }}
                                        label={'Data Group Name'}
                                        value={formik.values.groupName}
                                        name={'groupName'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.groupName && formik.errors.groupName)}
                                        helperText={formik.touched.groupName && formik.errors.groupName}
                                    />
                                </Stack>
                                <Stack maxWidth={200}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Select Chart Type'}
                                        value={formik.values.selectedChart}
                                        onItemSelected={(e) => {
                                            formik.setFieldValue('selectedChart', e.target.value)
                                        }}
                                        helperText={formik.touched.selectedChart && formik.errors.selectedChart}
                                        error={Boolean(formik.touched.selectedChart && formik.errors.selectedChart)}
                                    >
                                        {arrayChartType.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                        ))}
                                    </DefaultSelect>
                                </Stack>
                                <Stack maxWidth={200}>
                                    <DefaultSelect
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: 40,
                                            },
                                        }}
                                        label={'Select Sensor'}
                                        value={formik.values.selectedSensor}
                                        onItemSelected={(e) => {
                                            formik.setFieldValue('selectedSensor', e.target.value)
                                        }}
                                        helperText={formik.touched.selectedSensor && formik.errors.selectedSensor}
                                        error={Boolean(formik.touched.selectedSensor && formik.errors.selectedSensor)}
                                    >
                                        {arraySensorDataType.map((item, index) => (
                                            <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                        ))}
                                    </DefaultSelect>
                                </Stack>

                                <Stack maxWidth={200}>
                                    <DefaultInput
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                pr: 2,
                                                width: 200
                                            },
                                        }}
                                        label={'Chart Title'}
                                        value={formik.values.chartTitle}
                                        name={'chartTitle'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.chartTitle && formik.errors.chartTitle)}
                                        helperText={formik.touched.chartTitle && formik.errors.chartTitle}
                                    />
                                </Stack>

                                <Stack>
                                    <Grid container xs={12}>
                                        {arrayGraphColor.map((graph, index) => (
                                            <Grid item xs={2.4} key={index} >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                    mb={'15px'}
                                                >
                                                    <Checkbox
                                                        sx={{
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            color: '#B5B5BE',
                                                        }}

                                                        checked={selectedGraphColor === graph.code}
                                                        onChange={() => handleCheckboxChange(graph.code)}
                                                    />
                                                    <Box
                                                        width="25px"
                                                        height="25px"
                                                        bgcolor={graph.code}
                                                        borderRadius="50%"
                                                    />
                                                </Stack>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Stack>
                                <Stack direction={'row'} gap={2} >
                                    <Box flex={1}></Box>
                                    <ButtonBase
                                        sx={{
                                            border: '1px solid #E2E2EA',
                                            bgcolor: '#F6F8FA',
                                            borderRadius: '5px',
                                            height: '30px',
                                            width: '100px'
                                        }}
                                        onClick={() => { onClose(false) }}
                                    >
                                        <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                            Cancel
                                        </Typography>
                                    </ButtonBase>
                                    <ButtonBase
                                        sx={{
                                            height: '30px',
                                            width: '100px',
                                            bgcolor: '#1E75FF',
                                            borderRadius: '5px',
                                        }}
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={500} color={'#FAFAFB'}>
                                            Save
                                        </Typography>
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </FormikProvider>
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { typesColumns, typesRows } from './data/nodeTypes';
import { DefaultDataGrid } from '../../../../../ui/DefaultDataGrid/index.js';
import { useEffect, useState } from 'react';
import { NodeTypesManage } from './NodeTypesManage.jsx';
import api from '../../../../../lib/axios.js';

export const NodeTypes = ({ nodeTypeModal, setNodeTypeModal, query, selectedNodeTypeCategory, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayTypeData, setArrayTypeData] = useState([]);
    const [arrayFilterData, setArrayFilterData] = useState([]);

    useEffect(() => {
        if (nodeTypeModal === null) {
            getTypeData()
            setNodeTypeModal(null)
        }
    }, [nodeTypeModal]);

    function getTypeData() {
        setIsLoading(true)
        api.get('/apis/NodeTypes/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("query", query)
                console.log("selectedNodeTypeCategory", selectedNodeTypeCategory)
                var filterArray = applyFilters(resData, query, selectedNodeTypeCategory)
                setArrayFilterData(filterArray)
                setArrayTypeData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        var filterArray = applyFilters(arrayTypeData, query, selectedNodeTypeCategory)
        setArrayFilterData(filterArray)
    }, [query, selectedNodeTypeCategory]);

    const applyFilters = (arrayDataAll, query, selectedNodeTypeCategory) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['name', 'description'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (selectedNodeTypeCategory !== null && selectedNodeTypeCategory != -1) {
            var filteredArrayStatus = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.nodeCategoryID === selectedNodeTypeCategory) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayStatus]
        }

        if (query === "" && selectedNodeTypeCategory === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    return (
        <>
            <Stack
                flex={1}
                width={'100% + 24px'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                    pointerEvents: nodeTypeModal && 'none',
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
            >
                <DefaultDataGrid
                    columns={typesColumns}
                    rows={arrayFilterData}
                    onRowClick={(e) => {
                        setNodeTypeModal(e.row._id)
                    }}
                />
            </Stack>
            {nodeTypeModal != null && <NodeTypesManage selectedDataID={nodeTypeModal} onClose={setNodeTypeModal} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>

    )
}
import React, { useEffect } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import showPasswordIcon from '../../assets/icons/show-password.svg';
import hidePasswordIcon from '../../assets/icons/hide-password.svg';
import { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack'
import gtm from '../../lib/gtm';
import { Link } from 'react-router-dom';

export const Login = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { isExpired } = useAuth();
    const isMountedRef = useIsMountedRef();
    const { login, openForgot } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setSubmitting(true);
                var data = await login(values.email, values.password);
                if (data !== undefined && data.success) {
                    setStatus({ success: true });
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                } else {
                    enqueueSnackbar(data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                    setStatus({ success: false });
                    setErrors({ submit: data.message });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                console.log(err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        console.log("Is Expired", isExpired)
        if (isExpired) {
            enqueueSnackbar("Your Login is expired, Please login again", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        gtm.push({ event: 'page_view' });
    }, []);

    return (
        <Stack
            alignItems={'flex-start'}
            justifyContent={'center'}
            bgcolor={'#000000'}
            py={10}
            px={20}
            width={'100%'}
            position={'relative'}
            overflow={'hidden'}
        >
            <Box
                src={'/assets/login-bg.png'}
                component={'img'}
                sx={{
                    width: '1000px',
                    height: '600px',
                    position: 'absolute',
                    left: '500px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            />
            <FormikProvider value={formik}>
                <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={350}>
                    <Box width={285} height={54} src={'/icons/logo.svg'} component={'img'} mb={4} />
                    <Typography color={'common.white'} fontWeight={700} fontSize={25} mb={2}>
                        Sign In
                    </Typography>
                    <Typography color={'common.white'} mb={3}>
                        Hi! Welcome back, lets get to work.
                    </Typography>
                    <Box component={'form'} onSubmit={formik.handleSubmit} width={'100%'}>
                        <Stack spacing={1.5} width={'100%'}>
                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'common.white'}>
                                    Email
                                </Typography>
                                <TextField
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '17px 25px',
                                            borderRadius: '10px',
                                            background: 'white',
                                            border: '1px solid #E1E3E8',
                                        },
                                    }}
                                    type={'email'}
                                    placeholder='example@oilservices.com'
                                    name={'email'}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Stack>
                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'common.white'}>
                                    Password
                                </Typography>
                                <Box position={'relative'}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '30px', //'50%',
                                            right: '30px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            zIndex: 1,
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                        }}
                                        component={'img'}
                                        src={showPassword ? hidePasswordIcon : showPasswordIcon}
                                        onClick={() => setShowPassword((prevState) => !prevState)}
                                    />
                                    <TextField
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                lineHeight: '1.5',
                                                padding: '17px 60px 17px 25px',
                                                borderRadius: '10px',
                                                background: 'white',
                                                border: '1px solid #E1E3E8',
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                lineHeight: 'normal', // Reset line-height for Safari
                                                position: 'relative', // Change from absolute to relative
                                                top: 'auto', // Reset top position
                                                transform: 'none', // Reset transform
                                                opacity: '1', // Ensure placeholder is visible
                                            },
                                        }}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='*************************'
                                        name={'password'}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        error={Boolean(formik.touched.password && formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Box>
                            </Stack>
                            <Link to={'/authentication/forgot-password'}>
                                <Typography
                                    sx={{
                                        paddingRight: "10px",
                                        color: 'common.white',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: 13,
                                        textAlign: 'right',
                                        '&:hover': {
                                            textDecoration: 'none',
                                        },
                                    }}
                                >
                                    Forgot Password?
                                </Typography>
                            </Link>
                            <Typography
                                sx={{
                                    color: 'common.white',
                                    fontSize: 13,
                                    textAlign: 'right',
                                    a: {
                                        paddingRight: "10px",
                                        color: 'common.white',
                                        textDecoration: 'underline',
                                        fontSize: 13,
                                        textAlign: 'right',
                                        '&:hover': {
                                            textDecoration: 'none',
                                        },
                                    },
                                }}
                            >
                                Not a Member?{' '}
                                <Link to={'/authentication/register'}>
                                    Sign Up
                                </Link>
                            </Typography>
                            <Button
                                sx={{
                                    fontSize: 20,
                                    py: 1.2,
                                    borderRadius: '30px',
                                    maxWidth: 300,
                                    width: '100%',
                                    alignSelf: 'center',
                                    bgcolor: 'common.white',
                                    color: 'common.black',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        bgcolor: 'common.white',
                                    },
                                }}
                                type={'submit'}
                                disabled={formik.isSubmitting}
                            >
                                Sign In
                            </Button>
                        </Stack>
                    </Box>
                    <Typography color={'common.white'} mt={4}>
                        SIGNALTRACKS, LLC
                    </Typography>
                </Stack>
            </FormikProvider>
        </Stack>
    );
};

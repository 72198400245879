export const tabs = [
    {
        title: 'Map',
        value: 'map',
    },
    {
        title: 'Timeline',
        value: 'timeline',
    },
    {
        title: 'Telemetry',
        value: 'telemetry',
    },
    {
        title: 'Movements',
        value: 'movement',
    },
    {
        title: 'Events',
        value: 'events',
    },
    // {
    //     title: 'Details',
    //     value: 'details',
    // },
    // {
    //     title: 'Device',
    //     value: 'device',
    // },
];

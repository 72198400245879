import { Stack } from "@mui/material";
import { DefaultDataGrid } from "../../../ui/DefaultDataGrid";
import { columns, row } from "./data/logs";

export const Logs = () => {
    return (
        <Stack mt={'14px'} flex={1}>
            <DefaultDataGrid pageItems={10} pageSizeOptions={[10, 25, 50]} rows={row} columns={columns} />
        </Stack>
    )
}
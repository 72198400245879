import { Backdrop, CircularProgress, Stack } from "@mui/material";
import { usersColumns, usersRows } from "../../data/users"
import { SettingUserManage } from "./SettingUserManage";
import { DefaultDataGrid } from "../../../../ui/DefaultDataGrid";
import { useEffect, useState } from "react";
import api from "../../../../lib/axios";

export const SettingUser = ({ userDetailsModalOpen, setUserDetailsModalOpen, query, updateData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayUserData, setArrayUserData] = useState([]);
    const [arrayFilterData, setArrayFilterData] = useState([]);
    const [selectedDataID, setSelectedDataID] = useState(null);

    useEffect(() => {
        if (userDetailsModalOpen === null) {
            getData()
            setUserDetailsModalOpen(null)
        }
    }, [userDetailsModalOpen]);

    // useEffect(() => {
    //      updateData()
    // }, []);

    useEffect(() => {
        var filterArray = applyFilters(arrayUserData, query)
        setArrayFilterData(filterArray)
    }, [query]);

    function getData() {
        setIsLoading(true)
        api.post('/apis/CompanyUsers/byCompanyID/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setArrayFilterData(filterArray)
                setArrayUserData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    const applyFilters = (arrayDataAll, query) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['firstName', 'lastName', 'email'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []


        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    return (
        <>
            <Stack
                flex={1}
                width={'100% + 24px'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                    pointerEvents: userDetailsModalOpen && 'none',
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
            >
                <DefaultDataGrid
                    columns={usersColumns}
                    rows={arrayFilterData}
                    onRowClick={(e) => {
                        setUserDetailsModalOpen(e.row._id)
                    }}
                />
            </Stack>
            {userDetailsModalOpen != null && <SettingUserManage selectedDataID={userDetailsModalOpen} onClose={(e, value) => {
                setUserDetailsModalOpen(value)
                if (e) {
                    updateData()
                }

            }} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
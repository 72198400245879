export const tabs = [
    {
        title: 'Details',
        value: 'details',
    },
    {
        title: 'Intervals',
        value: 'intervals',
    },
];

export const tabs1 = [
    {
        title: 'Details',
        value: 'details',
    },
    {
        title: 'Recovery',
        value: 'recovery',
    },
    {
        title: 'Intervals',
        value: 'intervals',
    },
];

import { Box, Grid, Stack, Typography, ButtonBase, Backdrop, CircularProgress } from '@mui/material';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import React, { useState, useEffect } from 'react';
import { tabs } from '../../modules/Devices/data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { DefaultToggle } from '../../ui/DefaultToggle/DefaultToggle.jsx';
import api from '../../lib/axios';
import { enqueueSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { CheckCircle } from '@mui/icons-material';
var moment = require("moment");


export const DeviceDetailsModal = ({ selectedDataId, assetName, tracker, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabs[0].value);

    const formik = useFormik({
        initialValues: {
            submit: null,
            serialNumber: tracker.deviceSerialNumber,
            assetName: assetName,
            isPrimary: tracker.role == "Primary"
        },
        validationSchema: Yup.object().shape({
            serialNumber: Yup.string().max(255).required('Serial number is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setSubmitting(true);
                var selectedTracker = { ...tracker }
                selectedTracker.role = values.isPrimary ? "Primary" : "Secondary"
                onClose(true, selectedTracker)
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    })

    useEffect(() => {

    }, []);

    const getData = () => {
        setIsLoading(true);
        api.get('/apis/Trackers/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var dataValues = {
                    serialNumber: resData.deviceSerialNumber,
                }
                formik.setValues(dataValues)
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getCredentialData = () => {
        // setIsLoading(true);
        api.get('/apis/ProjectSetups/getOemCredential').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
            }
            // setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function getDeviceList() {
        try {
            const username = 'daniel@signaltracks.com';
            const password = 'Redbox4ever!Signal';
            const credentials = `${username}:${password}`;
            const base64Credentials = btoa(credentials);
            const response = await axios.get('https://api.oemserver.com/v1.0/trackingdevice/getcellulardevicelist', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Basic ${base64Credentials}`
                }
            });
            if (response.status == 200) {
                if (response.data) {
                    console.log('Response:', response.data);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const handleTabChange = (value) => {
        setActiveTab(value);
    };

    return (
        <FormikProvider value={formik} component={'form'} onSubmit={formik.handleSubmit}>
            <Stack
                px={4}
                pt={3}
                pb={2}
                gap={4}
                overflowY={'auto'}
                minWidth={'700px'}
                height={'750px'}
                maxHeight={'calc(100vh - 100px)'}
                maxWidth={'700px'}
                position={'fixed'}
                zIndex={10}
                sx={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY: 'auto',
                    borderRadius: '10px',
                    bgcolor: 'common.white',
                    border: '1px solid #D5D5D5',
                }}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Typography fontSize={18}>Device Details</Typography>
                <Box flex={1}>
                    <Stack gap={3}>
                        <Stack direction={'row'} gap={3}  >
                            <Stack>
                                <DefaultInput
                                    readOnly={true}
                                    sx={{
                                        '.MuiInput-input': {
                                            fontSize: 20,
                                            height: 40,
                                            py: 0,
                                            pr: 2,
                                            pl: 2,
                                            border: '0px solid #E2E2EA',
                                            borderRadius: '6px',
                                            boxSizing: 'border-box',
                                            bgcolor: '#F6F8FA',
                                        },
                                    }}
                                    label='Serial Number'
                                    name={"serialNumber"}
                                    value={formik.values.serialNumber}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.serialNumber && formik.errors.serialNumber)}
                                    helperText={formik.touched.serialNumber && formik.errors.serialNumber} />
                            </Stack>

                            <Stack position={'relative'}>
                                <DefaultInput
                                    readOnly={true}
                                    sx={{
                                        '.MuiInput-input': {
                                            fontSize: 20,
                                            height: 40,
                                            py: 0,
                                            pr: 2,
                                            pl: 2,
                                            border: '0px solid #E2E2EA',
                                            borderRadius: '6px',
                                            boxSizing: 'border-box',
                                            bgcolor: '#F6F8FA',
                                        },
                                    }}
                                    label='Assign Asset'
                                    name={"assetName"}
                                    value={formik.values.assetName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.assetName && formik.errors.assetName)}
                                    helperText={formik.touched.assetName && formik.errors.assetName} />
                                <CheckCircle
                                    sx={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 24,
                                        width: 30,
                                        height: 30,
                                        color: 'green.main',
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={8} alignItems={'center'} my={-1}>
                            <Typography fontSize={14}>PRIMARY ROLE</Typography>
                            <DefaultToggle name={'isPrimary'} />
                        </Stack>
                        {selectedDataId != "" && <Stack gap={3}>
                            {/* <Stack direction={'row'} gap={3}>
                                <Stack gap={1}>
                                    <Typography color={'#A8A8A7'} fontSize={12}>
                                        Battery
                                    </Typography>
                                    <Box
                                        sx={{
                                            py: 0.5,
                                            px: 3,
                                            border: '1px solid #24D5AA',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Typography fontSize={14}>4.443</Typography>
                                    </Box>
                                </Stack>
                                <Stack gap={1}>
                                    <Typography color={'#A8A8A7'} fontSize={12}>
                                        Status
                                    </Typography>
                                    <Box
                                        sx={{
                                            py: 0.5,
                                            px: 3,
                                            border: '1px solid #24D5AA',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Typography fontSize={14}>Online</Typography>
                                    </Box>
                                </Stack>
                            </Stack> */}
                            <Box>
                                <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleTabChange} />
                            </Box>
                            <Stack gap={3} maxWidth={600}>
                                {activeTab === tabs[0].value && (
                                    <>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{tracker.ident || "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            IMEI
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{tracker.gsmSimIccid || "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            ICCID
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{tracker.productId || "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Product
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>SIGNAL TRACKS</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Vendor
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>Shipment 14 March 2024</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Batch String
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontSize={15} fontWeight={500}>{tracker.firmware || "-"}</Typography>
                                                        <Typography fontSize={15} color={'#A0A3A6'}>
                                                            Firmware
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Typography fontSize={15} fontWeight={500}>DEVICE DATA</Typography>
                                        <Box
                                            sx={{
                                                p: 1,
                                                bgcolor: '#F6F8FA',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}>Last Connected</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{tracker.lastOnlineDate ? moment(tracker.lastOnlineDate).format("MM-DD-YYYY hh:mm:ss") : "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}> Device Temp.</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{tracker.temperature || "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}>Battery(v)</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{tracker.loadedVoltage || "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }} color={'#A0A3A6'}>Longitude</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{tracker.positionLongitude || "-"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} color={'#A0A3A6'}>Latitude</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography fontSize={15} style={{ flexGrow: 1 }}>{tracker.positionLatitude || "-"}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Stack gap={1}>
                                                        <Typography color={'#A8A8A7'} fontSize={15}>
                                                            Device
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                py: 0.5,
                                                                px: 3,
                                                                border: '1px solid #24D5AA',
                                                                borderRadius: '5px',
                                                                maxWidth: 90
                                                            }}
                                                        >
                                                            <Typography fontSize={14}>{tracker.state == "1" ? "Online" : "Offline"}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {/* {activeTab === tabs[1].value && (
                                    <>
                                        <DefaultSelect
                                            defaultValue={'6 Hours'}
                                            name={'deviceDetails.expiration'}
                                            helperText={'Select Expiration'}
                                        />
                                        <Typography fontSize={14} color={'#A0A3A6'}>
                                            * Higher intervals will reduce battery life.
                                        </Typography>
                                        <Box
                                            sx={{
                                                p: 1,
                                                bgcolor: '#F6F8FA',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>07-07-2023 06:31:59</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Last Connected
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>07-07-2023 12:31:59</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Communication
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>Pending</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Operation Status
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500} fontSize={18}>
                                                            8 hrs 23 mins
                                                        </Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Transmission
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )} */}
                                {activeTab === tabs[1].value && (
                                    <>
                                        <DefaultSelect
                                            defaultValue={'6 Hours'}
                                            name={'deviceDetails.interval'}
                                            label={'Select Reporting Interval'}
                                        />
                                        <Typography fontSize={14} color={'#A0A3A6'}>
                                            * Higher intervals will reduce battery life.
                                        </Typography>
                                        <Box
                                            sx={{
                                                p: 1,
                                                bgcolor: '#F6F8FA',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>07-07-2023 06:31:59</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Last Connected
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>07-07-2023 12:31:59</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Communication
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500}>Pending</Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Operation Status
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack gap={0.5}>
                                                        <Typography fontWeight={500} fontSize={18}>
                                                            8 hrs 23 mins
                                                        </Typography>
                                                        <Typography fontSize={12} color={'#A0A3A6'}>
                                                            Next Transmission
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                            </Stack>
                        </Stack>}
                    </Stack>
                </Box>
                <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                    <Box></Box>
                    <Stack direction={'row'} gap={2}>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                bgcolor: 'primary.main',
                                borderRadius: '5px',
                            }}
                            onClick={() => onClose(false, null)}
                        >
                            <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                Cancel
                            </Typography>
                        </ButtonBase>
                        < ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                bgcolor: '#EFEFEF',
                                borderRadius: '5px',
                            }}
                            disabled={formik.isSubmitting}
                            type="submit"
                        >
                            <Typography fontSize={14} fontWeight={700}>
                                Save
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack >
        </FormikProvider>
    );
};

export const tabs = [
    {
        title: 'Overview',
        value: 'overview',
    },
    {
        title: 'Timeline',
        value: 'timeline',
    },
    {
        title: 'Data',
        value: 'data',
    },
    {
        title: 'Charts',
        value: 'charts',
    },
    {
        title: 'Events',
        value: 'events',
    },
    {
        title: 'ML Assistant',
        value: 'ml',
    },
    // {
    //     title: 'Details',
    //     value: 'details',
    // },
    // {
    //     title: 'Secondary Data',
    //     value: 'secondaryData',
    // },
];

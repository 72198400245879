import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { typesColumns, typesRows } from '../../../../../modules/Settings/data/types.jsx';
import { DefaultDataGrid } from '../../../../../ui/DefaultDataGrid/index.js';
import { useEffect, useState } from 'react';
import { AssetTypeManage } from './AssetTypesManage.jsx';
import api from '../../../../../lib/axios.js';

export const AssetTypes = ({ assetTypeModal, setAssetTypeModal, query, selectedAssetTypeCategory, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayTypeData, setArrayTypeData] = useState([]);
    const [arrayFilterData, setArrayFilterData] = useState([]);

    useEffect(() => {
        if (assetTypeModal === null) {
            getTypeData()
            setAssetTypeModal(null)
        }
    }, [assetTypeModal]);

    function getTypeData() {
        setIsLoading(true)
        api.get('/apis/Types/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("query",query)
                console.log("selectedAssetTypeCategory",selectedAssetTypeCategory)
                var filterArray = applyFilters(resData, query,selectedAssetTypeCategory)
                setArrayFilterData(filterArray)
                setArrayTypeData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        var filterArray = applyFilters(arrayTypeData, query,selectedAssetTypeCategory)
        setArrayFilterData(filterArray)
    }, [query,selectedAssetTypeCategory]);

    const applyFilters = (arrayDataAll, query, selectedAssetTypeCategory) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['name', 'description'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (selectedAssetTypeCategory !== null && selectedAssetTypeCategory != -1) {
            var filteredArrayStatus = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.categoryID === selectedAssetTypeCategory) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayStatus]
        }

        if (query === "" && selectedAssetTypeCategory === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    return (
        <>
            <Stack
                flex={1}
                width={'100% + 24px'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                    pointerEvents: assetTypeModal && 'none',
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
            >
                <DefaultDataGrid
                    columns={typesColumns}
                    rows={arrayFilterData}
                    onRowClick={(e) => {
                        setAssetTypeModal(e.row._id)
                    }}
                />
            </Stack>
            {assetTypeModal != null && <AssetTypeManage selectedDataID={assetTypeModal} onClose={setAssetTypeModal} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>

    )
}
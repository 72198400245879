import { Box, Stack, Typography } from '@mui/material';
import { ColorStatus } from '../../../../ui/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tag } from '../../../../ui/Tag/index.js';
import moment from 'moment';

export const columns = [
    {
        field: 'mlSensorID',
        headerName: 'ML Session ID',
        width: 200,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{value ? value : "-"}</Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (value) => {
            var status = value.row.status == "1" ? "Active" : "Completed"
            const borderColor = status == "Active" ? "green.main" : "#1E75FF";
            const text = status
            const textColor = status == "Active" ? "green.main" : "#1E75FF";
            return (value.row.status == "1" ?
                <Tag textColor={textColor} text={text} borderColor={borderColor} sx={{ width: '80px', alignItems: 'center' }} /> :
                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>Completed</Typography>
            );
        },
    },
    {
        field: 'model',
        headerName: 'Model',
        width: 300,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{value ? value : "-"}</Typography>
        ),
    },
    {
        field: 'data',
        headerName: 'Data',
        width: 200,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{value ? value : "-"}</Typography>
        ),
    },
    {
        field: 'timestamp',
        headerName: 'Training Date',
        flex: 1,
        renderCell: (value) => {
            const date = value.row.timestamp ? value.row.timestamp : "-"
            return (
                // <Typography fontSize={13}>{(date == null || date == "") ? "-" : moment(date).format("MM/DD/YY hh:mm a")}</Typography>
                <Typography fontSize={13}>{date}</Typography>
            )
        },
    },
    {
        field: 'arrowIcon',
        headerName: '',
        width: 130,
        renderCell: () => (
            <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={1}>
                <ArrowForwardIos
                    sx={{
                        color: '#151D26',
                        width: 18,
                        height: 18,
                    }}
                />
                <Typography color={'#151D26'} fontSize={14}>
                    Details
                </Typography>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];


export const row = [
    {
        id: 1,
        mlSensorID: '10022',
        status: '1',
        model: 'HBOS - Historical Based Outlier',
        data: 'Vibration Data',
        timestamp: 'July 2, 2024 1:23PM CST',
    },
    {
        id: 2,
        mlSensorID: '10001',
        status: '2',
        model: 'HBOS - Historical Based Outlier',
        data: 'Vibration Data',
        timestamp: 'July 2, 2024 1:23PM CST',
    },
    {
        id: 3,
        mlSensorID: '10002',
        status: '1',
        model: 'HBOS - Historical Based Outlier',
        data: 'Vibration Data',
        timestamp: 'July 2, 2024 1:23PM CST',
    },
    {
        id: 4,
        mlSensorID: '10003',
        status: '2',
        model: 'HBOS - Historical Based Outlier',
        data: 'Vibration Data',
        timestamp: 'July 2, 2024 1:23PM CST',
    },
    {
        id: 5,
        mlSensorID: '10004',
        status: '1',
        model: 'HBOS - Historical Based Outlier',
        data: 'Vibration Data',
        timestamp: 'July 2, 2024 1:23PM CST',
    },
]

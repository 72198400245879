import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { categoriesColumns, categoriesRows } from '../../../../../modules/Settings/data/categories.jsx';
import { DefaultDataGrid } from '../../../../../ui/DefaultDataGrid/index.js';
import { useEffect, useState } from 'react';
import api from '../../../../..//lib/axios.js';
import { AssetCategoriesManage } from './AssetCategoriesManage.jsx';

export const AssetCategories = ({ assetCategoryModal, setAssetCategoryModal,query }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayCategoryData, setArrayCategoryData] = useState([]);
    const [arrayFilterData, setArrayFilterData] = useState([]);


    useEffect(() => {
        if (assetCategoryModal === null) {
            getCategoryData()
            setAssetCategoryModal(null)
        }
    }, [assetCategoryModal]);

    function getCategoryData() {
        setIsLoading(true)
        api.get('/apis/Categories/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setArrayFilterData(filterArray)
                setArrayCategoryData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        var filterArray = applyFilters(arrayCategoryData, query)
        setArrayFilterData(filterArray)
    }, [query]);

    const applyFilters = (arrayDataAll, query) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['name', 'description'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    return (
        <>
            <Stack
                flex={1}
                width={'100% + 24px'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                    pointerEvents: assetCategoryModal && 'none',
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
            >
                <DefaultDataGrid
                    columns={categoriesColumns}
                    rows={arrayFilterData}
                    onRowClick={(e) => {
                        setAssetCategoryModal(e.row._id)
                    }}
                />
            </Stack>
            {assetCategoryModal != null && <AssetCategoriesManage selectedDataID={assetCategoryModal} onClose={setAssetCategoryModal} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
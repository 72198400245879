export const arrayCondition = [
    { id: "1", value: "Equals not" },
    { id: "2", value: "Less than" },
    { id: "3", value: "Less than or equal" },
    { id: "4", value: "Greater than" },
    { id: "5", value: "Greater than or equal" },
    { id: "6", value: "Inside range" },
    { id: "7", value: "Outside range" },
]

export const arrayMeasurment = [
    { id: "1", value: "Temperature", array: ["1", "2", "3", "4"], field: "temperature", symbol: "°F" },
    { id: "2", value: "Altitude", array: ["1", "2", "3"], field: "altitude", symbol: "m" },
    { id: "3", value: "Speed", array: ["1", "2", "3"], field: "speed", symbol: "mph" },
    { id: "4", value: "Battery", array: ["1", "2", "3", "4"], field: "battery", symbol: "" },
    { id: "5", value: "Cell Signal", array: ["1", "2", "3"], field: "cellSignal", symbol: "" },
    { id: "6", value: "Rssi", array: ["4"], field: "rssi", symbol: "" },
    { id: '7', value: 'x_rms_ACC_G', array: ["4"], field: "xRmsACCG", symbol: "" },
    { id: '8', value: 'y_rms_ACC_G', array: ["4"], field: "yRmsACCG", symbol: "" },
    { id: '9', value: 'z_rms_ACC_G', array: ["4"], field: "zRmsACCG", symbol: "" },
    { id: '10', value: 'x_max_ACC_G', array: ["4"], field: "xMaxACCG", symbol: "" },
    { id: '11', value: 'y_max_ACC_G', array: ["4"], field: "yMaxACCG", symbol: "" },
    { id: '12', value: 'z_max_ACC_G', array: ["4"], field: "zMaxACCG", symbol: "" },
    { id: '13', value: 'x_velocity_mm_sec', array: ["4"], field: "xVelocityMmSec", symbol: "" },
    { id: '14', value: 'y_velocity_mm_sec', array: ["4"], field: "yVelocityMmSec", symbol: "" },
    { id: '15', value: 'z_velocity_mm_sec', array: ["4"], field: "zVelocityMmSec", symbol: "" },
    { id: '16', value: 'x_displacement_mm', array: ["4"], field: "xDisplacementMm", symbol: "" },
    { id: '17', value: 'y_displacement_mm', array: ["4"], field: "yDisplacementMm", symbol: "" },
    { id: '18', value: 'z_displacement_mm', array: ["4"], field: "zDisplacementMm", symbol: "" },
    { id: '19', value: 'x_peak_one_Hz', array: ["4"], field: "xPeakOneHz", symbol: "" },
    { id: '20', value: 'y_peak_one_Hz', array: ["4"], field: "yPeakOneHz", symbol: "" },
    { id: '21', value: 'z_peak_one_Hz', array: ["4"], field: "zPeakOneHz", symbol: "" },
    { id: '22', value: 'x_peak_two_Hz', array: ["4"], field: "xPeakTwoHz", symbol: "" },
    { id: '23', value: 'y_peak_two_Hz', array: ["4"], field: "yPeakTwoHz", symbol: "" },
    { id: '24', value: 'z_peak_two_Hz', array: ["4"], field: "zPeakTwoHz", symbol: "" },
    { id: '25', value: 'x_peak_three_Hz', array: ["4"], field: "xPeakThreeHz", symbol: "" },
    { id: '26', value: 'y_peak_three_Hz', array: ["4"], field: "yPeakThreeHz", symbol: "" },
    { id: '27', value: 'z_peak_three_Hz', array: ["4"], field: "zPeakThreeHz", symbol: "" },
]

export const arrayThreshold = [
    // { id: "1", value: "Days", },
    { id: "2", value: "Hours", },
    { id: "3", value: "Minutes", },
    // { id: "4", value: "Seconds", }
]


export const arrayAssetDeviceProduct = [
    { id: "1", value: "Digital Matter" }
]

export const arrayDeviceProduct = [
    { id: "1", value: "Digital Matter" },
    { id: "2", value: "Particle.io" },
    { id: "3", value: "Myriota" },
    { id: "4", value: "NCD.io" }
]

export const arrayDeviceType = [
    { id: "1", value: "HAWK" },
    { id: "2", value: "Monitor One" },
    { id: "3", value: "FlexSense" },
    { id: "4", value: "Smart Vibration" }
]

export const arrayRuleType = [
    { id: "1", value: "Asset" },
    { id: "2", value: "Node" },
]
import { Backdrop, CircularProgress, Stack } from "@mui/material";
import { groupsColumns } from '../../../../modules/Settings/data/groups.jsx';
import { DefaultDataGrid } from "../../../../ui/DefaultDataGrid";
import { useEffect, useState } from "react";
import api from "../../../../lib/axios";
import { SettingGroupsManage } from "./SettingGroupsManage";

export const SettingGroups = ({ groupDetailsModal, setGroupDetailsModal, query, updateData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayGroupsData, setArrayGroupsData] = useState([]);
    const [arrayFilterData, setArrayFilterData] = useState([]);

    useEffect(() => {
        console.log("groupDetailsModal", groupDetailsModal)
        if (groupDetailsModal === null) {
            getData()
            setGroupDetailsModal(null)
        }

    }, [groupDetailsModal]);

    // useEffect(() => {
    //     updateData()
    // }, [arrayGroupsData.length]);

    useEffect(() => {
        var filterArray = applyFilters(arrayGroupsData, query)
        setArrayFilterData(filterArray)
    }, [query]);

    const applyFilters = (arrayDataAll, query) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['name', 'description'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    function getData() {
        setIsLoading(true)
        api.get('/apis/Groups/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setArrayFilterData(filterArray)
                setArrayGroupsData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            <Stack
                flex={1}
                width={'100% + 24px'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                    pointerEvents: groupDetailsModal && 'none',
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
            >
                <DefaultDataGrid
                    columns={groupsColumns}
                    rows={arrayFilterData}
                    onRowClick={(e) => {
                        setGroupDetailsModal(e.row._id)
                    }}
                />
            </Stack>
            {groupDetailsModal != null && <SettingGroupsManage selectedDataID={groupDetailsModal} onClose={(e, value) => {
                setGroupDetailsModal(value)
                if (e) {
                    updateData()
                }
            }} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
import { Box, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Tag } from '../../ui/Tag/index.js';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '../../components/SearchInput/index.js';


export const AssetItem = ({ id, title, temp, speed, tags, deviceID }) => {
    const navigate = useNavigate();

    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            gap={3}
            alignItems={'center'}
            py={1.5}
            px={2.5}
            borderBottom={'2px dashed #E1E3E8'}
            sx={{
                cursor: 'pointer',
            }}
            onClick={() => navigate(`/asset-listing/detail`, { state: { id: id, isFromMap: true } })}
        >
            <Stack gap={1}>
                {/* <SearchInput
                    name={'search'}
                    // handleQueryChange={handleQueryChange} 
                    sx={{ minWidth: '200px' }} /> */}
                <Typography fontWeight={500} fontSize={14} color={"#151D26"} whiteSpace={'nowrap'} fontFamily={'Roboto'}>
                    {title}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <Box
                        sx={{
                            px: '12px',
                            border: '1px solid #E1E3E8',
                            height: '20px',
                            borderRadius: '5px',
                            bgcolor: '#36B368',
                            alignContent: 'center'
                        }}
                    >
                        <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#FFFFFF'}>
                            {speed != null && speed != "" && speed != "-" ? (speed + " MPH") : "-"}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            px: '12px',
                            height: '20px',
                            borderRadius: '5px',
                            bgcolor: '#F6F7FA',
                            alignContent: 'center'
                        }}
                    >
                        <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'}>
                            {deviceID != null && deviceID != "" && deviceID != "-" ? deviceID : "-"}
                        </Typography>
                    </Box>

                    {/* <Stack direction={'row'} gap={1}>
                        {tags.map((tag, index) => (
                            <Tag
                                key={`asset-tag-${index}`}
                                text={tag.text}
                                textSize={tag.textSize}
                                borderColor={tag.borderColor}
                                status={tag.status}
                                isAssetColor={true}
                            />
                        ))}
                    </Stack> */}

                    <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'}>
                        {temp != null && temp != "" && temp != "-" ? (temp + " °F") : "-"}
                    </Typography>

                </Stack>
            </Stack>
            <ExpandMore
                sx={{
                    transform: 'rotate(-90deg) translateY(5px)',
                    flexShrink: 0,
                }}
            />
        </Stack>
    );
};

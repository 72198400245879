export const arrayPinType = [
    { id: '1', name: 'Analogue' },
    { id: '2', name: 'Digital' },
];

export const arrayAnaloguePin = [
    { pin: "5" },
    { pin: "6" },
    { pin: "7" },
    { pin: "8" },
    { pin: "9" },
    { pin: "10" },
    { pin: "11" },
    { pin: "12" },
    { pin: "13" },
    { pin: "14" },
    { pin: "15" },
    { pin: "16" },
    { pin: "17" },
    { pin: "18" },
    { pin: "19" },
    { pin: "20" },
]

export const arrayDigitalPin = [
    { pin: "1" },
    { pin: "2" },
    { pin: "3" },
    { pin: "4" },
    { pin: "5" },
    { pin: "6" },
    { pin: "7" },
    { pin: "8" },
    { pin: "9" },
    { pin: "10" },
]

export const arrayDataType = [
    { id: '1', name: 'Float' },
    { id: '2', name: 'Int' },
    { id: '3', name: 'String' },
]

// export const arrayDecimalPlace = [
//     { id: '1', value: '1' },
//     { id: '2', value: '2' },
//     { id: '3', value: '3' },
// ]
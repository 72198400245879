import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { sidebarOpened } from '../../core/store/index.js';
import pin from '../../assets/images/blackPin.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnbmFsdHJhY2tzIiwiYSI6ImNsd3Rjd3A1bTAyYnQybnBuNXZnajJ2YjEifQ.6DdrfKM2ke64TpX-Igogdg';

export const MapViewNode = ({ sx, nodeData, isFullMap }) => {
    const navigate = useNavigate();
    const isOpenedSidebar = useRecoilValue(sidebarOpened);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-74.01521529520066);
    const [lat, setLat] = useState(40.73760113154407);
    const [zoom, setZoom] = useState(9);
    const nodeStatus = ['Online', 'Offline'];


    const getAssetStatusColor = (code) => {
        switch (code) {
            case 'Ready':
                return '#0061FF';
            case 'In Service':
                return '#19A872';
            case 'In Maintenance':
                return '#f47979';
            case 'Not In Service':
                return '#121212';
            case 'In Repair':
                return '#FFAA50';
            default:
                return '#19A872';
        }
    };

    function createCluster(props) {
        let html = `<div class="map-cluster">
        <span></span>
        <span></span>
        ${props.point_count}
            <div />`;

        const el = document.createElement('div');
        el.innerHTML = html;
        return el.firstChild;
    }

    const renderMap = () => {
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/signaltracks/clwun35vw012v01qsagva4ax3',
                center: [lng, lat],
                zoom: zoom,
            });
            map.current.addControl(new mapboxgl.NavigationControl());
            let arrayCluster = []
            nodeData.map((sensor) => {
                if (sensor.sensor != null && sensor.sensor.longitude != null && sensor.sensor.latitude != null && sensor.sensor.longitude != "-" && sensor.sensor.latitude != "-") {
                    arrayCluster.push(sensor)
                }
            })
            if (map.current && arrayCluster.length > 0) {
                map.current.setCenter([arrayCluster[0].sensor.longitude, arrayCluster[0].sensor.latitude])
            }
            const waypoints = arrayCluster.map((cluster) => ({
                type: "Feature",
                properties: cluster,
                geometry: {
                    type: "Point",
                    coordinates: [cluster.sensor.longitude, cluster.sensor.latitude, 0.0],
                },
            }));
            var objCluster = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                "features": waypoints
            }

            map.current.on('load', () => {
                map.current.loadImage(pin, function (error, images) {
                    if (error) throw error;
                    map.current.addImage('custom-pin', images);
                });
                map.current.addSource('tracks', {
                    type: 'geojson',
                    data: objCluster,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                });

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'tracks',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': 'transparent',
                        'circle-radius': 40,
                    },
                });

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'tracks',
                    filter: ['has', 'point_count'],
                });

                map.current.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'tracks',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        "icon-image": "custom-pin",
                        "icon-size": 0.4,
                    },
                });

                map.current.on('click', 'clusters', (e) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters'],
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('tracks').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return;

                        map.current.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom,
                        });
                    });
                });

                map.current.on('click', 'unclustered-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const sensor = e.features[0].properties;
                    const sensorData = JSON.parse(sensor.sensor);
                    console.log("sensorData", sensorData)
                    var popup = new mapboxgl.Popup({ anchor: 'bottom', closeOnClick: true })
                        .setLngLat(coordinates)
                        .setHTML(
                            `<div class='marker-wrapper'>
                                 <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-title"">${sensor != null && sensor.nodeName != "" ? sensor.nodeName : "-"}</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-item-box">
                                            <div class="marker-header-item-value">${sensorData != null && sensorData.state != "" ? sensorData.state == "1" ? "online" : "Offline" : "-"}</div>
                                            <div class='marker-item-value marker-item-circle' style="background-color: ${getAssetStatusColor(sensorData.state)};"></div>
                                        </div>
                                        <div class="marker-header-item-name">Status</div>
                                    </div>
                                     <div class="marker-header-item">
                                        <div class="marker-header-item-value">${sensorData != null && sensorData.timestamp != "" ? moment(sensorData.timestamp).format("MM/DD/YY hh:mmA") : "-"}</div>
                                        <div class="marker-header-item-name" >Updated</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${sensor != null && sensor.nodeType != "" ? sensor.nodeType : "-"}</div>
                                        <div class="marker-header-item-name">Type</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${sensor != null && sensor.events != null ? sensor.events : "--"}</div>
                                        <div class="marker-header-item-name">Events</div>
                                    </div>
                                </div>
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${sensorData != null && sensorData.battery != "" ? `${sensorData.battery}%` : "-"}</div>
                                        <div class="marker-header-item-name">Batt.</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${sensorData != null && sensorData.temperature != "" ? `${sensorData.temperature} °F` : "-"}</div >
                                        <div class="marker-header-item-name">Temp</div>
                                    </div >
                                </div >
                                <div class='marker-header' style="margin-left: 16px;">
                                    <div class='marker-item'>
                                        <div class="marker-item-box">
                                            <div class='marker-header-item-value'>${sensor != null && sensor.sensorNumber != "" ? sensor.sensorNumber : "-"}</div>
                                        </div>
                                        <div class='marker-header-item-name'>ID</div>
                                    </div>
                                    <button type="button" class="marker-footer-btn"></button>
                                </div>
                            </div >`
                        )
                        .addTo(map.current);

                    popup.getElement().querySelector('.marker-footer-btn').addEventListener('click', function (e) {
                        e.stopPropagation();
                        navigate(`/node-listing/detail`, { state: { id: sensor._id, isFromMap: true } })
                    });
                });

                const markers = {};
                let markersOnScreen = {};

                function updateMarkers() {
                    const newMarkers = {};
                    const features = map.current.querySourceFeatures('tracks');

                    for (const feature of features) {
                        const coords = feature.geometry.coordinates;
                        const props = feature.properties;
                        if (!props.cluster) continue;
                        const id = props.cluster_id;

                        let marker = markers[id];
                        if (!marker) {
                            const el = createCluster(props);
                            marker = markers[id] = new mapboxgl.Marker({
                                element: el,
                            }).setLngLat(coords);
                        }
                        newMarkers[id] = marker;

                        if (!markersOnScreen[id]) marker.addTo(map.current);
                    }

                    for (const id in markersOnScreen) {
                        if (!newMarkers[id]) markersOnScreen[id].remove();
                    }
                    markersOnScreen = newMarkers;
                }

                map.current.on('render', () => {
                    if (!map.current.isSourceLoaded('tracks')) return;
                    updateMarkers();
                });

                map.current.on('move', () => {
                    setLng(map.current.getCenter().lng.toFixed(4));
                    setLat(map.current.getCenter().lat.toFixed(4));
                    setZoom(map.current.getZoom().toFixed(2));
                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });
        }
    };

    const handleStorageChange = useDebouncedCallback(() => {
        if (map.current) {
            map.current.remove();
            map.current = null
        }
        renderMap();
    }, 500);

    useEffect(() => {
        handleStorageChange()
    }, [nodeData, isOpenedSidebar, isFullMap]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                ...sx,
            }}
            ref={mapContainer}
        />
    );
};

import { FormControl, MenuItem, Select, Typography, Autocomplete, TextField } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';


export const DefaultSelectForm = ({ sx, label, helperText, helperTextColor, options, getOptionLabel, isOptionEqualToValue, defaultValue, name, disableClearable = true, ...otherProps }) => {

    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', alignItems: 'center', ...sx }}>
            <>
                {helperText && (
                    <Typography
                        color={helperTextColor || '#A8A8A7'}
                        fontSize={12}
                        sx={{
                            alignSelf: 'flex-start',
                        }}
                    >
                        {helperText}
                    </Typography>
                )}
                <Autocomplete
                    {...otherProps}
                    popupIcon={<ExpandMore style={{ color: '#262B40', marginRight: "10px" }} />}
                    autoSelect={false}
                    disableClearable={disableClearable}
                    value={defaultValue}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={(event, newValue) => {
                        otherProps.onChange && otherProps.onChange(event, newValue);
                    }}
                    style={{
                        width: '100%',
                        border: '1px solid #E2E2EA',
                        borderRadius: '6px',
                        boxSizing: 'border-box',
                        backgroundColor: 'white'
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...otherProps}
                            {...params}
                            label={label}
                            name={name}
                            variant='standard'
                            onKeyDown={(e) => { if (e.key !== 'Tab') { e.preventDefault(); } }}
                            InputLabelProps={{
                                ...params.InputProps,
                                shrink: true,
                                readOnly: true,
                                style: { color: "#A8A8A7" }
                            }}
                            InputProps={{
                                ...params.InputProps, disableUnderline: true
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    marginTop: 0,
                                },
                            }}
                            sx={{
                                width: '100%',
                                overflow: 'hidden',
                                borderRadius: '6px',
                                '.MuiFormLabel-root': {
                                    fontSize: 12,
                                    transform: 'unset',
                                    color: '#A8A8A7',
                                    position: 'static',
                                    lineHeight: '1.5',
                                    '&.Mui-disabled': {
                                        color: 'primary.main',
                                    },
                                },
                                '.MuiInput-input': {
                                    fontSize: 14,
                                    height: 20,
                                    py: 0,
                                    pr: 2,
                                    pl: 2,
                                },
                                '.MuiInputBase-root': {
                                    mt: 0,
                                    pl: "10px",
                                    py: 0,
                                    '&::before': {
                                        border: 'none',
                                    },
                                    '&.Mui-disabled': {
                                        '&::before': {
                                            display: 'none',
                                        },
                                    },
                                },
                                ...sx,
                            }}

                        />
                    )}
                />
            </>
        </FormControl>
    );
};

import { Box, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Tabs } from '../../../ui/Tabs/index.js';
import { Map } from './Map.jsx';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { TripsItem } from '../../../modules/Asset/Trips/TripsItem.jsx';
import { playTabs } from '../data/playTabs.jsx';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import api from '../../../lib/axios';
import { TripMap } from './TripMap.jsx';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { movementColumns } from './data/movement.jsx';
var moment = require("moment");

export const Trips = ({ assetData, trackerData }) => {
    let refChart = useRef(null)
    const [activeTab, setActiveTab] = useState("");
    const [arrayDirectionData, setArrayDirectionData] = useState([]);
    const [arrayTripDirectionData, setArrayTripDirectionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    // const [endDate, setEndDate] = useState(dayjs().add(1, 'day'));
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isOpenTripMap, setIsOpenTripMap] = useState(false);


    useEffect(() => {
        if (assetData != null) {
            //getDirection(startDate, endDate)
            getTrips(startDate, endDate)
            ///getChatTrips()
        }
    }, [assetData]);

    const getTrips = (startDate, endDate) => {
        setIsLoading(true);
        api.post('/apis/Assets/getDirection', { assetID: assetData._id, startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY') }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayDirectionData(resData)
            }
            setSelectedLocation(null)
            setActiveTab("")
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleStartDateChange = (date) => {
        console.log("Start date: ", date)
        const isStartDateAfterEndDate = date.isAfter(endDate);
        const isStartDateEqualToEndDate = date.isSame(endDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            console.log("startDate", startDate)
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setStartDate(date)
            getTrips(date, endDate);
        }
    };

    const handleEndDateChange = (date) => {
        console.log(date)

        const isEndDateAfterStartDate = date.isAfter(startDate);
        const isStartDateEqualToEndDate = date.isSame(startDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            setEndDate(date)
            getTrips(startDate, date);
        }
    };

    const handleChangeTab = (tab) => {
        if (isOpenTripMap) {
            setIsOpenTripMap(false)
            setSelectedLocation(null)
        }
        if (arrayDirectionData.length > 0) {
            setActiveTab(tab);
        }
    };

    const updateSelectedLocation = (data) => {
        if (selectedLocation != null && selectedLocation._id == data._id) {
            setSelectedLocation(null)
            setIsOpenTripMap(false)
        } else {
            setSelectedLocation(data)
            //  getDirection(data.startTime, data.endTime)
            setIsOpenTripMap(true)
        }

        setActiveTab("")
    }

    function calculateAvg(status) {
        let totalDurationInSeconds = 0;
        let count = 0;

        arrayDirectionData.forEach((item) => {
            if (item.status === status) {
                totalDurationInSeconds += item.durationInSeconds;
                count++;
            }
        });

        const durationInDays = moment(endDate.toDate()).diff(moment(startDate.toDate()), 'days') + 1;

        let avgDurationInSeconds = totalDurationInSeconds;
        if (durationInDays > 0) {
            avgDurationInSeconds = totalDurationInSeconds / durationInDays;
        }

        const avgDuration = moment.duration(avgDurationInSeconds, 'seconds');
        const hours = Math.floor(avgDuration.asHours());
        const minutes = avgDuration.minutes();

        if (hours >= 24) {
            return `24.00`;
        }

        return `${hours}.${minutes.toString().padStart(2, '0')}`;
    }

    function getHeatMap(arrayData) {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == "chartdiv") {
                root.dispose();
            }
        });
        let root = am5.Root.new("chartdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",

            layout: root.verticalLayout
        }));

        var yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });

        yRenderer.grid.template.set("visible", false);

        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: "weekday"
        }));

        var xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite: true,
            minorGridEnabled: true
        });

        xRenderer.grid.template.set("visible", false);

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "hour"
        }));

        // Adjust xAxis label position
        xRenderer.labels.template.setAll({
            rotation: 0, // No rotation
            centerY: am5.p100, // Position at the bottom of the cell
            dy: 65, // Additional vertical offset to move below the cell
            fontSize: 12,
        });


        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "hour",
            categoryYField: "weekday",
            valueField: "value"
        }));

        series.columns.template.setAll({
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100),
            cornerRadiusBL: 8,
            cornerRadiusBR: 8,
            cornerRadiusTL: 8,
            cornerRadiusTR: 8
        });

        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color(0xFEBC2E),
            max: am5.color(0x1E75FF),
            dataField: "value",
            key: "fill",
            customFunction: function (target, minValue, maxValue, value) {
                if (value == 0) {
                    target.set("fill", am5.color(0xFEBC2E));
                } else if (value == 1) {
                    target.set("fill", am5.color(0x1E75FF));
                } else {
                    target.set("fill", am5.color(0xF6F8FA));
                }
            }
        }]);

        const data = [
            { weekday: '', hour: '12am', value: 0 },
            { weekday: '', hour: '1am', value: 0 },
            { weekday: '', hour: '2am', value: 0 },
            { weekday: '', hour: '3am', value: 0 },
            { weekday: '', hour: '4am', value: 0 },
            { weekday: '', hour: '5am', value: 0 },
            { weekday: '', hour: '6am', value: 0 },
            { weekday: '', hour: '7am', value: 0 },
            { weekday: '', hour: '8am', value: 0 },
            { weekday: '', hour: '9am', value: 0 },
            { weekday: '', hour: '10am', value: 0 },
            { weekday: '', hour: '11am', value: 0 },
            { weekday: '', hour: '12pm', value: 0 },
            { weekday: '', hour: '1pm', value: 1 },
            { weekday: '', hour: '2pm', value: 0 },
            { weekday: '', hour: '3pm', value: 0 },
            { weekday: '', hour: '4pm', value: 0 },
            { weekday: '', hour: '5pm', value: 0 },
            { weekday: '', hour: '6pm', value: 0 },
            { weekday: '', hour: '7pm', value: 0 },
            { weekday: '', hour: '8pm', value: 0 },
            { weekday: '', hour: '9pm', value: 0 },
            { weekday: '', hour: '10pm', value: 0 },
            { weekday: '', hour: '11pm', value: 0 },
        ];

        series.data.setAll(arrayData);

        yAxis.data.setAll([
            { weekday: "" }
        ]);

        xAxis.data.setAll([
            { hour: "12am" },
            { hour: "1am" },
            { hour: "2am" },
            { hour: "3am" },
            { hour: "4am" },
            { hour: "5am" },
            { hour: "6am" },
            { hour: "7am" },
            { hour: "8am" },
            { hour: "9am" },
            { hour: "10am" },
            { hour: "11am" },
            { hour: "12pm" },
            { hour: "1pm" },
            { hour: "2pm" },
            { hour: "3pm" },
            { hour: "4pm" },
            { hour: "5pm" },
            { hour: "6pm" },
            { hour: "7pm" },
            { hour: "8pm" },
            { hour: "9pm" },
            { hour: "10pm" },
            { hour: "11pm" }
        ]);

        // Make stuff animate on load
        series.appear(200);
        chart.appear(1000, 100);
        refChart = root;
    }

    return (
        <>
            <Stack mb={2} direction={'row'} gap={6} alignItems={'center'}>
                <Stack direction={'row'} gap={2}>
                    <Datepicker initialDate={startDate} handleDateChange={handleStartDateChange} />
                    <Datepicker initialDate={endDate} handleDateChange={handleEndDateChange} />
                </Stack>
                <Stack direction={'row'}>
                    <Tabs data={playTabs} activeTab={activeTab} setActiveTab={handleChangeTab} activeColor={'#151D26'} inline />
                </Stack>
            </Stack>
            <Stack overflow={'hidden'} height={'calc(100%)'} width={'calc(100% + 24px)'}>
                <Stack
                    flex={1}
                    direction={'row'}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 8px 8px 8px',
                        // borderRight: 'none',
                        // borderBottom: 'none',
                    }}
                    mb={1}
                    height={"100%"}
                >
                    <Box
                        width={'50%'}
                        flexShrink={0}
                        borderRight={'1px solid #E2E2EA'}
                        display={"auto"}
                        height={'calc(100vh - 292px)'}
                        sx={{
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                width: '4px',
                                bgcolor: 'grey.400',
                            },
                        }}>
                        <Stack sx={{ height: '100%' }}>
                            <DefaultDataGrid columns={movementColumns(selectedLocation)} rows={arrayDirectionData} onRowClick={(e) => { updateSelectedLocation(e.row) }} />
                        </Stack>
                    </Box>
                    <Map arrayDirectionData={isOpenTripMap ? [selectedLocation] : arrayDirectionData} selectedData={selectedLocation} activeAnimation={activeTab} />
                    {/* {!isOpenTripMap ? <Map arrayDirectionData={arrayDirectionData} selectedData={selectedLocation} activeAnimation={activeTab} /> :
                        <TripMap
                            arrayDirectionData={arrayTripDirectionData}
                            selectedData={selectedLocation}
                            activeAnimation={activeTab}
                            onClose={() => {
                                setIsOpenTripMap(false)
                                setSelectedLocation(null)
                            }} />} */}
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

import mapboxgl from 'mapbox-gl';
import { Box, Button } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useRecoilValue } from 'recoil';
import { sidebarOpened } from '../../../core/store/index.js';
import { Tabs } from '../../../ui/Tabs/index.js';
import { tabs } from '../../../components/Map/data/tabs.js';

mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnbmFsdHJhY2tzIiwiYSI6ImNsd3Rjd3A1bTAyYnQybnBuNXZnajJ2YjEifQ.6DdrfKM2ke64TpX-Igogdg';

export const TripMap = memo(({ sx, arrayDirectionData, selectedData, activeAnimation }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const isOpenedSidebar = useRecoilValue(sidebarOpened);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [zoom, setZoom] = useState(14);
    const [animationId, setAnimationId] = useState(null);

    const handleChangeTab = (value) => {
        setActiveTab(value);
    };

    const drawPoints = () => {
        // Remove existing markers
        document.querySelectorAll('.map-marker').forEach(marker => marker.remove());
    
        arrayDirectionData.forEach(data => {
            if (data.positionLongitude != null && data.positionLatitude != null) {
                const el = document.createElement('div');
                el.className = 'map-marker';
    
                new mapboxgl.Marker(el)
                    .setLngLat([data.positionLongitude, data.positionLatitude])
                    .addTo(map.current);
            }
        });
    
        if (arrayDirectionData.length > 0) {
            const firstValidWaypoint = arrayDirectionData.find(data => data.positionLongitude != null && data.positionLatitude != null);
            if (firstValidWaypoint) {
                map.current.setCenter([firstValidWaypoint.positionLongitude, firstValidWaypoint.positionLatitude]);
            }
        }
    };
    

    const drawPoints2 = () => {
        const waypoints = arrayDirectionData.map(data => {
            if (data.positionLongitude != null && data.positionLatitude != null) {
                return {
                    type: "Feature",
                    properties: {
                        color: '#4ea612',
                        description: `<strong>Location</strong><p>${data.positionLongitude}-${data.positionLatitude}</p>`
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [data.positionLongitude, data.positionLatitude],
                    },
                }
            } else {
                return null
            }
        }).filter(waypoint => waypoint !== null);

        if (map.current.getSource('points')) {
            map.current.getSource('points').setData({
                type: "FeatureCollection",
                features: waypoints,
            });
        } else {
            map.current.addSource("points", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: waypoints,
                },
            });

            map.current.addLayer({
                id: "points",
                type: "circle",
                source: "points",
                paint: {
                    "circle-radius": 6,
                    "circle-color": ["get", "color"]
                },
            });
        }

        if (waypoints.length > 0) {
            map.current.setCenter(waypoints[0].geometry.coordinates);
        }
    };

    const animateLine = () => {
        const coordinates = arrayDirectionData.map(data => {
            if (data.positionLongitude != null && data.positionLatitude != null) {
                return [data.positionLongitude, data.positionLatitude]
            } else {
                return null
            }
        }).filter(coord => coord !== null);

        if (map.current.getSource('line')) {
            map.current.getSource('line').setData({
                'type': 'FeatureCollection',
                'features': [{
                    'type': 'Feature',
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': []
                    }
                }]
            });
        } else {
            map.current.addSource('line', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': []
                        }
                    }]
                }
            });

            map.current.addLayer({
                'id': 'line-animation',
                'type': 'line',
                'source': 'line',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#4ea612',
                    'line-width': 5,
                    'line-opacity': 0.8
                }
            });
        }

        const interpolate = (start, end, t) => {
            return [
                start[0] + (end[0] - start[0]) * t,
                start[1] + (end[1] - start[1]) * t
            ];
        };

        let currentIndex = 0;
        let t = 0;
        const speed = 0.009; // Adjust speed of animation.

        const animate = () => {
            if (currentIndex < coordinates.length - 1) {
                const source = map.current.getSource('line');
                console.log("INSTART", source)
                if (source) {
                    const data = source._data;
                    const start = coordinates[currentIndex];
                    const end = coordinates[currentIndex + 1];
                    const interpolatedPoint = interpolate(start, end, t);

                    if (t < 1) {
                        t += speed;
                    } else {
                        t = 0;
                        currentIndex++;
                    }

                    data.features[0].geometry.coordinates.push(interpolatedPoint);
                    source.setData(data);
                    setAnimationId(requestAnimationFrame(animate));
                }
            }
        };

        animate();
    };
    const rewindLine = () => {
        const source = map.current.getSource('line');
        if (source) {
            const data = source._data;
            const speed = 0.01; // Adjust speed of animation.

            const animateRewind = () => {
                if (data.features[0].geometry.coordinates.length > 0) {
                    data.features[0].geometry.coordinates.pop();
                    source.setData(data);
                    setAnimationId(requestAnimationFrame(animateRewind));
                }
            };

            animateRewind();
        }

    };

    const handleStartAnimation = () => {
        if (animationId) cancelAnimationFrame(animationId);

        animateLine();
    };

    const handleRewindAnimation = () => {
        if (animationId) cancelAnimationFrame(animationId);
        rewindLine();
    };

    const renderMap = () => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: `mapbox://styles/signaltracks/${activeTab}`,
            center: [-74.01521529520066, 40.73760113154407],
            zoom: zoom,
        });
        map.current.addControl(new mapboxgl.NavigationControl());

        map.current.on('move', () => {
            setZoom(map.current.getZoom().toFixed(2));
        });

        map.current.on('load', () => {
            drawPoints();
        });
    };

    const handleStorageChange = useDebouncedCallback(() => {
        if (map.current) {
            map.current.remove();
            map.current = null;
        }
        renderMap();
    }, 500);


    useEffect(() => {
        handleStorageChange();
    }, [isOpenedSidebar, arrayDirectionData, selectedData, activeTab]);


    useEffect(() => {
        if (activeAnimation == "play") {
            handleStartAnimation();
        } else if (activeAnimation == "rewind") {
            handleRewindAnimation();
        }
        console.log("activeAnimation", activeAnimation)
    }, [activeAnimation]);

    return (
        <Box position={'relative'} width={'100%'} height={'100%'}>
            <Box position={'absolute'} left={12} top={12} zIndex={3}>
                <Tabs data={tabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline />
                {/* <Button variant="contained" color="primary" onClick={handleButtonClick} style={{ marginTop: '10px' }}>
                    Draw Line
                </Button> */}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    ...sx,
                }}
                ref={mapContainer}
            />
        </Box>
    );
});
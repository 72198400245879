import { Box, Stack, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tag } from '../../../ui/Tag/index.js';
import { ColorStatus } from '../../../ui/ColorStatus/ColorStatus.jsx';
import moment from 'moment';

export const columns = (calculateDuration, onAssetClick) => [
    {
        field: 'deviceSerialNumber',
        headerName: 'Tracker',
        width: 130,
        renderCell: (value) => (
            <Tag text={value.row.deviceSerialNumber} borderColor={"green.main"} />
        ),
    },
    {
        field: 'assignedAsset',
        headerName: 'Assigned Asset',
        width: 150,
        renderCell: (value) => {
            return (
                value.row.asset ? <Typography fontSize={13} color={'secondary.main'} onClick={(e) => {
                    e.stopPropagation()
                    // onAssetClick(value.row.asset)
                }}>
                    {value.row.asset.assetName || "-"}
                </Typography> : <Typography fontSize={13}>
                    {'None'}
                </Typography>
            )
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: (value) => {
            const borderColor = value.row.asset ? "#0A00FF" : "green.main"
            const text = value.row.asset ? "Assigned" : "Available"
            const textColor = value.row.asset ? "#0A00FF" : ""
            return (
                <Box
                    sx={{
                        pl: 2,
                        pr: 2,
                        border: '1px solid',
                        borderColor: borderColor,
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center'
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} >
                        <Typography fontSize={12}>{text}</Typography>

                    </Stack>
                </Box>
            );
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.type ? value.row.type : '-'}
            </Typography>
        ),
    },
    {
        field: 'loadedVoltage',
        headerName: 'Battery (v)',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.loadedVoltage ? value.row.loadedVoltage : '-'}
            </Typography>
        ),
    },
    {
        field: 'temperature',
        headerName: 'Device Temp',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.temperature ? value.row.temperature : '-'}
            </Typography>
        ),
    },
    {
        field: 'timestamp',
        headerName: 'Last Update',
        width: 200,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{value ? moment(value).format("MMMM DD, YYYY h:mmA") : "-"}</Typography>
        )
    },
    {
        field: 'arrowIcon',
        headerName: '',
        flex: 1,
        renderCell: () => (
            <Stack direction={'row'} justifyContent={'flex-end'} pr={5} alignItems={'center'} width={'100%'} gap={1}>
                <ArrowForwardIos
                    sx={{
                        color: 'Black',
                        width: 18,
                        height: 18,
                    }}
                />
                <Typography color={'Black'} fontSize={14}>
                    Details
                </Typography>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const rows = [
    {
        id: 1,
        device: {
            text: '808578',
            borderColor: 'green.main',
        },
        assignedAsset: 'TR-500-14A',
        status: {
            text: 'Assigned',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        type: 'YabbyEdge4-G',
        battery: '5.167',
        deviceTemp: '68.5 °F',
        lastUpdate: '11 minutes ago',
    },
    {
        id: 2,
        device: {
            text: '808578',
            borderColor: 'green.main',
        },
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        battery: '5.167',
        deviceTemp: '68.5 °F',
        lastUpdate: '5 hours ago',
    },
    {
        id: 3,
        device: {
            text: '808578',
            borderColor: 'green.main',
        },
        assignedAsset: 'TR-500-14A',
        status: {
            text: 'Assigned',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        type: 'YabbyEdge4-G',
        battery: '5.167',
        deviceTemp: '68.5 °F',
        lastUpdate: '5 seconds ago',
    },
    {
        id: 4,
        device: {
            text: '808578',
            borderColor: 'green.main',
        },
        assignedAsset: 'TR-500-14A',
        status: {
            text: 'Assigned',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        type: 'YabbyEdge4-G',
        battery: '5.167',
        deviceTemp: '68.5 °F',
        lastUpdate: '45 minutes ago',
    },
    {
        id: 5,
        device: {
            text: '808578',
            borderColor: 'green.main',
        },
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        battery: '5.167',
        deviceTemp: '68.5 °F',
        lastUpdate: '12 hours ago',
    },
];

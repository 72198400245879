import { Box, Stack } from "@mui/material"
import React, { useState, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const GraphData = () => {

    useEffect(() => {
        LineChart()
    }, []);

    function LineChart() {
        console.log("chart called")
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == "linechartdiv") {
                root.dispose();
            }
        });

        let root = am5.Root.new('linechartdiv');
        console.log("root initialized:", root);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        root.dateFormatter.setAll({
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["valueX"]
        });

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));

        let easing = am5.ease.linear;

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.1,
            groupData: false,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 70
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.2,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        // let fieldValue = arraySensorDataType.find(obj => obj.id == selectedSensor)?.field;
        // console.log("field ", fieldValue);

        xAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });
        
        yAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            minBulletDistance: 10,
            connect: false,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "timestamp",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueX}: {valueY}"
            })
        }));

        series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
        });

        series.strokes.template.setAll({
            strokeWidth: 2
        });

        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["timestamp"]
        });

        var data = [{
            "timestamp": new Date(2021, 0, 1).getTime(),
            "value": 1000
        }, {
            "timestamp": new Date(2021, 0, 2).getTime(),
            "value": 800
        }, {
            "timestamp": new Date(2021, 0, 3).getTime(),
            "value": 700
        }, {
            "timestamp": new Date(2021, 0, 4).getTime(),
            "value": 900
        }];
        // arrayData.map((chartData) => {
        //     let obj = {
        //         "battery": parseFloat(chartData.battery),
        //         "temperature": parseFloat(chartData.temperature),
        //         "altitude": parseFloat(chartData.altitude),
        //         "speed": parseFloat(chartData.speed),
        //         "cellSignal": parseFloat(chartData.cellSignal),
        //         "timestamp": new Date(chartData.timestamp)
        //     };

        //     chartData.arraySecondaryData.forEach((secondaryData) => {
        //         obj[secondaryData.pin] = parseFloat(secondaryData.value);
        //     });
        //     data.push(obj);
        // });

        series.data.setAll(data);

        // series.bullets.push(function () {
        //     let circle = am5.Circle.new(root, {
        //         radius: 4,
        //         fill: root.interfaceColors.get("background"),
        //         stroke: series.get("fill"),
        //         strokeWidth: 2
        //     });

        //     return am5.Bullet.new(root, {
        //         sprite: circle
        //     });
        // });

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);
        chart.appear(1000, 100);
    }
    return (
        <>
            <Stack flex={1} px={"33px"} pt={1}>
                <Box
                    sx={{
                        height: '100%',
                    }}
                    id={'linechartdiv'}
                />
            </Stack>
        </>
    )
}
export const tabs = [
    {
        title: 'Node Setup',
        text: 'Name, Categories, Types, Groups',
        value: 'setup',
    },
    // {
    //     title: 'Asset Details',
    //     text: 'Specs, VIN, Model, US DOT, HAZMAT',
    //     value: 'asset',
    // },
    {
        title: 'Contact Details',
        text: 'Node Contact Name, Cell, Email',
        value: 'contact',
    },
    {
        title: 'Sensor Node',
        text: 'Device Serial, Status, Configuration',
        value: 'trackers',
    },
];

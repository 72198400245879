import { ExpandMore } from "@mui/icons-material"
import { Box, Stack, Typography, Backdrop, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import TimelineIcon from '../../../assets/icons/TimelineIcon.svg'
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg'
import TimelineTimePanel from "./TimelineTimePanel"
import { arrayDataPoints } from "./data/data"
import { CustomDatepicker } from "./CustomDatepicker"
import dayjs from "dayjs"
import api from '../../../lib/axios.js';
import { TimelineChart } from "./TimelineChart"
import moment from "moment"


export const TimeLine = ({ nodeData, sensorData, selectedDataID }) => {
    const [arrayInbound, setArrayInbound] = useState([])
    const [inboundPercentage, setInboundPercentage] = useState(-1)
    const [lastInboundDate, setLastInboundDate] = useState("")
    const [arrayEvents, setArrayEvents] = useState([])
    const [eventPercentage, setEventPercentage] = useState(-1)
    const [totalEvents, setTotalEvents] = useState(0)
    const [selectedDataPoints, setSelectedDataPoints] = useState([]);
    const [startDate, setStartDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);
    const [timelineOrder, setTimelineOrder] = useState(() => {
        const savedOrder = localStorage.getItem('timelineOrder');
        return savedOrder ? JSON.parse(savedOrder) : ["timePannel", "inbound", "events"];
    });

    useEffect(() => {
        localStorage.setItem('timelineOrder', JSON.stringify(timelineOrder));
    }, [timelineOrder]);

    useEffect(() => {
        getInBountData()
        getEventData()
    }, []);
    useEffect(() => {
        const handler = setTimeout(() => {
            getInBountData()
            getEventData()
        }, 1000);

        // Cleanup the timeout if startDate changes again before debounceDelay
        return () => {
            clearTimeout(handler);
        };
    }, [startDate]);
    const getInBountData = () => {

        setIsLoading(true)
        let url = '/apis/Nodes/getInboundTimelineData'
        if (sensorData.product == "4") {
            url = '/apis/RuleEvents/getInboundTimelineVibrarionData'
        }
        api.post(url, {
            "startDate": startDate.format('MM-DD-YYYY'),
            "endDate": startDate.format('MM-DD-YYYY'),
            "nodeID": selectedDataID
        }).then((res) => {
            if (res.data.success) {
                var data = res.data.data


                setArrayInbound(data)
                const lastObject = data.slice().reverse().find(item => item.state === "1");
                if (lastObject) {
                    setLastInboundDate("Last Data: " + moment(lastObject.timestamp).format("MM-DD-YYYY hh:mm a"))
                } else {
                    setLastInboundDate("Last Data: -")
                }
                if (dayjs(startDate).isSame(dayjs(), 'day')) {
                    const now = moment();
                    const hours = now.hours();
                    const minutes = now.minutes();
                    const seconds = now.seconds();

                    // Convert hours, minutes, and seconds into total seconds
                    const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
                    const totalSecondsInDay = 24 * 60 * 60;
                    const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
                    setInboundPercentage(percentageWidth)
                } else {
                    setInboundPercentage(-1)
                }
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getEventData = () => {


        api.post('/apis/RuleEvents/getEventTimelineData', {
            "startDate": startDate.format('MM-DD-YYYY'),
            "endDate": startDate.format('MM-DD-YYYY'),
            "nodeID": selectedDataID
        }).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                setArrayEvents(data.arrayData)
                setTotalEvents(data.totalEvents)
                if (dayjs(startDate).isSame(dayjs(), 'day')) {
                    const now = moment();
                    const hours = now.hours();
                    const minutes = now.minutes();
                    const seconds = now.seconds();

                    // Convert hours, minutes, and seconds into total seconds
                    const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
                    const totalSecondsInDay = 24 * 60 * 60;
                    const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
                    setEventPercentage(percentageWidth)
                } else {
                    setEventPercentage(-1)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleSelectAll = () => {
        if (selectedDataPoints.length === arrayDataPoints.length) {
            setSelectedDataPoints([]);
        } else {
            setSelectedDataPoints(arrayDataPoints.map((data) => data.id));
        }
    };

    const handleSelectSingle = (id) => {
        if (selectedDataPoints.includes(id)) {
            setSelectedDataPoints(selectedDataPoints.filter((dataId) => dataId !== id));
        } else {
            setSelectedDataPoints([...selectedDataPoints, id]);
        }
    };
    const handleStartDateChange = (date) => {
        setStartDate(date)
        console.log("Date", date)
        //getNotifications(date, endDate)
    };
    const handleNextDate = () => {
        setStartDate(startDate.add(1, 'day'));
        //getNotifications(date, endDate)
    };
    const handlePreviousDate = () => {
        setStartDate(startDate.add(-1, 'day'));
        //getNotifications(date, endDate)
    };
    const handleRefreshData = () => {
        getInBountData()
        getEventData()
    }

    const moveComponentUp = (index) => {
        if (index === 0) return;
        const newOrder = [...timelineOrder];
        [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
        setTimelineOrder(newOrder);
    };

    const moveComponentDown = (index) => {
        if (index === timelineOrder.length - 1) return;
        const newOrder = [...timelineOrder];
        [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];
        setTimelineOrder(newOrder);
    };

    const renderComponent = (component, index) => {
        switch (component) {
            case "timePannel":
                return (

                    <Box
                        key="timePannel"
                        sx={{
                            bgcolor: '#FFFFFF',
                            borderRadius: '3.5px',
                            border: '1px solid #E6E7E8',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: '16px'
                        }}
                    >
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack direction={'row'} py={'18px'} alignItems={'center'} gap={'25px'} pl={'25px'}>
                                <Typography fontFamily={'Roboto'} fontWeight={500} fontSize={15} color={'#051219'}>{startDate.format('MMMM DD, YYYY')}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} mr={'24px'} >
                                <Box
                                    sx={{
                                        height: '13px',
                                        width: '10px',
                                        opacity: index === 0 ? 0.5 : 1,
                                        cursor: index === 0 ? 'not-allowed' : 'pointer',
                                    }}
                                    component={'img'}
                                    onClick={index > 0 ? () => moveComponentUp(index) : null}
                                    src={ArrowUpIcon}
                                ></Box>
                                <Box
                                    sx={{
                                        height: '13px',
                                        width: '10px',
                                        ml: '18px',
                                        mr: '11px',
                                        opacity: (index === (timelineOrder.length - 1)) ? 0.5 : 1,
                                        cursor: (index === (timelineOrder.length - 1)) ? 'not-allowed' : 'pointer',
                                    }}
                                    component={'img'}
                                    onClick={index != (timelineOrder.length - 1) ? () => moveComponentDown(index) : null}
                                    src={ArrowDownIcon}></Box>
                            </Stack>
                        </Stack>
                        <Box width={'100%'} height={'2px'} bgcolor={"#E6E7E8"} />
                        <TimelineTimePanel />
                    </Box>
                );
            case "inbound":
                return (
                    <TimelineChart
                        key="inbound"
                        timelineOrder={timelineOrder}
                        index={index}
                        onUp={() => moveComponentUp(index)}
                        onDown={() => moveComponentDown(index)}
                        arrayData={arrayInbound} totalPercentage={inboundPercentage} title="Inbound Telemetry" subTitle={lastInboundDate} />
                );
            case "events":
                return (
                    <TimelineChart
                        key="events"
                        timelineOrder={timelineOrder}
                        index={index}
                        onUp={() => moveComponentUp(index)}
                        onDown={() => moveComponentDown(index)}
                        arrayData={arrayEvents}
                        totalPercentage={eventPercentage}
                        title="Events"
                        subTitle={"Events: " + totalEvents} />
                );
            default:
                return null;
        }
    };
    return (
        <Stack sx={{
            height: '100%', width: '100%',
            flexGrow: 1,
            overflowY: 'auto',
            pr: '0px',
            '&::-webkit-scrollbar': {
                width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
                width: '4px',
                backgroundColor: '#bdbdbd',
            },
        }}>

            < Stack direction={'row'} width={'100%'} gap={'9px'} >
                <Stack direction={'row'}>
                    <Box
                        sx={{
                            height: '35px',
                            width: '39px',
                            bgcolor: '#FFFFFF',
                            borderRadius: '3.5px 0 0 3.5px',
                            border: '1px solid #E6E7E8',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            handlePreviousDate()
                        }}
                    >
                        <ExpandMore sx={{ transform: "rotate(90deg)" }} />
                    </Box>
                    <CustomDatepicker initialDate={startDate} handleDateChange={handleStartDateChange} />
                    <Box
                        sx={{
                            height: '35px',
                            width: '39px',
                            bgcolor: '#FFFFFF',
                            borderRadius: ' 0 3.5px 3.5px 0',
                            border: '1px solid #E6E7E8',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            handleNextDate()
                        }}
                    >
                        <ExpandMore sx={{ transform: "rotate(-90deg)" }} />
                    </Box>
                </Stack>
                <Box
                    sx={{
                        height: '35px',
                        width: '39px',
                        bgcolor: '#FFFFFF',
                        borderRadius: '3.5px',
                        border: '1px solid #E6E7E8',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleRefreshData}
                >
                    <Box component={'img'} src={TimelineIcon}></Box>
                </Box>
                {/* <AllDataPoint>
                    {arrayDataPoints.length > 0 && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedDataPoints.length === arrayDataPoints.length}
                                    onChange={handleSelectAll}
                                />
                            }
                            label="All Data Points"
                        />
                    )}
                    {arrayDataPoints.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={selectedDataPoints.includes(item.id)}
                                    onChange={() => handleSelectSingle(item.id)}
                                />
                            }
                            label={item.name}
                        />
                    ))}
                </AllDataPoint> */}
                <Stack width={'102px'}>
                </Stack>
            </Stack >


            {timelineOrder.map((component, index) => renderComponent(component, index))}



            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    )
}
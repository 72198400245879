import { ButtonBase, Stack, Typography } from '@mui/material';
import { Tag } from '../../../ui/Tag/index.js';
import moment from 'moment';

export const trackerColumns = (handleAssignedTracker) => [
    {
        field: 'deviceSerialNumber',
        headerName: 'Device',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.deviceSerialNumber ? value.row.deviceSerialNumber : '-'}
            </Typography>
        ),
    },
    {
        field: 'assignedAsset',
        headerName: 'Assigned Asset',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.asset && value.row.asset.assetName ? value.row.asset.assetName : 'None'}
            </Typography>
        ),
    },
    {
        field: 'asset',
        headerName: 'Status',
        width: 130,
        renderCell: (value) => {
            var assign = value.row.asset != null
            const borderColor = assign ? "#0A00FF" : "green.main"
            const text = assign ? "Assigned" : "Available"
            const textColor = assign ? "#0A00FF" : ""
            return (
                <Tag textColor={textColor} text={text} borderColor={borderColor} />
            )
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 100,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.type ? value.row.type : 'None'}
            </Typography>
        ),
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 130,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.role ? value.row.role : 'None'}
            </Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Billing',
        width: 100,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.status ? value.row.status == "1" ? "Active" : "Inactive" : 'None'}
            </Typography>
        ),
    },
    {
        field: 'timestamp',
        headerName: 'Last Update',
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{(value == null || value == "") ? "None" : calculateDuration(value, moment().format("MM/DD/YY hh:mm a"))}</Typography>
        )
    },
    {
        field: 'assignDevice',
        headerName: '',
        width: 200,
        renderCell: (params) => (
            <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={1} justifyContent={'flex-end'} pr={3}>
                {params.row.asset == null && <ButtonBase
                    sx={{
                        height: 30,
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        bgcolor: 'secondary.main',
                        borderRadius: '6px',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleAssignedTracker(params.row)
                    }}
                >
                    <Typography fontSize={14} color={'common.white'}>
                        Assign Device
                    </Typography>
                </ButtonBase>}
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const assignedTrackerColumns = (handleUnassignedTracker, assetName) => [
    {
        field: 'deviceSerialNumber',
        headerName: 'Device',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.deviceSerialNumber ? value.row.deviceSerialNumber : '-'}
            </Typography>
        ),
    },
    {
        field: 'assignedAsset',
        headerName: 'Assigned Asset',
        width: 150,
        renderCell: (value) => (
            <>
                <Stack>
                </Stack>
                <Typography fontSize={13}>
                    {assetName ? assetName : "None"}
                </Typography>
            </>
        ),
    },
    {
        field: 'asset',
        headerName: 'Status',
        width: 130,
        renderCell: (value) => {
            const borderColor = "#0A00FF"
            const text = "Assigned"
            const textColor = "#0A00FF"
            return (
                <Tag textColor={textColor} text={text} borderColor={borderColor} />
            )
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 100,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.type ? value.row.type : 'None'}
            </Typography>
        ),
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 130,
        // renderCell: (value) => {
        //     const borderColor = value.row.role == "1" ? "#0A00FF" : "#707070"
        //     const text = value.row.role == "1" ? "Admin" : "Standard"
        //     const textColor = value.row.role == "1" ? "#0A00FF" : ""
        //     return (
        //         <Tag textColor={textColor} text={text} borderColor={borderColor} />
        //     )
        // }
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.role ? value.row.role : 'None'}
            </Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Billing',
        width: 100,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.status ? value.row.status == "1" ? "Active" : "Inactive" : 'None'}
            </Typography>
        ),
    },
    {
        field: 'timestamp',
        headerName: 'Last Update',
        flex: 1,
        renderCell: (value) => (
            <Typography fontSize={13}>{(value == null || value == "") ? "None" : calculateDuration(value, moment().format("MM/DD/YY hh:mm a"))}</Typography>
        ),
    },
    {
        field: 'assignDevice',
        headerName: '',
        width: 200,
        renderCell: (params) => (
            <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={1} justifyContent={'flex-end'} pr={3}>
                <ButtonBase
                    sx={{
                        height: 30,
                        px: 2,
                        py: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        bgcolor: '#151D26',
                        borderRadius: '6px',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleUnassignedTracker(params.row)
                    }}
                >
                    <Typography fontSize={14} color={'common.white'}>
                        Unassign Device
                    </Typography>
                </ButtonBase>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const trackersRows = [
    {
        id: 1,
        deviceSerialNumber: '112211',
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        role: {
            text: 'None',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        billing: 'None',
        timestamp: '10 minutes ago',
    },
    {
        id: 2,
        deviceSerialNumber: '113344',
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        role: {
            text: 'None',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        billing: 'None',
        timestamp: '10 minutes ago',
    },
];

function calculateDuration(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the duration in milliseconds
    const durationMs = end - start;

    // Convert durationMs to days, hours, minutes, and seconds
    const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
    const seconds = Math.floor((durationMs / 1000) % 60);
    // Build the duration string
    let durationString = "";
    if (days > 0) {
        durationString += days + (days === 1 ? " Day " : " Days ");
    }
    if (hours > 0) {
        durationString += hours + (hours === 1 ? " hr " : " hrs ");
    }
    if (minutes > 0) {
        durationString += minutes + (minutes === 1 ? " min " : " mins ");
    }
    if (durationString.trim() === "") {
        durationString = "0 mins";
    }
    return durationString.trim();
}

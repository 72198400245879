import { Box, ButtonBase, Grid, Stack, Typography, Backdrop, CircularProgress, IconButton, Checkbox } from '@mui/material';
import { AccessTimeFilled, Add } from '@mui/icons-material';
import React, { useState, useEffect, createRef } from 'react';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { DefaultToggle } from '../../../ui/DefaultToggle/DefaultToggle.jsx';
import { Tabs } from '../../../ui/Tabs/index.js';
import { toolsTabs } from '../../../modules/Asset/data/toolsTabs.jsx';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/index.js';
import { ConfirmAlert } from '../../../customControls/confirmAlert.js';
import api from '../../../lib/axios.js';
import { Map } from './Map.jsx';
import moment from 'moment'
import { enqueueSnackbar } from 'notistack';
import { documentColumns, geoFenceColumns, sharingColumns } from '../data/locationMap.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { status } from 'nprogress';
import { trackersTabs } from '../../../components/Map/data/tabs.js';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const LocationsMap = ({ assetData, trackerData, handleChangeTrackersTab, activeTrackerTab, getData }) => {
    const [activeTab, setActiveTab] = useState(toolsTabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [sharingDeleteConfirmationShouldOpen, setSharingDeleteConfirmationShouldOpen] = useState(false);
    const [documentDeleteConfirmationShouldOpen, setDocumentDeleteConfirmationShouldOpen] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [sharingArrayData, setSharingArrayData] = useState([]);
    const [selectedDataId, setSelectedDataId] = useState("");
    const [selectedSharingDataId, setSelectedSharingDataId] = useState("");
    const [selectedDocumentID, setSelectedDocumentID] = useState("");
    const [addGeoFence, setAddGeoFence] = useState(false);
    const [showGeo, setShowGeo] = useState(false);
    const [showMoreDetail, setShowMoreDetail] = useState(false);
    const [addSharing, setAddSharing] = useState(false);
    const [showSharing, setShowSharing] = useState(false);
    const [showDocument, setShowDocument] = useState(false);
    const [drawData, setDrawData] = useState(null);
    const [selectedPoligon, setSelectedPoligon] = useState(null);
    const [documentArrayData, setDocumentArrayData] = useState([]);
    const [device1Activebilling, setDevice1Activebilling] = useState(false);
    const [device2Activebilling, setDevice2Activebilling] = useState(false);
    const fileRef = createRef()
    const formik = useFormik({
        initialValues: {
            submit: null,
            fenceName: '',
            isAlert: false,
            email: ''
        },
        validationSchema: Yup.object().shape({
            fenceName: Yup.string().required('Fence name is required'),
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    fenceName: values.fenceName,
                    isAlert: values.isAlert,
                    email: values.email,
                }
                formik.setValues(dataValues)
                if (drawData == null) {
                    enqueueSnackbar("Please draw poligon to add geofece", {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                } else {
                    setIsLoading(true)
                    var data = await save(values.fenceName, values.isAlert, values.email);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        getPoligon()
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                }

                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })

    const sharingformik = useFormik({
        initialValues: {
            submit: null,
            email: '',
            status: false
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    email: values.email,
                    status: values.status,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                var data = await apiCalling(values.email, values.status);
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    setStatus({ success: true });
                    setSubmitting(false);
                } else {
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })

    useEffect(() => {
        if (assetData && assetData._id != null) {
            getPoligon()
            getSharingList()
        }
    }, [assetData]);

    useEffect(() => {
        if (addGeoFence) {
            setAddGeoFence(true)
            hideShareLocation()
        }

        if (addSharing) {
            setAddSharing(true)
            hideGeoFence()
        }

    }, [addGeoFence, addSharing]);

    useEffect(() => {
        pieChart('chartdiv')
    }, []);

    const getPoligon = () => {
        setIsLoading(true)
        api.get('/apis/GeoFencings/byAsset/' + assetData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function save(name, isAlert, email) {
        let arrayData = []
        const data = drawData;
        if (data.features.length > 0) {
            if (selectedPoligon == null) {
                data.features.map(res => {
                    var feature = {
                        "email": email,
                        "notification": isAlert ? "1" : "0",
                        "name": name,
                        "region": {
                            "type": "Polygon",
                            "coordinates":
                                res.geometry.coordinates
                        },
                        "mode": activeTab,
                    }
                    arrayData.push(feature)
                })
                if (arrayData.length > 0) {
                    await addData(arrayData)
                }
            } else {
                var updatedData = data.features.find(obj => obj.id == selectedPoligon._id)
                if (updatedData) {
                    await updateData(name, updatedData, selectedPoligon, email, isAlert)
                }
            }
        }
    }

    async function addData(arrayData) {
        var data = {
            assetID: assetData._id,
            arrayData: arrayData
        }
        return await api.post('/apis/GeoFencings/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon added', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    async function updateData(name, feature, selectedPoligon, email, isAlert) {
        var data = {
            "name": name,
            "email": email,
            "notification": isAlert ? "1" : "0",
            "region": {
                "type": "Polygon",
                "coordinates":
                    feature.geometry.coordinates
            },
        }
        return await api.put('/apis/GeoFencings/' + selectedPoligon._id, data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("Put", resData);
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setSelectedPoligon(null)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function deleteRecord() {
        api.delete('/apis/GeoFencings/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Poligon deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setSelectedDataId("")
                getPoligon()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteRecord()
        } else {
            setSelectedDataId("")
        }
    }

    const onDeleteClick = (row) => {
        setSelectedDataId(row._id)
        setDeleteConfirmationShouldOpen(true)
    };

    const onEditClick = (row) => {
        setSelectedPoligon(row)
        var value = {
            fenceName: row.name,
            isAlert: row.notification == "1" ? true : false,
            email: row.email
        }
        formik.setValues(value)
        setAddGeoFence(true)
    };

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    const onDrawGeofence = (data) => {
        setDrawData(data)
    };

    const getSharingList = () => {
        setIsLoading(true)
        api.get('/apis/ShareLocations/byAsset/' + assetData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setSharingArrayData(resData)
            }
            console.log("ID", selectedSharingDataId);
            getImageData()
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function addSharingData(email, status) {
        var data = {
            email: email,
            assetID: assetData._id,
            status: status ? "1" : "0",
        }
        return await api.post('/apis/ShareLocations/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var value = {
                    submit: null,
                    email: "",
                    status: false
                }
                sharingformik.setValues(value)
                setAddSharing(false)

                enqueueSnackbar('Share location added', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getSharingList()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function updateSharLocation(email, isAlertStatus) {
        var data = {
            "email": email,
            "status": isAlertStatus ? "1" : "0",
        }
        return await api.put('/apis/ShareLocations/' + selectedSharingDataId, data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("Put", resData);
                var value = {
                    submit: null,
                    email: "",
                    status: false
                }
                sharingformik.setValues(value)
                setAddSharing(false)

                enqueueSnackbar('Share location updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getSharingList()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onSharingEditClick = (row) => {
        var value = {
            email: row.email,
            status: row.status == "1" ? true : false,
        }
        sharingformik.setValues(value)
        setSelectedSharingDataId(row._id)
        setAddSharing(true)
    };

    async function apiCalling(email, status) {
        console.log("ID", selectedSharingDataId);
        if (selectedSharingDataId != "") {
            await updateSharLocation(email, status)
        } else {
            await addSharingData(email, status)
        }
    }

    const onSharingDeleteClick = (row) => {
        setSelectedSharingDataId(row._id)
        setSharingDeleteConfirmationShouldOpen(true)
    };

    const onDocumentDeleteClick = (row) => {
        setSelectedDocumentID(row._id)
        setDocumentDeleteConfirmationShouldOpen(true)
    };


    function deleteDocumnet() {
        setIsLoading(true)
        api.delete('/apis/Images/' + selectedDocumentID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("File deleted", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                setSelectedDocumentID("")
                getImageData()
            } else {
                enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateDocumentConfirmAlert = (value) => {
        if (value) {
            deleteDocumnet()
        } else {
            setSelectedDocumentID("")
        }
    }

    function deleteSharingRecord() {
        api.delete('/apis/ShareLocations/' + selectedSharingDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Share location deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setSelectedSharingDataId("")
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
            getSharingList()
        }).catch(function (error) {
            console.log(error);
        });
    }


    const updateSharingConfirmAlert = (value) => {
        if (value) {
            deleteSharingRecord()
        } else {
            setSelectedDataId("")
        }
    }

    function hideGeoFence() {
        setShowGeo(false)

        setShowMoreDetail(false)
        setShowDocument(false)
        setDrawData(null)
        setAddGeoFence(false)
        setSelectedPoligon(null)
        var value = {
            fenceName: '',
            isAlert: false,
            email: null
        }
        formik.setValues(value)
    }

    function hideShareLocation() {
        setShowSharing(false)

        setShowMoreDetail(false)
        setShowDocument(false)
        setAddSharing(false)
        var value = {
            submit: null,
            email: "",
            status: false
        }
        sharingformik.setValues(value)
    }

    function hideDocument() {
        setShowSharing(false)
        setShowGeo(false)

        setShowDocument(false)
        setAddSharing(false)
        setAddGeoFence(false)
        var value = {
            submit: null,
            email: "",
            status: false
        }
        sharingformik.setValues(value)
        var value2 = {
            fenceName: '',
            isAlert: false,
            email: null
        }
        formik.setValues(value2)
    }

    function hideMoreDetail() {
        setShowSharing(false)
        setShowGeo(false)
        setShowDocument(false)
        setShowMoreDetail(false)
        setAddSharing(false)
        setAddGeoFence(false)
        var value = {
            submit: null,
            email: "",
            status: false
        }
        sharingformik.setValues(value)
        var value2 = {
            fenceName: '',
            isAlert: false,
            email: null
        }
        formik.setValues(value2)
    }

    function hideLocationDetail() {
        setShowSharing(false)
        setShowGeo(false)

        setShowMoreDetail(false)
        setAddSharing(false)
        setAddGeoFence(false)
        var value = {
            submit: null,
            email: "",
            status: false
        }
        sharingformik.setValues(value)
        var value2 = {
            fenceName: '',
            isAlert: false,
            email: null
        }
        formik.setValues(value2)
    }

    const pieChart = (chartID) => {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: 50,
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        series.get("colors").set("colors", [
            am5.color(0x1E75FF),
            am5.color(0xE6E7E8),
        ]);

        series.data.setAll([
            { sector: "", size: 95 },
            { sector: "", size: 5 },
        ]);
        label.set("text", "95%");

        // if (nodeData != null && nodeData.arrayGraph != null && sensorData != null && sensorData.arraySecondaryData != null) {
        //     var objGraph3 = nodeData.arrayGraph.find(obj => obj.graph == "3")
        //     if (objGraph3) {
        //         var objPin3 = sensorData.arraySecondaryData.find(obj => obj.pin == objGraph3.pin)
        //         if (objPin3) {
        //             series.get("colors").set("colors", [
        //                 am5.color(objGraph3.color),
        //                 am5.color(0xE6E7E8),
        //             ]);
        //             const maxValue = isNaN(parseFloat(objGraph3.maxValue)) ? 0 : parseFloat(objGraph3.maxValue);
        //             const selectedValue = isNaN(parseFloat(objPin3.value)) ? 0 : parseFloat(objPin3.value);
        //             const totalSize = (selectedValue / maxValue) * 100;
        //             console.log("totalSize3", totalSize)
        //             const remainingValue = 100 - totalSize;
        //             console.log("remainingValue", remainingValue)
        //             series.data.setAll([
        //                 { sector: objGraph3.graphName, size: totalSize },
        //                 { sector: "", size: remainingValue },
        //             ]);
        //             const integerValue = parseInt(totalSize)
        //             const percentageValue = objGraph3.showPercentage == "1" ? `${integerValue}%` : integerValue
        //             label.set("text", percentageValue);
        //         }
        //     }
        // }


        series.appear(1000, 100);
    }

    async function uploadFiles(file) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", file);

        formData.append("userName", localStorage.getItem("fullName"));
        api({
            method: "POST",
            url: '/apis/Images/uploadimage/' + assetData._id,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((res) => {
                if (res.data.success) {
                    enqueueSnackbar("File uploaded", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                    getImageData()
                } else {
                    enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                }
                setIsLoading(false)
            }).catch(function (error) {
                console.log(error);
            });
    }

    async function getImageData() {
        // setIsLoading(true)
        return await api.get('/apis/Images/byAsset/' + assetData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setDocumentArrayData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function changeStatus(status, id) {
        // setIsLoading(true)
        var data = {
            status: status,
        }
        return await api.put('/apis/Trackers/changestatus/' + id, data).then((res) => {
            if (res.data.success) {
                console.log("data", res.data)
                getData()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
            <Stack
                flex={1}
                direction={'row'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 8px 8px 8px',
                    bgcolor: '#FFFFFF',
                }}
                mb={1}
                width={'calc(100% + 24px)'}
            >
                <Stack gap={3} p={3} borderRight={'1px solid #E2E2EA'} width={420} flexShrink={0}>
                    <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Location</Typography>
                    <Stack gap={1}>
                        <Typography fontSize={12} color={'#262B40'} fontFamily={'Roboto'} fontWeight={500}>{trackerData != null && trackerData.address ? trackerData.address : "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                            Last Updated:{trackerData != null && trackerData.positionLatitude != null && trackerData.positionLatitude != "" && trackerData.positionLatitude != "-" && trackerData.timestamp != null && trackerData.timestamp !== "" ? moment(trackerData.timestamp).format("MM-DD-YY hh:mma") : "-"}
                        </Typography>
                        <Box px={2} py={1} ml={-1} width={'calc(100% + 16px)'} bgcolor={'#F6F8FA'} borderRadius={'12px'}>
                            <Stack direction={'row'}>
                                <Stack>
                                    <Grid container spacing={2.5}>
                                        <Grid item xs={6}>
                                            <Stack gap={0.5}>
                                                <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{trackerData != null && trackerData.positionSpeed > 0 ? "Online" : "Offline"}</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                    Status
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction={'row'} justifyContent="space-between" >
                                                <Stack gap={0.5}>
                                                    <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{assetData.duration || "-"}</Typography>
                                                    <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                        Area Duration
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack gap={0.5}>

                                                <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{trackerData != null && trackerData.positionSpeed > 0 ? "Yes" : "No"}</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                    Movement
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack gap={0.5}>
                                                <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{trackerData != null && trackerData.positionSpeed ? trackerData.positionSpeed == 1 ? 0.0 : trackerData.positionSpeed : "-"}</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                    Speed
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack gap={0.5}>
                                                <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{trackerData != null && trackerData.temperature ? trackerData.temperature + " °F" : "-"}</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                    Temp
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack gap={0.5}>
                                                <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{trackerData != null && trackerData.positionAltitude ? trackerData.positionAltitude + " m" : "-"}</Typography>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                    Altitude
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <Stack gap={1} mt={1.7} ml={-2}>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400} sx={{ pt: 1.5 }} textAlign={'center'}>
                                            Utilization
                                        </Typography>
                                        <Box
                                            sx={{
                                                height: '100px',
                                                width: '100px',
                                            }}
                                            id={'chartdiv'}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>

                    {/* More Detail */}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>More Details</Typography>
                        <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowMoreDetail(!showMoreDetail)
                                hideDocument()
                            }}
                        >
                            {showMoreDetail ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >
                    </Stack>
                    {showMoreDetail &&
                        <Stack width={'100%'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{assetData != null && assetData.category ? assetData.category : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Category
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{assetData != null && assetData.type ? assetData.type : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Type
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{assetData != null && assetData.manufacturer ? assetData.manufacturer : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Manufacturer
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{assetData != null && assetData.model ? assetData.model : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Model
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{assetData != null && assetData.vin ? assetData.vin : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Serial
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12} sx={{ textDecoration: assetData != null && assetData.serialNumber ? 'underline' : "none" }}>{assetData != null && assetData.serialNumber ? assetData.serialNumber : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Device 1
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {assetData != null && assetData.tracker != null && <Grid item xs={1.5}>
                                    <Stack gap={0.5}>
                                        <Checkbox
                                            sx={{
                                                height: '16px',
                                                width: '16px',
                                            }}
                                            checked={assetData.tracker.status == "1"}
                                            onChange={(e) => {
                                                console.log("checked", e.target.checked ? "1" : "2");
                                                changeStatus(e.target.checked ? "1" : "2", assetData.serialNumberID)

                                            }}
                                        />
                                        <Typography fontSize={12} color={'#63C467'} fontFamily={'Roboto'} fontWeight={500} pl={0.7}>
                                            $
                                        </Typography>
                                    </Stack>
                                </Grid>}
                                <Grid item xs={2.5}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12} sx={{ textDecoration: assetData != null && assetData.secondarySerialNumber ? 'underline' : "none" }}>{assetData != null && assetData.secondarySerialNumber ? assetData.secondarySerialNumber : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Device 2
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {assetData != null && assetData.secondaryTracker != null && <Grid item xs={1.5}>
                                    <Stack gap={0.5}>
                                        <Checkbox
                                            sx={{
                                                height: '16px',
                                                width: '16px',
                                            }}
                                            checked={assetData.secondaryTracker.status == "1"}
                                            onChange={(e) => {
                                                console.log("checked", e.target.checked ? "1" : "2");
                                                changeStatus(e.target.checked ? "1" : "2", assetData.secondarySerialNumberID)
                                            }}
                                        />
                                        <Typography fontSize={12} color={'#63C467'} fontFamily={'Roboto'} fontWeight={500} pl={0.7}>
                                            $
                                        </Typography>
                                    </Stack>
                                </Grid>}
                            </Grid>
                        </Stack>
                    }

                    {/* Add GeoFence */}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Geofences</Typography>
                        {!addGeoFence && <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowGeo(!showGeo)
                                hideShareLocation()
                            }}
                        >
                            {showGeo ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >}
                    </Stack>

                    {showGeo && <Stack>
                        <ButtonBase
                            sx={{
                                pl: 2,
                                pr: 0.5,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                height: '30px',
                                width: '160px'
                            }}
                            onClick={() => {
                                setAddGeoFence(true)
                                // hideShareLocation()
                            }}
                        >

                            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                <Typography color={'#44444F'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={14}>Add Geofence</Typography>
                                <Add
                                    sx={{
                                        color: 'secondary.main',
                                        width: 24,
                                        height: 24
                                    }}
                                />
                            </Stack>
                        </ButtonBase>
                    </Stack>}

                    {addGeoFence && <FormikProvider value={formik}> <Stack gap={1} component={'form'}
                        onSubmit={formik.handleSubmit}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Fence Name
                            </Typography>
                            <DefaultInput
                                defaultValue={formik.values.fenceName}
                                placeholder={'Enter name...'}
                                name={'fenceName'}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.fenceName && formik.errors.fenceName)}
                                helperText={formik.touched.fenceName && formik.errors.fenceName} />
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Select Tool
                            </Typography>
                            <Stack
                                flex={1}
                                sx={{
                                    height: 40,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '6px',
                                }}
                            >
                                <Tabs data={toolsTabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline small />
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Alerts
                            </Typography>
                            <Stack
                                direction={'row'}
                                gap={1}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flex={1}
                                sx={{
                                    height: 40,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '6px',
                                    pl: 2,
                                    pr: 1,
                                }}
                            >
                                <Typography fontSize={14}>Activate Alerts</Typography>
                                <DefaultToggle name={'isAlert'} />
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Send To
                            </Typography>
                            <DefaultInput
                                defaultValue={formik.values.email}
                                placeholder={'Enter email'}
                                name={'email'}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Stack>
                        <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#EFEFEF',
                                    borderRadius: '5px',
                                }}
                                onClick={() => {
                                    setDrawData(null)
                                    setAddGeoFence(false)
                                    setSelectedPoligon(null)
                                    var value = {
                                        fenceName: '',
                                        isAlert: false,
                                        email: null
                                    }
                                    formik.setValues(value)
                                }}
                            >
                                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                    Cancel
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#FCFCFE',
                                    borderRadius: '5px',
                                }}
                                disabled={formik.isSubmitting}
                                type="submit"
                            >
                                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                    Save
                                </Typography>
                            </ButtonBase>
                        </Stack>
                    </Stack> </FormikProvider>}
                    {addGeoFence ? '' : showGeo && <DefaultDataGrid rows={arrayData} columns={geoFenceColumns(assetData.serialNumber, onDeleteClick, onEditClick)} disablePagination onRowClick={(e) => {
                        if (e.row.path) {
                            window.open(e.row.path, '_blank');
                        }
                    }} />}

                    {/* Add Sharing */}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Location Sharing</Typography>
                        {!addSharing && <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowSharing(!showSharing)
                                hideGeoFence()
                            }}
                        >
                            {showSharing ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >}
                    </Stack>
                    {showSharing && <ButtonBase
                        sx={{
                            pl: 2,
                            pr: 0.5,
                            border: '1px solid #E2E2EA',
                            borderRadius: '5px',
                            height: '30px',
                            width: '160px',
                        }}
                        onClick={() => {
                            setAddSharing(true)
                            // hideGeoFence()
                        }}
                    >

                        <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            <Typography color={'#44444F'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={14}>Share Location</Typography>
                            <Add
                                sx={{
                                    color: 'secondary.main',
                                    width: 24,
                                    height: 24,
                                }}
                            />
                        </Stack>
                    </ButtonBase>}
                    {addSharing && <FormikProvider value={sharingformik}> <Stack gap={1} component={'form'}
                        onSubmit={sharingformik.handleSubmit}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400}>
                                Status
                            </Typography>
                            <Stack
                                direction={'row'}
                                gap={1}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flex={1}
                                sx={{
                                    height: 40,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '6px',
                                    pl: 2,
                                    pr: 1,
                                }}
                            >
                                <Typography fontSize={14}>Activate Swipe</Typography>
                                <DefaultToggle name={'status'} />
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400}>
                                Send To
                            </Typography>
                            <DefaultInput
                                defaultValue={sharingformik.values.email}
                                placeholder={'Enter email'}
                                name={'email'}
                                onBlur={sharingformik.handleBlur}
                                onChange={sharingformik.handleChange}
                                error={Boolean(sharingformik.touched.email && sharingformik.errors.email)}
                                helperText={sharingformik.touched.email && sharingformik.errors.email}
                            />
                        </Stack>
                        <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#EFEFEF',
                                    borderRadius: '5px',
                                }}
                                onClick={() => {
                                    setAddSharing(false)
                                    var value = {
                                        submit: null,
                                        email: "",
                                        status: false
                                    }
                                    sharingformik.setValues(value)
                                }}
                            >
                                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                    Cancel
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#FCFCFE',
                                    borderRadius: '5px',
                                }}
                                disabled={sharingformik.isSubmitting}
                                type="submit"
                            >
                                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                    Save
                                </Typography>
                            </ButtonBase>
                        </Stack>
                    </Stack> </FormikProvider>}
                    {addSharing ? '' : showSharing && <DefaultDataGrid rows={sharingArrayData} columns={sharingColumns(onSharingDeleteClick, onSharingEditClick)} disablePagination onRowClick={(e) => {
                        if (e.row.path) {
                            window.open(e.row.path, '_blank');
                        }
                    }} />}

                    {/* Show Document*/}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Documents</Typography>
                        <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowDocument(!showDocument)
                                hideLocationDetail()
                            }}
                        >
                            {showDocument ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >
                    </Stack>
                    {showDocument && <Stack>
                        <input
                            type="file"
                            name="files"
                            hidden
                            ref={fileRef}
                            onChange={(e) => {
                                const selectedFiles = e.target.files;
                                console.log("selectedFiles", selectedFiles)
                                if (selectedFiles.length > 0) {
                                    uploadFiles(selectedFiles[0]);
                                }

                            }}

                        />
                        <ButtonBase
                            sx={{
                                border: '1px solid #E2E2EA',
                                bgcolor: '#FCFCFE',
                                borderRadius: '5px',
                                width: 100,
                                height: 30
                            }}

                            onClick={(e) => {
                                e.preventDefault()
                                if (fileRef.current) {
                                    fileRef.current.value = null;
                                    fileRef.current.click()
                                }
                            }}
                        >
                            <Typography color={'#44444F'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={14}>
                                Upload
                            </Typography>
                        </ButtonBase>
                    </Stack>}
                    {showDocument && <DefaultDataGrid rows={documentArrayData} columns={documentColumns(onDocumentDeleteClick)} disablePagination onRowClick={(e) => {
                        if (e.row.path) {
                            window.open(e.row.path, '_blank');
                        }
                    }}></DefaultDataGrid>}
                </Stack>
                <Map trackerData={activeTrackerTab == "primary" ? trackerData : assetData.secondaryTracker} arrayData={arrayData} isDrawGeoFence={addGeoFence} onDrawGeofence={onDrawGeofence} mode={activeTab} selectedPoligon={selectedPoligon} activeTrackerTab={activeTrackerTab} handleChangeTrackersTab={handleChangeTrackersTab} showTrackerTab={assetData != null && assetData.secondaryTracker != null} />
            </Stack >
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            {
                sharingDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setSharingDeleteConfirmationShouldOpen(false)
                        updateSharingConfirmAlert(value)
                    }} />)
            }
            {
                documentDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDocumentDeleteConfirmationShouldOpen(false)
                        updateDocumentConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

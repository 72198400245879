import { Stack, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tag } from '../../../ui/Tag/index.js';
import { arrayGroupType } from '../tabs/User/Data/UserData.jsx';

export const groupsColumns = [
    {
        field: 'name',
        headerName: 'Group Name',
        width: 200,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.name ? value.row.name : '-'}
            </Typography>
        ),
    },
    {
        field: 'description',
        headerName: 'Description',
        width: 250,
        renderCell: (value) => (
            <Typography fontSize={13}>
                {value.row.description ? value.row.description : '-'}
            </Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: (value) => {
            const borderColor = value.row.status == "1" ? "green.main" : "red.main"
            const text = value.row.status == "1" ? "Active" : "Disabled"
            return (
                <Tag text={text} borderColor={borderColor} />
            )
        }
    },
    {
        field: 'type',
        headerName: 'Group Type',
        width: 150,
        renderCell: ({value}) => (
            <Typography fontSize={13}>
                {arrayGroupType.find(obj => obj.id == value)?.value || "-"}
            </Typography>
        ),
    },
    {
        field: 'arrowIcon',
        headerName: '',
        flex: 1,
        renderCell: () => (
            <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={1} justifyContent={'flex-end'} pr={3}>
                <ArrowForwardIos
                    sx={{
                        color: 'secondary.main',
                        width: 18,
                        height: 18,
                    }}
                />
                <Typography color={'secondary.main'} fontSize={14}>
                    Details
                </Typography>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

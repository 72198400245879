import { Box, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon.svg'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon.svg'
import SettingsIcon from '../../../assets/icons/settings.svg'
import api from '../../../lib/axios.js';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import moment from 'moment/moment';
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx'

export const ChartBox = ({ nodeData, sensorData, onChartSettingClick, componentOrder, index, onUp, onDown }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arraySensorDataType, setArraySensorDataType] = useState([]);
    const [arrayData, setArrayData] = useState([]);
    const chartId = 'chartDiv'
    const selectedSensor = nodeData?.objChart?.sensor
    const color = nodeData?.objChart?.color || "#0x007bff"

    useEffect(() => {
        if (nodeData != null && sensorData != null) {
            console.log("PRODUCT:", sensorData.product)
            if (sensorData.product == '4') {
                const arrayMeasurment = [
                    { id: "1", value: "Temperature", field: "temperature", symbol: "°F" },
                    { id: "4", value: "Battery", field: "battery", symbol: "" },
                    { id: "6", value: "Rssi", field: "rssi", symbol: "" },
                    { id: '7', value: 'rms_ACC_G ', field: "RmsACCG" },
                    { id: '8', value: 'max_ACC_G', field: "MaxACCG" },
                    { id: '9', value: 'velocity_mm_sec', field: "VelocityMmSec" },
                    { id: '10', value: 'displacement_mm', field: "DisplacementMm" },
                    { id: '11', value: 'peak_one_Hz', field: "PeakOneHz" },
                    { id: '12', value: 'peak_two_Hz', field: "PeakTwoHz" },
                    { id: '13', value: 'peak_three_Hz', field: "PeakThreeHz" }
                ]
                setArraySensorDataType(arrayMeasurment)
                getSensorData(moment(), moment())
            } else {
                getSecondaryData()
            }
        }
    }, [nodeData, sensorData]);

    useEffect(() => {
        if (nodeData != null && nodeData.objChart != null && nodeData.objChart.chartType != null) {
            if (nodeData.objChart.chartType == "1") {
                LineChart()
            } else {
                ColumnChart()
            }
        }
    }, [arrayData]);

    const getSecondaryData = () => {
        setIsLoading(true)
        api.get('/apis/SecondaryDatas/byNode/' + nodeData._id).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                let arraySensorDataType = arrayMeasurment.filter(item => item.array.includes(sensorData.product + ""))
                data.forEach((obj) => {
                    const exists = arraySensorDataType.some(sensor =>
                        sensor.id === obj._id);
                    if (!exists) {
                        let newData = { id: obj._id, value: obj.name, field: "pin", symbol: "" }
                        arraySensorDataType.push(newData);

                    }
                })
                setArraySensorDataType(arraySensorDataType)
                getSensorData(moment(), moment())
            } else {
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    const getSensorData = async (startDate, endDate) => {
        setIsLoading(true);
        let url = '/apis/Nodes/getSensorData'
        if (sensorData.product == "4") {
            url = '/apis/NodeSensors/vibrationSensorDataByDate'
        }
        let response = await api.post(url, { startDate: startDate.format('MM-DD-YYYY'), endDate: endDate.format('MM-DD-YYYY'), nodeID: nodeData._id })
        setArrayData(response.data.data)
        setIsLoading(false);
    }

    function LineChart() {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartId) {
                root.dispose();
            }
        });

        let root = am5.Root.new(chartId);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        root.dateFormatter.setAll({
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["valueX"]
        });

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));

        let easing = am5.ease.linear;

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.1,
            groupData: false,
            baseInterval: {
                timeUnit: "minute",
                count: 1
            },
            dateFormats: {
                hour: "hh:mm a",
                minute: "hh:mm a",
                day: "MMM dd",
            },
            periodChangeDateFormats: {
                day: "MMM dd",
                minute: "hh:mm a",
                hour: "MMM dd",    // Show "MMM DD" when the date changes
            },
            tooltipDateFormats: {
                hour: "hh:mm a" // 12-hour format with minutes and AM/PM for tooltips
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 70
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.2,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });
        yAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });

        let fieldValue = arraySensorDataType.find(obj => obj.id == selectedSensor)?.field;
        console.log("field ", fieldValue);
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            minBulletDistance: 10,
            connect: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldValue,
            valueXField: "timestamp",
            stroke: am5.color(color),
            fill: am5.color(color),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueX}: {valueY}"
            })
        }));

        series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
        });
        console.log("color:", color)
        series.strokes.template.setAll({
            // stroke: am5.color(color),
            strokeWidth: 2
        });

        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["timestamp"]
        });



        let data = [];

        arrayData.map((chartData) => {
            if (sensorData.product == "4") {
                if (selectedSensor == "1" || selectedSensor == "4" || selectedSensor == "6") {
                    let obj = {
                        [fieldValue]: parseFloat(chartData[fieldValue]),
                        "timestamp": new Date(chartData.createdAt)
                    };
                    data.push(obj);
                } else {
                    let obj = {
                        [fieldValue]: parseFloat(chartData[`x${fieldValue}`]),
                        [`y${fieldValue}`]: parseFloat(chartData[`y${fieldValue}`]),
                        [`z${fieldValue}`]: parseFloat(chartData[`z${fieldValue}`]),
                        "timestamp": new Date(chartData.createdAt)
                    };
                    data.push(obj);
                }

            } else {
                let obj = {
                    "battery": parseFloat(chartData.battery),
                    "temperature": parseFloat(chartData.temperature),
                    "altitude": parseFloat(chartData.altitude),
                    "speed": parseFloat(chartData.speed),
                    "cellSignal": parseFloat(chartData.cellSignal),
                    "timestamp": new Date(chartData.timestamp)
                };

                chartData.arraySecondaryData.forEach((secondaryData) => {
                    obj.pin = parseFloat(secondaryData.value);
                });
                data.push(obj);
            }
        });
        data.reverse()
        if (sensorData.product == "4") {
            if (selectedSensor != "1" && selectedSensor != "4" && selectedSensor != "6") {
                let seriesy = chart.series.push(am5xy.LineSeries.new(root, {
                    minBulletDistance: 10,
                    connect: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: `y${fieldValue}`,
                    valueXField: "timestamp",
                    stroke: am5.color(color),
                    fill: am5.color(color),
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{valueX}: {valueY}"
                    })
                }));

                seriesy.fills.template.setAll({
                    fillOpacity: 0.2,
                    visible: true
                });
                console.log("color:", color)
                seriesy.strokes.template.setAll({
                    // stroke: am5.color(color),
                    strokeWidth: 2
                });

                seriesy.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "MM-dd-YYYY hh:mm a",
                    dateFields: ["timestamp"]
                });

                let seriesz = chart.series.push(am5xy.LineSeries.new(root, {
                    minBulletDistance: 10,
                    connect: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: `z${fieldValue}`,
                    valueXField: "timestamp",
                    stroke: am5.color(color),
                    fill: am5.color(color),
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{valueX}: {valueY}"
                    })
                }));

                seriesz.fills.template.setAll({
                    fillOpacity: 0.2,
                    visible: true
                });
                console.log("color:", color)
                seriesz.strokes.template.setAll({
                    // stroke: am5.color(color),
                    strokeWidth: 2
                });

                seriesz.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "MM-dd-YYYY hh:mm a",
                    dateFields: ["timestamp"]
                });
                seriesy.data.setAll(data);
                seriesz.data.setAll(data);
            }
        }
        series.data.setAll(data);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        chart.appear(1000, 100);
    }

    function ColumnChart() {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartId) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartId);

        const myTheme = am5.Theme.new(root);

        root.dateFormatter.setAll({
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["valueX"]
        });
        root.setThemes([
            am5themes_Animated.new(root),
            myTheme,
            am5themes_Responsive.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));

        cursor.lineY.set("visible", false);


        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0,
            baseInterval: {
                timeUnit: "minute",
                count: 1
            },
            dateFormats: {
                hour: "hh:mm a",
                minute: "hh:mm a",
                day: "MMM dd",
            },
            periodChangeDateFormats: {
                day: "MMM dd",
                minute: "hh:mm a",
                hour: "MMM dd",    // Show "MMM DD" when the date changes
            },
            tooltipDateFormats: {
                hour: "hh:mm a" // 12-hour format with minutes and AM/PM for tooltips
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 70
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });
        yAxis.get("renderer").labels.template.setAll({
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "normal",
        });

        let fieldValue = arraySensorDataType.find(obj => obj.id == selectedSensor)?.field
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldValue,
            valueXField: "timestamp",
            stroke: am5.color(color),
            fill: am5.color(color),
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            })
        }));

        series.columns.template.setAll({ strokeOpacity: 0 })

        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "MM-dd-YYYY hh:mm a",
            dateFields: ["timestamp"]
        });
        let data = []
        arrayData.map((chartData) => {
            if (sensorData.product == "4") {
                if (selectedSensor == "1" || selectedSensor == "4" || selectedSensor == "6") {
                    let obj = {
                        [fieldValue]: parseFloat(chartData[fieldValue]),
                        "timestamp": new Date(chartData.createdAt)
                    };
                    data.push(obj);
                } else {
                    let obj = {
                        [fieldValue]: parseFloat(chartData[`x${fieldValue}`]),
                        [`y${fieldValue}`]: parseFloat(chartData[`y${fieldValue}`]),
                        [`z${fieldValue}`]: parseFloat(chartData[`z${fieldValue}`]),
                        "timestamp": new Date(chartData.createdAt)
                    };
                    data.push(obj);
                }


            } else {
                let obj = {
                    "battery": parseFloat(chartData.battery),
                    "temperature": parseFloat(chartData.temperature),
                    "altitude": parseFloat(chartData.altitude),
                    "speed": parseFloat(chartData.speed),
                    "cellSignal": parseFloat(chartData.cellSignal),
                    "timestamp": new Date(chartData.timestamp)
                }
                chartData.arraySecondaryData.forEach((secondaryData) => {
                    obj.pin = parseFloat(secondaryData.value)

                })
                data.push(obj)
            }
        });
        data.reverse()
        if (sensorData.product == "4") {
            if (selectedSensor != "1" && selectedSensor != "4" && selectedSensor != "6") {
                let seriesy = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: "Series",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: `y${fieldValue}`,
                    valueXField: "timestamp",
                    stroke: am5.color(color),
                    fill: am5.color(color),
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    })
                }));
                seriesy.columns.template.setAll({ strokeOpacity: 0 })

                seriesy.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "MM-dd-YYYY hh:mm a",
                    dateFields: ["timestamp"]
                });

                let seriesz = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: "Series",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: `y${fieldValue}`,
                    valueXField: "timestamp",
                    stroke: am5.color(color),
                    fill: am5.color(color),
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    })
                }));
                seriesz.columns.template.setAll({ strokeOpacity: 0 })

                seriesz.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "MM-dd-YYYY hh:mm a",
                    dateFields: ["timestamp"]
                });
               
                seriesy.data.setAll(data);
                seriesz.data.setAll(data);
            }
        }
        series.data.setAll(data);

        // series.data.setAll(arrayData);
        series.appear(1000);
        chart.appear(1000, 100);
    }
    return (
        <>
            <Box sx={{ width: "100%", border: '1px solid #E2E2EA', borderRadius: '3.5px', marginBottom: "10px" }}>
                <Stack direction="row" sx={{ display: "flex", height: "40px", borderBottom: '1px solid #E2E2EA', alignItems: 'center' }} px={'15px'}>
                    <Typography fontWeight={500} fontSize={12} >{nodeData?.chartGroupName || "Data Group 1"}</Typography>
                    <Box flexGrow={1}></Box>
                    <Stack direction={'row'} alignItems={'center'} gap="18px">
                        <Box
                            sx={{
                                height: '18px',
                                width: '18px',
                                cursor: 'pointer',
                            }}
                            component={'img'}
                            onClick={(e) => {
                                onChartSettingClick(true)
                            }}
                            src={SettingsIcon}
                        />
                        <Box
                            sx={{
                                height: '13px',
                                width: '10px',
                                opacity: index === 0 ? 0.5 : 1,
                                cursor: index === 0 ? 'not-allowed' : 'pointer',
                            }}
                            onClick={index > 0 ? () => onUp() : null}
                            component={'img'}
                            src={ArrowUpIcon}
                        />
                        <Box
                            sx={{
                                height: '13px',
                                width: '10px',
                                opacity: (index === (componentOrder.length - 1)) ? 0.5 : 1,
                                cursor: (index === (componentOrder.length - 1)) ? 'not-allowed' : 'pointer',
                            }}
                            onClick={index != (componentOrder.length - 1) ? () => onDown() : null}
                            component={'img'}
                            src={ArrowDownIcon}></Box>
                    </Stack>
                </Stack>
                <Typography fontWeight={500} fontSize={12} p={'15px'}>{nodeData?.objChart?.chartName || ""}</Typography>
                <Box sx={{ height: "260px" }} id={chartId} px={'15px'} overflow='hidden'>

                </Box>
            </Box>
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

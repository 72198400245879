import { FormikProvider, useFormik } from 'formik';
import { arrayPin } from './Data/data.jsx';
import { ButtonBase, Stack, Typography, Backdrop, CircularProgress, Box, Grid, MenuItem } from "@mui/material"
import React, { useState, useEffect, useRef } from 'react';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import api from '../../../lib/axios.js';
import * as Yup from 'yup';
import moment from 'moment'
import dayjs from 'dayjs';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/index.js';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import Papa from 'papaparse';
import { ConfirmAlert } from '../../../customControls/confirmAlert.js';
import { Close } from '@mui/icons-material';

export const ConfigDataLogicManage = ({ children, selectedDataID, nodeID, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    // const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    // const [logicValue, setLogicValue] = useState(null);

    // const formik = useFormik({
    //     initialValues: {
    //         submit: null,
    //         name: '',
    //         selectedPin: null,
    //         logic: '',
    //     },
    //     validationSchema: Yup.object().shape({
    //         name: Yup.string().required('Name is required'),
    //         selectedPin: Yup.object().required('Pin is required'),
    //         logic: Yup.string().required('Logic is required'),
    //     }),
    //     onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
    //         try {
    //             console.log("dataValues", values)
    //             var dataValues = {
    //                 submit: null,
    //                 name: values.name,
    //                 selectedPin: values.selectedPin,
    //                 logic: values.logic
    //             }
    //             formik.setValues(dataValues)
    //             setIsLoading(true)
    //             var data = await apiCalling(values);
    //             console.log("Data", data.data.success)
    //             if (data !== undefined && data.data.success) {
    //                 console.log("IF")
    //                 setStatus({ success: true });
    //                 setSubmitting(false);
    //                 enqueueSnackbar('Secondary data ' + (selectedDataID == "0" ? "added" : "updated"), {
    //                     anchorOrigin: {
    //                         horizontal: 'right',
    //                         vertical: 'top'
    //                     },
    //                     variant: 'success'
    //                 });
    //                 onClose(true, null)
    //             } else {
    //                 console.log("ELSE")
    //                 setStatus({ success: false });
    //                 setErrors({ submit: data.data.message });
    //                 setSubmitting(false);
    //                 enqueueSnackbar(data.data.message, {
    //                     anchorOrigin: {
    //                         horizontal: 'right',
    //                         vertical: 'top'
    //                     },
    //                     variant: 'error'
    //                 });
    //             }
    //             setSubmitting(false);
    //         } catch (err) {
    //             console.log("inCATCH", err);
    //             if (isMountedRef.current) {
    //                 setStatus({ success: false });
    //                 setErrors({ submit: err.message });
    //                 setSubmitting(false);
    //             }
    //         }
    //     },
    // })

    // useEffect(() => {
    //     handleLogicCalculation(formik.values.logic)
    // }, [formik.values.logic]);

    // useEffect(() => {
    //     if (selectedDataID !== "" && selectedDataID !== "0") {
    //         getData();
    //     } else {
    //         setIsLoading(false);
    //     }
    // }, [selectedDataID]);


    // async function apiCalling(values) {
    //     var data = {
    //         name: values.name,
    //         pin: values.selectedPin.pin,
    //         logic: values.logic,
    //         nodeID: nodeID
    //     }
    //     setIsLoading(false)
    //     console.log("data", data)
    //     if (logicValue != null) {
    //         if (selectedDataID != "0") {
    //             return await api.put('/apis/SecondaryDatas/updateById/' + selectedDataID, data)
    //         } else {
    //             return await api.post('/apis/SecondaryDatas/add', data)
    //         }
    //     } else {
    //         enqueueSnackbar('Logic is inValid', {
    //             anchorOrigin: {
    //                 horizontal: 'right',
    //                 vertical: 'top'
    //             },
    //             variant: 'error'
    //         });
    //     }
    // }

    // const handleLogicCalculation = (logics) => {
    //     console.log("logic", logics);
    //     var logic = logics;
    //     var rawValue = 100
    //     var operator = logic[0]; // Extract the operator (first character)
    //     var operand = parseFloat(logic.slice(1)); // Extract the operand (remaining part)

    //     // Initialize value to rawValue
    //     var value = rawValue;

    //     // Check if the operator is one of the valid ones
    //     if (['/', '*', '+', '-'].includes(operator) && !isNaN(operand)) {
    //         switch (operator) {
    //             case '/':
    //                 value = rawValue / operand;
    //                 break;
    //             case '*':
    //                 value = rawValue * operand;
    //                 break;
    //             case '+':
    //                 value = rawValue + operand;
    //                 break;
    //             case '-':
    //                 value = rawValue - operand;
    //                 break;
    //         }
    //         console.log("logic Value", value);
    //         setLogicValue(value)
    //     } else {
    //         // console.error(Invalid logic: ${ logic });
    //         // Handle invalid logic case (e.g., skip this item, set value to null, etc.)
    //         value = null;
    //         setLogicValue(null)
    //     }
    // }

    // function getData() {
    //     setIsLoading(true)
    //     api.get('/apis/SecondaryDatas/getById/' + selectedDataID).then((res) => {
    //         if (res.data.success) {
    //             var data = res.data.data
    //             console.log("got data", data)

    //             var selectedPin = arrayPin.filter(pin => pin.pin == data.pin)
    //             if (selectedPin.length > 0) {
    //                 selectedPin = selectedPin[0]
    //             } else {
    //                 selectedPin = null
    //             }

    //             var dataValues = {
    //                 submit: null,
    //                 name: data.name,
    //                 selectedPin: selectedPin,
    //                 logic: data.logic
    //             }
    //             formik.setValues(dataValues)
    //             setIsLoading(false)
    //         }
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    // function deleteSecondaryData() {
    //     setIsLoading(true)
    //     api.delete('/apis/SecondaryDatas/deleteById/' + selectedDataID).then((res) => {
    //         if (res.data.success) {
    //             enqueueSnackbar('Secondary data deleted', {
    //                 anchorOrigin: {
    //                     horizontal: 'right',
    //                     vertical: 'top'
    //                 },
    //                 variant: 'success'
    //             });
    //             onClose(true, null)
    //         } else {
    //             setIsLoading(false)
    //         }
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    // const updateConfirmAlert = (value) => {
    //     if (value) {
    //         deleteSecondaryData()
    //     } else {
    //         setDeleteConfirmationShouldOpen(false)
    //     }
    // }

    return (
        <>
            <Stack width={'100%'}>
                {/* <FormikProvider value={formik}> */}
                <Stack
                    px={4}
                    pt={3}
                    pb={2}
                    gap={4}
                    overflowY={'auto'}
                    minWidth={'700px'}
                    height={'750px'}
                    maxHeight={'calc(100vh - 100px)'}
                    maxWidth={'700px'}
                    position={'fixed'}
                    zIndex={10}
                    sx={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        overflowY: 'auto',
                        borderRadius: '10px',
                        bgcolor: 'common.white',
                        border: '1px solid #D5D5D5',
                    }}
                // component={'form'}
                // onSubmit={formik.handleSubmit}
                >
                    <Stack>
                        <Stack direction={'row'} width={'100%'}>
                            <Stack direction={'row'} alignItems="center" justifyContent="center" width="100%">
                                <Typography fontSize={18}>Information</Typography>
                            </Stack>
                            <Stack >
                                <ButtonBase onClick={() => onClose(false)} >
                                    <Close></Close>
                                </ButtonBase>
                            </Stack>

                        </Stack>
                        <Box mt={3}>
                            <Grid spacing={3} container>
                                <Grid xs={6} item>
                                    <Stack justifyContent="center" height={'100%'} ml={2}>
                                        <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={'Medium'} color={'#151D26'}>Information about logic</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                    {/* <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <DefaultInput
                                    label={'Name'}
                                    placeholder={'E.g. abc'}
                                    value={formik.values.name}
                                    name={'name'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.name && formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DefaultSelect
                                    sx={{
                                        '.MuiInputBase-root': {
                                            height: 40,
                                        },
                                    }}
                                    label={'Pin'}
                                    value={formik.values.selectedPin}
                                    onItemSelected={(e) => {
                                        formik.setFieldValue('selectedPin', e.target.value)
                                    }}
                                    helperText={formik.touched.selectedPin && formik.errors.selectedPin}
                                    error={Boolean(formik.touched.selectedPin && formik.errors.selectedPin)}
                                    placeholder="Select pin"
                                    displayKey="pin"

                                >
                                    {arrayPin.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item.pin}</MenuItem>
                                    ))}
                                </DefaultSelect>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={5} >
                                <Stack direction={'row'} alignItems={'center'} gap={2} >
                                    <Typography fontSize={14} mt={2.3}>
                                        E.g.100
                                    </Typography>
                                    <DefaultInput
                                        label={'Logic'}
                                        placeholder={'E.g. abc'}
                                        value={formik.values.logic}
                                        name={'logic'}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.logic && formik.errors.logic)}
                                        helperText={formik.touched.logic && formik.errors.logic}
                                    />
                                    {logicValue != null && <Typography fontSize={14} mt={2.3}>
                                        =
                                    </Typography>}
                                    {logicValue != null && <Typography fontSize={14} mt={2.3}>
                                        {logicValue}
                                    </Typography>}
                                </Stack>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        height: '1px',
                                        width: '100%',
                                        borderTop: '1px dashed #D5D5D5',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box flex={1}>{children}</Box>
                        <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                            <Stack>
                                {selectedDataID != "0" && <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #FF3941',
                                        bgcolor: 'common.white',
                                        borderRadius: '5px',
                                    }}
                                    onClick={(e) => {
                                        setDeleteConfirmationShouldOpen(true)
                                    }}
                                >

                                    <Typography fontSize={14} fontWeight={700}>
                                        Delete
                                    </Typography>
                                </ButtonBase>}
                            </Stack>*/}

                    {/* </Stack>  */}
                </Stack>
                {/* </FormikProvider > */}
            </Stack>
            {/* {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            } */}
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
export const tabs = [
    {
        title: 'Details',
        value: 'details',
    },
    {
        title: 'Interval',
        value: 'interval',
    }
];

import React, { useState } from 'react';
import dayjs from 'dayjs';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Box, IconButton, TextField, Typography } from '@mui/material';

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
        },
    }),
    ...(day.day() === 0 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(day.day() === 6 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }
    return dayA.isSame(dayB, 'week');
};

function Day(props) {
    const { day, selectedDay, hoveredDay, ...other } = props;
    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

export const WeekPicker = (props) => {
    const [range, setRange] = useState([dayjs().startOf('week'), dayjs().endOf('week')]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [hoveredDay, setHoveredDay] = React.useState(null);

    const handleWeekChange = (newDate) => {
        var startDate = dayjs(newDate).startOf('week');
        var endDate = dayjs(newDate).endOf('week');
        setRange([startDate, endDate]);
        props.handleDateChange(newDate);
        setIsDatePickerOpen(false);
    };
    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '2px',
                    borderRadius: '5px',
                    bgcolor: 'common.white',
                    border: '1px solid #E2E2EA',
                    backgroundColor: '#F5F7FB',
                    position: 'relative',
                    '.MuiIconButton-root': {
                        width: '65px',
                        mr: '0',
                        borderRadius: '5px',
                        height: '45px',
                        position: 'relative',
                        '&::after': {
                            content: "''",
                            position: 'absolute',
                            height: '25px',
                            width: '1px',
                            backgroundColor: 'grey.light',
                            left: 0,
                        },
                    },
                }}
            >
                <Typography
                    sx={{
                        display: 'block',
                        boxSizing: 'content-box',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                        position: 'relative',
                        alignItems: 'center',
                        height: '1.4375em',
                        animationDirection: '10ms',
                        lineHeight: '1.4375em',
                    }}
                >
                    {`${dayjs(range[0]).format('MMMM DD, YYYY')} - ${dayjs(range[1]).format('MMMM DD, YYYY')}`}
                </Typography>
                <IconButton sx={{ marginLeft: '2px' }} onClick={toggleDatePicker}>
                    <TodayRoundedIcon />
                </IconButton>
                {isDatePickerOpen && (
                    <DatePicker
                        sx={{
                            width: '0px',
                            height: '0px',
                            marginTop: -4,
                            borderRadius: '5px',
                            bgcolor: 'common.white',
                            border: '1px solid #E2E2EA',
                            position: 'absolute',
                            overflow: 'hidden',
                            '.MuiOutlinedInput-input': {
                                p: '0 12px',
                                height: '40px',
                                order: 2,
                            },
                            '.MuiInputBase-root': {
                                pr: 0,
                            },
                            '.MuiInputAdornment-root': {
                                ml: 0,
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                p: '0',
                            },
                            '.MuiIconButton-root': {
                                width: '100%',
                                mr: '0',
                                height: '100%',
                                position: 'absolute',
                                borderRadius: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                svg: {
                                    width: '16px',
                                    height: '16px',
                                    transform: 'rotate(90deg)',
                                },
                            },
                        }}
                        showDaysOutsideCurrentMonth
                        open={isDatePickerOpen}
                        onClose={() => setIsDatePickerOpen(false)}
                        value={props.initialDate}
                        onChange={handleWeekChange}
                        renderInput={(day, _value, DayComponentProps) => {
                            const isSelected = dayjs(day).isSame(props.initialDate, 'day');
                            return (
                                <IconButton
                                    {...DayComponentProps}
                                    sx={{
                                        bgcolor: isSelected ? 'primary.main' : 'transparent',
                                        color: isSelected ? 'white' : 'inherit',
                                        '&:hover': {
                                            bgcolor: isSelected ? 'primary.dark' : 'grey.200',
                                        },
                                    }}
                                >
                                    {day.format('d')}
                                </IconButton>
                            );
                        }}
                        slots={{ day: Day }}
                        slotProps={{
                            day: (ownerState) => ({
                                selectedDay: props.initialDate,
                                hoveredDay,
                                onPointerEnter: () => setHoveredDay(ownerState.day),
                                onPointerLeave: () => setHoveredDay(null),
                            }),
                        }}
                        inputFormat="'Week of' MMM d"
                        views={['year', 'month', 'day']}
                    />
                )}
            </Box>
        </LocalizationProvider>
    );
};
import mapboxgl from 'mapbox-gl';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Box, Stack } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useRecoilValue } from 'recoil';
import { sidebarOpened } from '../../../core/store/index.js';
import { Tabs } from '../../../ui/Tabs/index.js';
import { tabs } from '../../../components/Map/data/tabs.js';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnbmFsdHJhY2tzIiwiYSI6ImNsd3Rjd3A1bTAyYnQybnBuNXZnajJ2YjEifQ.6DdrfKM2ke64TpX-Igogdg';

// eslint-disable-next-line react/display-name
export const Map = memo(({ trackerData, sx }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const isOpenedSidebar = useRecoilValue(sidebarOpened);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);
    const [lng, setLng] = useState((trackerData != null && trackerData.positionLongitude != null && trackerData.positionLongitude != "-") ? trackerData.positionLongitude : -74.01521529520066);
    const [lat, setLat] = useState((trackerData != null && trackerData.positionLatitude != null && trackerData.positionLatitude != "-") ? trackerData.positionLatitude : 40.73760113154407);
    const [zoom, setZoom] = useState(12);

    const handleChangeTab = (value) => {
        setActiveTab(value);
    };
    const renderMap = () => {
        console.log("-----", [lng, lat])
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: `mapbox://styles/signaltracks/${activeTab}`,
                center: [lng, lat],
                zoom: zoom,
            });
            console.log("-----", map.current.getCenter())
            map.current.addControl(new mapboxgl.NavigationControl());
            map.current.setCenter([lng, lat])
            map.current.on("load", async () => {

            });

            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(4));
                setLat(map.current.getCenter().lat.toFixed(4));
                setZoom(map.current.getZoom().toFixed(2));
            });

            map.current.on('click', (e) => {
                //console.log(e);
            });


            if (trackerData != null && trackerData.positionLongitude != null && trackerData.positionLongitude != "-" && trackerData.positionLatitude != null && trackerData.positionLatitude != "-") {
                const el = document.createElement('div');
                el.className = 'map-marker';
                new mapboxgl.Marker(el).setLngLat([trackerData.positionLongitude, trackerData.positionLatitude]).addTo(map.current);
            }
        }
    };
    const handleStorageChange = useDebouncedCallback(() => {
        if (map.current) {
            map.current.remove();
            map.current = null
        }
        renderMap();
    }, 500);

    useEffect(() => {
        if (trackerData != null && trackerData._id != null) {
            var logitude = (trackerData != null && trackerData.positionLongitude != null && trackerData.positionLongitude != "-") ? trackerData.positionLongitude : -74.01521529520066
            var latitude = (trackerData != null && trackerData.positionLatitude != null && trackerData.positionLatitude != "-") ? trackerData.positionLatitude : 40.73760113154407
            setLng(logitude);
            setLat(latitude);
            if (map.current) {
                map.current.setCenter([logitude, latitude])
            }
        }
        handleStorageChange();
    }, [isOpenedSidebar,trackerData, activeTab]);


    return (
        <Box position={'relative'} width={'100%'} height={'100%'}>
            <Stack direction={'row'} justifyContent={'space-between'} position="relative">
                <Box position={'absolute'} left={12} top={12} zIndex={3}>
                    <Tabs data={tabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline />
                </Box>
            </Stack>


            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    ...sx,
                }}
                ref={mapContainer}
            />
        </Box >
    );
});

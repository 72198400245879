import { Stack, TextField, Box } from '@mui/material';

export const SearchInput = ({ sx, name, placeholder,...props }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <Stack
            direction={'row'}
            sx={{
                border: '1px solid #E2E2EA',
                borderRadius: '6px',
                minWidth: '250px',
                overflow: 'hidden',
                height: 40,
                ...sx,
            }}
        // component={'form'}
        // onSubmit={handleSubmit}
        >
            <Box position={'relative'} width={'100%'}>
                <TextField
                    variant='standard'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={props.handleQueryChange}
                    placeholder={placeholder}
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '6px',
                        '.MuiFormLabel-root': {
                            fontSize: 12,
                            transform: 'unset',
                            color: '#A8A8A7',
                            position: 'static',
                            lineHeight: '1.5',
                            '&.Mui-disabled': {
                                // color: 'primary.main',
                            },
                        },
                        '.MuiInput-input': {
                            fontSize: 14,
                            height: 40,
                            py: 0,
                            // pr: 2,
                            pl: 2,
                            border: '1px solid #E2E2EA',
                            borderRadius: '6px',
                            boxSizing: 'border-box',
                        },
                        '.MuiInputBase-root': {
                            mt: 0,
                            py: 0,
                            '&::before': {
                                border: 'none',
                            },
                            '&.Mui-disabled': {
                                '&::before': {
                                    display: 'none',
                                },
                            },
                            height: '40px',
                            // pr: 2,
                            //pt: '1px',
                            '&::after': {
                                bottom: 1.5,
                            },
                        },
                        '.MuiInputBase-input': {
                            fontSize: 14,
                            // bgcolor: '#F6F8FA',
                            borderRight: 0,
                        },
                        ...sx,
                    }}
                    {...props}
                />
            </Box>
        </Stack>
    );
};

import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { categoriesColumns, categoriesRows } from './data/nodeCategories.jsx';
import { DefaultDataGrid } from '../../../../../ui/DefaultDataGrid/index.js';
import { useEffect, useState } from 'react';
import api from '../../../../..//lib/axios.js';
import { NodeCategoriesManage } from './NodeCategoriesManage.jsx';

export const NodeCategories = ({ nodeCategoryModal, setNodeCategoryModal, query }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayCategoryData, setArrayCategoryData] = useState([]);
    const [arrayFilterData, setArrayFilterData] = useState([]);


    useEffect(() => {
        if (nodeCategoryModal === null) {
            getCategoryData()
            setNodeCategoryModal(null)
        }
    }, [nodeCategoryModal]);

    function getCategoryData() {
        setIsLoading(true)
        api.get('/apis/NodeCategories/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setArrayFilterData(filterArray)
                setArrayCategoryData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        var filterArray = applyFilters(arrayCategoryData, query)
        setArrayFilterData(filterArray)
    }, [query]);

    const applyFilters = (arrayDataAll, query) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['name', 'description'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    return (
        <>
            <Stack
                flex={1}
                width={'100% + 24px'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 0 0 8px',
                    borderRight: 0,
                    pointerEvents: nodeCategoryModal && 'none',
                }}
                mb={3}
                overflow={'hidden'}
                position={'relative'}
            >
                <DefaultDataGrid
                    columns={categoriesColumns}
                    rows={arrayFilterData}
                    onRowClick={(e) => {
                        setNodeCategoryModal(e.row._id)
                    }}
                />
            </Stack>
            {nodeCategoryModal != null && <NodeCategoriesManage selectedDataID={nodeCategoryModal} onClose={setNodeCategoryModal} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}